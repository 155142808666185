import { useState } from "react"
import { CgArrowsExchange } from "react-icons/cg"
import { useSelector } from "react-redux"
import formatBalance from "../../utils/formatBalance"
import formatTime from "../../utils/formatTime"
import Currency from "./Currency"
import StatusBadge from "./StatusBadge"
import renderFormattedCurrency from "../../utils/renderFormattedCurrency"
import replaceTransactionType from "../../utils/replaceTransactionType"
import Modal from "./Modal"
import styles from "../styles/UI/TransactionContainer.module.css"

const TransactionContainer = ({ transaction }) => {
  const currentWalletID = useSelector((state) => state.wallet.wallet.id)
  const [isModalOpen, setIsModalOpen] = useState(false)

  const openModal = () => setIsModalOpen(true)
  const closeModal = () => setIsModalOpen(false)

  const renderTransactionDetails = () => {
    switch (transaction.__typename) {
      case "CryptocurrencySendTransactionType":
      case "CryptocurrencyReceiveTransactionType":
      case "PaymentTransactionType":
      case "PaymentVortexTransactionType":
      case "TransferTransactionType":
        return (
          <>
            <div className={styles.gridItem} onClick={openModal}>
              {replaceTransactionType(
                transaction.__typename.replace("TransactionType", "")
              )}
            </div>
            <div className={styles.gridItem}>
              <StatusBadge
                status={
                  transaction.sendStatus ||
                  transaction.receiveStatus ||
                  transaction.paymentStatus ||
                  transaction.paymentVortexStatus ||
                  transaction.transferStatus ||
                  "N/A"
                }
              />
            </div>
            <div className={styles.gridItem}>
              <Currency currency={transaction.currency} variant="simple" />
            </div>
            <div className={styles.gridItem}>
              {renderFormattedCurrency(
                transaction.currency.currencyType,
                transaction.currency.symbol,
                formatBalance(transaction.amount, true)
              )}
            </div>
            <div className={styles.time}>
              {formatTime(transaction.createdAt)}
            </div>
          </>
        )
      case "SwapTransactionType":
        return (
          <>
            <div className={styles.gridItem} onClick={openModal}>
              Swap
            </div>
            <div className={styles.gridItem}>
              <StatusBadge status={transaction.swapStatus} />
            </div>
            <div className={styles.swapCurrency}>
              <Currency currency={transaction.fromCurrency} variant="simple" />
              <CgArrowsExchange className={styles.swapIcon} />
              <Currency currency={transaction.toCurrency} variant="simple" />
            </div>
            <div className={styles.time}>
              {formatTime(transaction.createdAt)}
            </div>
          </>
        )
      default:
        return <div>Unknown transaction type.</div>
    }
  }

  const renderModalContent = () => {
    switch (transaction.__typename) {
      case "CryptocurrencySendTransactionType":
        return (
          <>
            <StatusBadge status={transaction.sendStatus} />
            <p>
              <strong>Sender:</strong> {transaction.sender}
            </p>
            <p>
              <strong>Receiver:</strong> {transaction.receiver}
            </p>
            <p>
              <strong>Amount:</strong> {formatBalance(transaction.amount, true)}{" "}
              {transaction.currency.symbol}
            </p>
            <p>
              <strong>Fee:</strong> {formatBalance(transaction.fee, true)}{" "}
              {transaction.currency.symbol}
            </p>
            <p>
              <strong>Network:</strong> {transaction.network.name}
            </p>
            <p>
              <strong>Transaction Hash:</strong> {transaction.txh}
            </p>
            <Currency currency={transaction.currency} variant="simple" />
            <p>
              <strong>Created At:</strong> {formatTime(transaction.createdAt)}
            </p>
          </>
        )
      case "CryptocurrencyReceiveTransactionType":
        return (
          <>
            <StatusBadge status={transaction.receiveStatus} />
            <p>
              <strong>Sender:</strong> {transaction.sender}
            </p>
            <p>
              <strong>Receiver:</strong> {transaction.receiver}
            </p>
            <p>
              <strong>Amount:</strong> {formatBalance(transaction.amount, true)}{" "}
              {transaction.currency.symbol}
            </p>
            <p>
              <strong>Network:</strong> {transaction.network.name}
            </p>
            <p>
              <strong>Transaction Hash:</strong> {transaction.txh}
            </p>
            <Currency currency={transaction.currency} variant="simple" />
            <p>
              <strong>Created At:</strong> {formatTime(transaction.createdAt)}
            </p>
          </>
        )
      case "PaymentTransactionType":
        return (
          <>
            <StatusBadge status={transaction.paymentStatus} />
            <p>
              <strong>Invoice:</strong> {transaction.invoice}
            </p>
            <p>
              <strong>Sender:</strong> {transaction.sender}
            </p>
            <p>
              <strong>Receiver:</strong> {transaction.receiver}
            </p>
            <p>
              <strong>Amount:</strong> {formatBalance(transaction.amount, true)}{" "}
              {transaction.currency.symbol}
            </p>
            <p>
              <strong>Fee:</strong> {formatBalance(transaction.fee, true)}{" "}
              {transaction.currency.symbol}
            </p>
            <p>
              <strong>Network:</strong> {transaction.network.name}
            </p>
            <p>
              <strong>Transaction Hash:</strong> {transaction.txh}
            </p>
            <Currency currency={transaction.currency} variant="simple" />
            <p>
              <strong>Created At:</strong> {formatTime(transaction.createdAt)}
            </p>
          </>
        )
      case "PaymentVortexTransactionType":
        return (
          <>
            <StatusBadge status={transaction.paymentVortexStatus} />
            <p>
              <strong>Invoice:</strong> {transaction.invoice}
            </p>
            <p>
              <strong>Sender Wallet ID:</strong> {transaction.senderWallet.id}
            </p>
            <p>
              <strong>Amount:</strong> {formatBalance(transaction.amount, true)}{" "}
              {transaction.currency.symbol}
            </p>
            {currentWalletID === transaction.receiverWallet.id && (
              <p>
                <strong>Fee:</strong> {formatBalance(transaction.fee, true)}{" "}
                {transaction.currency.symbol}
              </p>
            )}
            <p>
              <strong>Created At:</strong> {formatTime(transaction.createdAt)}
            </p>
          </>
        )
      case "TransferTransactionType":
        return (
          <>
            <StatusBadge status={transaction.transferStatus} />
            <p>
              <strong>Sender Wallet ID:</strong> {transaction.senderWallet.id}
            </p>
            <p>
              <strong>Receiver Wallet ID:</strong>{" "}
              {transaction.receiverWallet.id}
            </p>
            <p>
              <strong>Amount:</strong> {formatBalance(transaction.amount, true)}{" "}
              {transaction.currency.symbol}
            </p>
            {currentWalletID === transaction.senderWallet.id && (
              <p>
                <strong>Fee:</strong> {formatBalance(transaction.fee, true)}{" "}
                {transaction.currency.symbol}
              </p>
            )}
            <p>
              <strong>Description:</strong> {transaction.description}
            </p>
            <Currency currency={transaction.currency} variant="simple" />
            <p>
              <strong>Created At:</strong> {formatTime(transaction.createdAt)}
            </p>
          </>
        )
      case "SwapTransactionType":
        return (
          <>
            <StatusBadge status={transaction.swapStatus} />
            <p>
              <strong>From Currency:</strong> {transaction.fromCurrency.name}
            </p>
            <p>
              <strong>To Currency:</strong> {transaction.toCurrency.name}
            </p>
            <p>
              <strong>From Amount:</strong>{" "}
              {formatBalance(transaction.fromAmount, true)}{" "}
              {transaction.fromCurrency.symbol}
            </p>
            <p>
              <strong>To Amount:</strong>{" "}
              {formatBalance(transaction.toAmount, true)}{" "}
              {transaction.toCurrency.symbol}
            </p>
            <p>
              <strong>Exchange Rate:</strong> {transaction.exchangeRate}
            </p>
            <p>
              <strong>Fee:</strong> {formatBalance(transaction.fee, true)}{" "}
              {transaction.toCurrency.symbol}
            </p>
            <p>
              <strong>Created At:</strong> {formatTime(transaction.createdAt)}
            </p>
          </>
        )
      default:
        return <p>Unknown transaction type.</p>
    }
  }

  return (
    <div>
      <div
        className={
          transaction.__typename === "SwapTransactionType"
            ? styles.swapTransactionContainer
            : styles.transactionContainer
        }
        onClick={openModal}
      >
        {renderTransactionDetails()}
      </div>
      {isModalOpen && (
        <Modal title="Transaction Details" onClose={closeModal}>
          {renderModalContent()}
        </Modal>
      )}
    </div>
  )
}

export default TransactionContainer
