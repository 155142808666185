import { gql } from "@apollo/client"

export const CREATE_INVOICE = gql`
  mutation CreateInvoice(
    $walletId: UUID!
    $amount: Decimal!
    $currency: String!
    $expireTime: Int
  ) {
    createInvoice(
      walletId: $walletId
      amount: $amount
      currency: $currency
      expireTime: $expireTime
    ) {
      ok
      message
      invoice {
        id
        amount
        currency {
          name
          symbol
        }
        expireTime
        status
      }
    }
  }
`
