import { useQuery } from "@apollo/client"
import { useDispatch, useSelector } from "react-redux"
import { USER_WALLETS } from "../apollo/graphql/Query/userWallets"
import { setWallet } from "../redux/walletSlice"
import LabelButton from "../assets/UI/LabelButton"
import { useNavigate } from "react-router-dom"
import KYC from "../assets/UI/KYC"
import KYB from "../assets/UI/KYB"
import UsernameTag from "../assets/UI/UsernameTag"
import AvatarUpload from "../assets/UI/AvatarUpload"
import styles from "../assets/styles/Components/Wallets.module.css"

const Wallets = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const currentWallet = useSelector((state) => state.wallet.wallet)
  const { loading, error, data, refetch } = useQuery(USER_WALLETS)

  const handleSwitchWallet = (wallet) => {
    refetch()
      .then((refetchedData) => {
        const updatedWallet = refetchedData.data.userWallets.find(
          (w) => w.id === wallet.id
        )
        if (updatedWallet) {
          dispatch(setWallet(updatedWallet))
          navigate("/wallet")
        }
      })
      .catch((err) => {
        console.error("Error refetching wallets:", err)
      })
  }

  if (loading) return <p>Loading wallets...</p>
  if (error) return <p>Error loading wallets: {error.message}</p>

  if (!currentWallet) {
    return <p>No wallet selected.</p>
  }

  const isPersonal =
    currentWallet.walletType === "PERSONAL" ||
    currentWallet.walletType === "USER"
  const isBusiness = currentWallet.walletType === "BUSINESS"

  const kycStatus = currentWallet.user?.kycStatus || "NONE"
  const kybStatus = currentWallet.business?.kybStatus || "NONE"

  return (
    <div className={styles.container}>
      <div className={styles.currentWallet}>
        <AvatarUpload
          wallet={currentWallet}
          avatarUrl={
            isPersonal && currentWallet.user
              ? currentWallet.user.avatarUrl
              : isBusiness && currentWallet.business.avatarUrl
          }
        />
        <p className={styles.currentWalletText}>
          {isPersonal && currentWallet.user
            ? `${currentWallet.user.firstName} ${currentWallet.user.lastName}`
            : isBusiness && currentWallet.business
            ? currentWallet.business.legalName
            : "Unknown Wallet"}
        </p>
        {isPersonal && <UsernameTag />}
      </div>

      {kycStatus !== "COMPLETED" && <KYC />}

      {isBusiness && kybStatus !== "COMPLETED" && <KYB />}

      <div>
        <h2>Switch Wallet</h2>
        {data.userWallets
          .filter((wallet) => wallet.id !== currentWallet.id)
          .map((wallet) => (
            <LabelButton
              key={wallet.id}
              label={
                wallet.walletType === "PERSONAL" && wallet.user
                  ? `${wallet.user.firstName} ${wallet.user.lastName}`
                  : wallet.business?.legalName || "Unknown Wallet"
              }
              onClick={() => handleSwitchWallet(wallet)}
              icon={
                wallet.walletType === "PERSONAL" && wallet.user
                  ? wallet.user.avatarUrl
                  : wallet.business?.avatarUrl
              }
              size="normal"
              withBackground={true}
            >
              {wallet.walletType === "PERSONAL" && wallet.user && (
                <div>
                  <p>
                    {wallet.user.firstName} {wallet.user.lastName}
                  </p>
                </div>
              )}
              {wallet.walletType === "BUSINESS" && wallet.business && (
                <div>
                  <p>{wallet.business.legalName}</p>
                </div>
              )}
            </LabelButton>
          ))}
      </div>
    </div>
  )
}

export default Wallets
