import { useState } from "react"
import { useMutation, useLazyQuery } from "@apollo/client"
import { useDispatch, useSelector } from "react-redux"
import { ALL_NATIONALITIES } from "../../apollo/graphql/Query/allNationalities"
import { SUBMIT_KYC } from "../../apollo/graphql/Mutation/submitKYC"
import { setWallet } from "../../redux/walletSlice"
import Modal from "./Modal"
import LabelButton from "./LabelButton"
import StatusBadge from "./StatusBadge"
import { toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import styles from "../styles/UI/KYC.module.css"

const KYC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [selectedNationality, setSelectedNationality] = useState("")
  const [document, setDocument] = useState(null)
  const [proofOfAddress, setProofOfAddress] = useState(null)
  const [currentStep, setCurrentStep] = useState(1)

  const dispatch = useDispatch()
  const wallet = useSelector((state) => state.wallet.wallet)
  const kycStatus = wallet?.user?.kycStatus || "NONE"

  const [
    loadNationalities,
    { data: nationalitiesData, loading: loadingNationalities },
  ] = useLazyQuery(ALL_NATIONALITIES)
  const [submitKYC, { loading: loadingSubmit }] = useMutation(SUBMIT_KYC)

  const handleOpenModal = () => {
    setIsModalOpen(true)
    loadNationalities()
  }

  const handleCloseModal = () => {
    setIsModalOpen(false)
    setSelectedNationality("")
    setDocument(null)
    setProofOfAddress(null)
    setCurrentStep(1)
  }

  const handleFileChange = (e, setFile) => {
    const file = e.target.files[0]
    setFile(file)
  }

  const handleNextStep = () => {
    if (currentStep === 1 && !selectedNationality) {
      toast.warn("Please select a nationality.")
    } else if (currentStep === 2 && !document) {
      toast.warn("Please upload your document.")
    } else {
      setCurrentStep(currentStep + 1)
    }
  }

  const handleSubmit = () => {
    if (selectedNationality && document && proofOfAddress) {
      submitKYC({
        variables: {
          nationalityId: selectedNationality,
          document,
          proofOfAddress,
        },
      })
        .then((response) => {
          toast.success(response.data.submitKyc.message)
          dispatch(
            setWallet({
              ...wallet,
              user: { ...wallet.user, kycStatus: "PENDING" },
            })
          )
          handleCloseModal()
        })
        .catch((error) => {
          console.error("Error submitting KYC:", error)
          toast.error("Failed to submit KYC. Please try again.")
        })
    } else {
      toast.warn("Please complete all steps and upload all required documents.")
    }
  }

  return (
    <>
      <ToastContainer />
      <LabelButton onClick={handleOpenModal} arrow>
        KYC <StatusBadge status={kycStatus} />
      </LabelButton>

      {isModalOpen && (
        <Modal title="Submit KYC" onClose={handleCloseModal}>
          {loadingNationalities ? (
            <p>Loading nationalities...</p>
          ) : (
            <div className={styles.kycForm}>
              {currentStep === 1 && (
                <div className={styles.field}>
                  <label>Select Nationality:</label>
                  <select
                    value={selectedNationality}
                    onChange={(e) => setSelectedNationality(e.target.value)}
                    className={styles.selectInput}
                  >
                    <option value="" disabled>
                      Select a nationality
                    </option>
                    {nationalitiesData?.allNationalities?.map((nationality) => (
                      <option key={nationality.id} value={nationality.id}>
                        {nationality.name}
                      </option>
                    ))}
                  </select>
                  <LabelButton onClick={handleNextStep}>Next</LabelButton>
                </div>
              )}

              {currentStep === 2 && (
                <div className={styles.field}>
                  <label>Upload Document (Passport or ID):</label>
                  <input
                    type="file"
                    accept=".png,.jpeg,.jpg,.pdf"
                    onChange={(e) => handleFileChange(e, setDocument)}
                  />
                  <LabelButton onClick={handleNextStep}>Next</LabelButton>
                </div>
              )}

              {currentStep === 3 && (
                <div className={styles.field}>
                  <label>Upload Proof of Address:</label>
                  <input
                    type="file"
                    accept=".png,.jpeg,.jpg,.pdf"
                    onChange={(e) => handleFileChange(e, setProofOfAddress)}
                  />
                  <LabelButton onClick={handleSubmit} disabled={loadingSubmit}>
                    {loadingSubmit ? "Submitting..." : "Submit KYC"}
                  </LabelButton>
                </div>
              )}
            </div>
          )}
        </Modal>
      )}
    </>
  )
}

export default KYC
