import PropTypes from "prop-types"
import { toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import styles from "../styles/UI/AddressDisplay.module.css"

const AddressDisplay = ({ address }) => {
  const handleCopyClick = () => {
    navigator.clipboard.writeText(address).then(() => {
      toast("Copied to clipboard", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        style: {
          backgroundColor: "#070707",
          color: "#ffffff",
        },
      })
    })
  }

  return (
    <div className={styles.addressContainer}>
      <p className={styles.addressText} onClick={handleCopyClick}>
        {address}
      </p>
      <ToastContainer />
    </div>
  )
}

AddressDisplay.propTypes = {
  address: PropTypes.string.isRequired,
}

export default AddressDisplay
