import { useState, useEffect } from "react"
import { useMutation } from "@apollo/client"
import { toast, ToastContainer } from "react-toastify"
import { VERIFY_EMAIL_OTP } from "../../apollo/graphql/Mutation/verifyEmailOtp"
import { VERIFY_PHONE_OTP } from "../../apollo/graphql/Mutation/verifyPhoneOtp"
import { RESEND_EMAIL_OTP } from "../../apollo/graphql/Mutation/resendEmailOtp"
import { RESEND_PHONE_OTP } from "../../apollo/graphql/Mutation/resendPhoneOtp"
import "react-toastify/dist/ReactToastify.css"
import styles from "../styles/UI/OTPInput.module.css"

const OTPInput = ({
  length = 6,
  email = "",
  phoneNumber = "",
  errorMessage,
  title,
  secondLabel = "Resend Code",
  onSuccess,
  type = "email",
  resendCooldown = 60,
}) => {
  const [otp, setOtp] = useState(Array(length).fill(""))
  const [errmsg, setErrmsg] = useState(errorMessage || "")
  const [cooldown, setCooldown] = useState(0)

  const [verifyEmailOtp] = useMutation(VERIFY_EMAIL_OTP)
  const [verifyPhoneOtp] = useMutation(VERIFY_PHONE_OTP)
  const [resendEmailOtp] = useMutation(RESEND_EMAIL_OTP)
  const [resendPhoneOtp] = useMutation(RESEND_PHONE_OTP)

  useEffect(() => {
    let timer
    if (cooldown > 0) {
      timer = setInterval(() => {
        setCooldown((prev) => prev - 1)
      }, 1000)
    } else if (cooldown === 0 && timer) {
      clearInterval(timer)
    }
    return () => clearInterval(timer)
  }, [cooldown])

  const handleChange = (e, index) => {
    const { value } = e.target
    if (/^[0-9]$/.test(value) || value === "") {
      const newOtp = [...otp]
      newOtp[index] = value
      setOtp(newOtp)
      setErrmsg("")
      if (value !== "" && index < length - 1) {
        document.getElementById(`otp-input-${index + 1}`).focus()
      }
      if (newOtp.join("").length === length && value !== "") {
        handleVerify(newOtp.join(""))
      }
    }
  }

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && otp[index] === "") {
      if (index > 0) {
        document.getElementById(`otp-input-${index - 1}`).focus()
      }
    }
    if (e.key === "Backspace" && otp[index] !== "") {
      const newOtp = [...otp]
      newOtp[index] = ""
      setOtp(newOtp)
      setErrmsg("")
    }
  }

  const handleVerify = async (enteredOtp) => {
    try {
      let data
      if (type === "email") {
        data = await verifyEmailOtp({
          variables: { email, otp: enteredOtp },
        })
      } else if (type === "phone") {
        data = await verifyPhoneOtp({
          variables: { phoneNumber, otp: enteredOtp },
        })
      }

      const success =
        data?.data?.[type === "email" ? "verifyEmailOtp" : "verifyPhoneOtp"]
          ?.success

      if (success) {
        if (onSuccess) {
          onSuccess()
        }
      } else {
        setErrmsg("Verification failed. Please try again.")
      }
    } catch {
      setErrmsg("Verification error. Please try again.")
    }
  }

  const handleResend = async () => {
    if (cooldown > 0) return

    try {
      let data
      if (type === "email") {
        data = await resendEmailOtp({ variables: { email } })
      } else if (type === "phone") {
        data = await resendPhoneOtp({ variables: { phoneNumber } })
      }

      const success =
        data?.data?.[type === "email" ? "resendEmailOtp" : "resendPhoneOtp"]
          ?.success

      if (success) {
        toast.success("OTP resent successfully.")
        setCooldown(resendCooldown)
      } else {
        setErrmsg("Failed to resend OTP. Please try again.")
      }
    } catch {
      setErrmsg("Error occurred while resending OTP.")
    }

    setOtp(Array(length).fill(""))
  }

  return (
    <div className={styles.container}>
      <ToastContainer position="top-right" autoClose={3000} />
      {title && <h2 className={styles.title}>{title}</h2>}
      <div className={styles.otpContainer}>
        {otp.map((_, index) => (
          <input
            key={index}
            id={`otp-input-${index}`}
            type="text"
            maxLength="1"
            className={`${styles.otpInput} ${errmsg ? styles.error : ""}`}
            value={otp[index]}
            onChange={(e) => handleChange(e, index)}
            onKeyDown={(e) => handleKeyDown(e, index)}
          />
        ))}
      </div>
      <div className={styles.errorAndSecondLabel}>
        {errmsg && <span className={styles.errorMessage}>{errmsg}</span>}
        <button
          className={`${styles.secondLabelButton} ${
            cooldown > 0 ? styles.disabledButton : ""
          }`}
          onClick={handleResend}
          disabled={cooldown > 0}
        >
          {cooldown > 0 ? `Resend in ${cooldown}s` : secondLabel}
        </button>
      </div>
    </div>
  )
}

export default OTPInput
