import OTPInput from "../assets/UI/OTPInput"
import styles from "../assets/styles/Components/OTPPhoneVerification.module.css"

const OTPPhoneVerification = ({ phoneNumber, onSuccess }) => {
  return (
    <div className={styles.container}>
      <OTPInput
        length={6}
        phoneNumber={phoneNumber}
        type="phone"
        title="Enter the OTP sent to your phone"
        onSuccess={onSuccess}
        resendCooldown={60}
      />
    </div>
  )
}

export default OTPPhoneVerification
