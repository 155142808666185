import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  wallet: JSON.parse(localStorage.getItem("wallet")) || null,
}

const walletSlice = createSlice({
  name: "wallet",
  initialState,
  reducers: {
    setWallet: (state, action) => {
      state.wallet = action.payload
      localStorage.setItem("wallet", JSON.stringify(action.payload))
    },
    clearWallet: (state) => {
      state.wallet = null
      localStorage.removeItem("wallet")
    },
  },
})

export const { setWallet, clearWallet } = walletSlice.actions
export default walletSlice.reducer
