import { gql } from "@apollo/client"

export const UPDATE_WEBSITE_CURRENCY_BLOCKCHAINS = gql`
  mutation UpdateWebsiteCurrencies(
    $websiteId: UUID!
    $currencyId: ID!
    $blockchainIds: [ID!]!
  ) {
    updateWebsiteCurrencies(
      websiteId: $websiteId
      currencyId: $currencyId
      blockchainIds: $blockchainIds
    ) {
      ok
      website {
        id
        name
        supportedCurrencies {
          id
          currency {
            id
            name
            symbol
            isoCode
            logoUrl
            isActive
          }
          blockchain {
            id
            fullName
            name
            symbol
            logoUrl
            isActive
          }
        }
      }
      message
    }
  }
`
