import { gql } from "@apollo/client"

export const FIND_INVOICE_OR_STATIC_INVOICE = gql`
  query FindInvoiceOrStaticInvoiceByAddress($address: String!) {
    findInvoiceOrStaticInvoiceByAddress(address: $address) {
      ... on InvoiceType {
        __typename
        id
        amount
        currency {
          id
          name
          symbol
          isoCode
          logoUrl
          isActive
        }
        selectedCurrency {
          id
          currency {
            id
            name
            symbol
            isoCode
            logoUrl
            isActive
          }
          blockchain {
            id
            fullName
            name
            symbol
            logoUrl
            isActive
          }
        }
        expectedAmount
        expirationDate
        invoiceStatus: status
        address {
          subAddress
        }
      }
      ... on StaticInvoiceType {
        __typename
        id
        name
        network {
          id
          fullName
          name
          symbol
          logoUrl
          isActive
        }
        staticInvoiceStatus: status
        address {
          subAddress
        }
      }
    }
  }
`
