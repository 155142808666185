import { useParams } from "react-router-dom"
import { useQuery } from "@apollo/client"
import { WEBSITE } from "../apollo/graphql/Query/website"
import { ALL_WEBSITES } from "../apollo/graphql/Query/allWebsites"
import TabMenu from "../assets/UI/TabMenu"
import WebsiteSettings from "../components/WebsiteSettings"
import WebsiteSupportedCurrencies from "../components/WebsiteSupportedCurrencies"
import { useSelector } from "react-redux"
import AddressDisplay from "../assets/UI/AddressDisplay"
import StatusBadge from "../assets/UI/StatusBadge"
import styles from "../assets/styles/Pages/WebsitePage.module.css"

const WebsitePage = () => {
  const { websiteId } = useParams()
  const walletId = useSelector((state) => state.wallet.wallet.id)
  const { loading, error, data, refetch } = useQuery(WEBSITE, {
    variables: { websiteId },
  })
  const { refetch: refetchWebsites } = useQuery(ALL_WEBSITES, {
    variables: { walletId },
  })

  if (loading) return <p>Loading website data...</p>
  if (error) return <p>Error loading website data: {error.message}</p>

  const website = data.website

  const tabs = [
    {
      key: "settings",
      label: "Settings",
      content: (
        <WebsiteSettings
          website={website}
          refetchWebsite={refetch}
          refetchWebsites={refetchWebsites}
        />
      ),
    },
    {
      key: "currencies",
      label: "Supported Currencies",
      content: (
        <WebsiteSupportedCurrencies
          walletId={walletId}
          website={website}
          refetchWebsite={refetch}
        />
      ),
    },
  ]

  return (
    <div className={styles.container}>
      <p className={styles.websiteName}>{website.name}</p>
      <p className={styles.websiteURL}>{website.url}</p>
      <div className={styles.apiKey}>
        {website.apiKey ? (
          <>
            <p className={styles.apiKeyText}>ApiKey</p>
            <AddressDisplay address={website.apiKey} />
          </>
        ) : (
          <StatusBadge status={"Pending"} />
        )}
      </div>
      <TabMenu tabs={tabs} />
    </div>
  )
}

export default WebsitePage
