import { useState, useEffect } from "react"
import { useQuery, useMutation } from "@apollo/client"
import Card from "../assets/UI/Card"
import Button from "../assets/UI/Button"
import Modal from "../assets/UI/Modal"
import LabelButton from "../assets/UI/LabelButton"
import Currency from "../assets/UI/Currency"
import { ALL_CURRENCIES } from "../apollo/graphql/Query/allCurrencies"
import { UPDATE_WEBSITE_CURRENCY_BLOCKCHAINS } from "../apollo/graphql/Mutation/updateWebsiteCurrencies"
import styles from "../assets/styles/Components/WebsiteSupportedCurrencies.module.css"

const WebsiteSupportedCurrencies = ({ walletId, website, refetchWebsite }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [currentCurrency, setCurrentCurrency] = useState(null)
  const [selectedBlockchainIds, setSelectedBlockchainIds] = useState([])

  const {
    loading: currenciesLoading,
    error: currenciesError,
    data: currenciesData,
  } = useQuery(ALL_CURRENCIES, {
    variables: {
      walletId: walletId,
      withPositiveBalance: false,
      currencyType: "CRYPTO",
    },
  })

  const supportedCurrencies = website.supportedCurrencies

  const [updateCurrencies, { loading: updateLoading }] = useMutation(
    UPDATE_WEBSITE_CURRENCY_BLOCKCHAINS
  )

  useEffect(() => {
    if (isModalOpen && currentCurrency) {
      const blockchainIds = supportedCurrencies
        .filter((bc) => bc.currency.id === currentCurrency.currency.id)
        .map((bc) => bc.blockchain.id)
      setSelectedBlockchainIds(blockchainIds)
    }
  }, [isModalOpen, currentCurrency, supportedCurrencies])

  const handleButtonClick = (currencyData) => {
    setCurrentCurrency(currencyData)
    setIsModalOpen(true)
  }

  const handleBlockchainSelection = (blockchainId) => {
    setSelectedBlockchainIds((prevSelected) => {
      if (prevSelected.includes(blockchainId)) {
        return prevSelected.filter((id) => id !== blockchainId)
      } else {
        return [...prevSelected, blockchainId]
      }
    })
  }

  const updateSupportedCurrencies = async () => {
    try {
      const currencyId = currentCurrency.currency.id.toString()
      const blockchainIds = selectedBlockchainIds.map((id) => id.toString())

      const { data: mutationData } = await updateCurrencies({
        variables: {
          websiteId: website.id,
          currencyId: currencyId,
          blockchainIds: blockchainIds,
        },
      })

      if (mutationData.updateWebsiteCurrencies.ok) {
        refetchWebsite()
        setIsModalOpen(false)
      } else {
        alert(`Error: ${mutationData.updateWebsiteCurrencies.message}`)
      }
    } catch (error) {
      console.error("Error updating supported currencies:", error)
      alert("An unexpected error occurred while updating supported currencies.")
    }
  }

  if (currenciesLoading) return <p>Loading currencies...</p>
  if (currenciesError) return <p>Error loading currencies.</p>

  return (
    <div className={styles.container}>
      {currenciesData.allCurrencies.map((currencyData) => {
        const currencyId = currencyData.currency.id
        const hasBlockchains = currencyData.blockchains.length > 0
        const isActive =
          hasBlockchains &&
          supportedCurrencies.some((bc) => bc.currency.id === currencyId)

        return (
          <Card key={currencyId} inactive={!isActive}>
            <div className={styles.card}>
              <Currency currency={currencyData.currency} variant="simple" />
              <Button onClick={() => handleButtonClick(currencyData)}>
                {hasBlockchains
                  ? "Select Blockchains"
                  : "No Blockchains Available"}
              </Button>
            </div>
          </Card>
        )
      })}

      {isModalOpen && currentCurrency && (
        <Modal
          title={`Select Blockchains for ${currentCurrency.currency.name}`}
          onClose={() => setIsModalOpen(false)}
        >
          {currentCurrency.blockchains.map((blockchainData) => {
            const blockchainId = blockchainData.blockchain.id
            const isChecked = selectedBlockchainIds.includes(blockchainId)

            return (
              <LabelButton
                key={blockchainId}
                onClick={() => handleBlockchainSelection(blockchainId)}
                active={isChecked}
                icon={blockchainData.blockchain.logoUrl}
                size="normal"
                withBackground={true}
              >
                {blockchainData.blockchain.fullName}
              </LabelButton>
            )
          })}
          <div className={styles.modalBtn}>
            <Button
              onClick={updateSupportedCurrencies}
              disabled={updateLoading}
            >
              {updateLoading ? "Updating..." : "Save"}
            </Button>
          </div>
        </Modal>
      )}
    </div>
  )
}

export default WebsiteSupportedCurrencies
