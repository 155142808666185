import { ReactTyped } from "react-typed"
import AddressDisplay from "../assets/UI/AddressDisplay"
import Logo from "../assets/UI/Logo"
import QRCodeDisplay from "../assets/UI/QRCodeDisplay"
import styles from "../assets/styles/Components/StaticInvoiceComponent.module.css"

const StaticInvoiceComponent = ({ invoice }) => {
  if (invoice.staticInvoiceStatus === "ACTIVE") {
    return (
      <>
        <div className={styles.logoContainer}>
          <Logo />
        </div>
        <div className={styles.container}>
          <p className={styles.invoiceName}>{invoice.name}</p>
          <QRCodeDisplay value={invoice.address.subAddress} />
          <p className={styles.networkName}>{invoice.network.fullName}</p>
          <AddressDisplay address={invoice.address.subAddress} />
        </div>
      </>
    )
  } else {
    return (
      <>
        <div className={styles.logoContainer}>
          <Logo />
        </div>
        <div className={styles.container}>
          <ReactTyped
            strings={["Invoice Inactive"]}
            typeSpeed={50}
            showCursor={false}
            className={styles.typedText}
          />
        </div>
      </>
    )
  }
}

export default StaticInvoiceComponent
