import { createRoot } from "react-dom/client"
import { Provider } from "react-redux"
import { ApolloProvider } from "@apollo/client"
import App from "./App"
import client from "./apollo/ApolloClient"
import store from "./redux/store"
import "./index.css"

const container = document.getElementById("root")
const root = createRoot(container)

root.render(
  <Provider store={store}>
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  </Provider>
)
