import { useState } from "react"
import { useQuery } from "@apollo/client"
import { ALL_TRANSACTIONS } from "../apollo/graphql/Query/allTransactions"
import TransactionContainer from "../assets/UI/TransactionContainer"
import Modal from "../assets/UI/Modal"
import Button from "../assets/UI/Button"
import Skeleton, { SkeletonTheme } from "react-loading-skeleton"
import "react-loading-skeleton/dist/skeleton.css"
import Input from "../assets/UI/Input"
import styles from "../assets/styles/Components/TransactionList.module.css"

const TransactionList = ({ walletId }) => {
  const [fromDate, setFromDate] = useState(null)
  const [toDate, setToDate] = useState(null)
  const [transactionTypes, setTransactionTypes] = useState([])
  const [currencyType, setCurrencyType] = useState(null)
  const [currencyIsoCode, setCurrencyIsoCode] = useState(null)
  const [tempFromDate, setTempFromDate] = useState(null)
  const [tempToDate, setTempToDate] = useState(null)
  const [tempTransactionTypes, setTempTransactionTypes] = useState([])
  const [tempCurrencyType, setTempCurrencyType] = useState(null)
  const [tempCurrencyIsoCode, setTempCurrencyIsoCode] = useState(null)

  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false)
  const [appliedSettingsCount, setAppliedSettingsCount] = useState(0)

  const { loading, error, data, refetch } = useQuery(ALL_TRANSACTIONS, {
    variables: {
      walletId,
      fromDate,
      toDate,
      transactionTypes,
      currencyType,
      currencyIsoCode,
    },
    fetchPolicy: "network-only",
  })

  const openSettingsModal = () => setIsSettingsModalOpen(true)
  const closeSettingsModal = () => setIsSettingsModalOpen(false)

  const applySettings = () => {
    setFromDate(tempFromDate)
    setToDate(tempToDate)
    setTransactionTypes(tempTransactionTypes)
    setCurrencyType(tempCurrencyType)
    setCurrencyIsoCode(tempCurrencyIsoCode)

    let count = 0
    if (tempFromDate) count++
    if (tempToDate) count++
    if (tempTransactionTypes.length > 0) count++
    if (tempCurrencyType) count++
    if (tempCurrencyIsoCode) count++
    setAppliedSettingsCount(count)

    closeSettingsModal()
    refetch()
  }

  const resetSettings = () => {
    setFromDate(null)
    setToDate(null)
    setTransactionTypes([])
    setCurrencyType(null)
    setCurrencyIsoCode(null)

    setTempFromDate(null)
    setTempToDate(null)
    setTempTransactionTypes([])
    setTempCurrencyType(null)
    setTempCurrencyIsoCode(null)

    setAppliedSettingsCount(0)

    closeSettingsModal()
    refetch()
  }

  if (loading) {
    return (
      <SkeletonTheme baseColor="#070707" highlightColor="#1e1e1e">
        <Skeleton count={5} height={108} />
      </SkeletonTheme>
    )
  }

  if (error) return <p>Error: {error.message}</p>

  const groupTransactionsByDate = (transactions) => {
    return transactions.reduce((groups, transaction) => {
      const date = new Date(transaction.createdAt).toLocaleDateString("en-GB", {
        day: "numeric",
        month: "long",
        year: "numeric",
      })

      if (!groups[date]) {
        groups[date] = []
      }
      groups[date].push(transaction)
      return groups
    }, {})
  }

  const groupedTransactions = groupTransactionsByDate(data.allTransactions)

  return (
    <>
      <div className={styles.filter}>
        <Button className={styles.settingsButton} onClick={openSettingsModal}>
          Filter {appliedSettingsCount > 0 && `(${appliedSettingsCount})`}
        </Button>
      </div>

      {Object.keys(groupedTransactions).length > 0 ? (
        <div className={styles.transactionsContainer}>
          {Object.keys(groupedTransactions).map((date) => (
            <div key={date}>
              <div className={styles.dateHeader}>{date}</div>
              <div className={styles.transactions}>
                {groupedTransactions[date].map((transaction) => (
                  <TransactionContainer
                    key={transaction.id}
                    transaction={transaction}
                  />
                ))}
              </div>
            </div>
          ))}
        </div>
      ) : (
        <p>No transactions found.</p>
      )}

      {isSettingsModalOpen && (
        <Modal title="Filter Transactions" onClose={closeSettingsModal}>
          <div className={styles.modalContent}>
            <div className={styles.modalRow}>
              <div className={styles.field}>
                <label>From Date:</label>
                <Input
                  type="date"
                  value={tempFromDate ? tempFromDate.split("T")[0] : ""}
                  onChange={(e) =>
                    setTempFromDate(
                      e.target.value ? e.target.value + "T00:00:00Z" : null
                    )
                  }
                />
              </div>
              <div className={styles.field}>
                <label>To Date:</label>
                <Input
                  type="date"
                  value={tempToDate ? tempToDate.split("T")[0] : ""}
                  onChange={(e) =>
                    setTempToDate(
                      e.target.value ? e.target.value + "T23:59:59Z" : null
                    )
                  }
                />
              </div>
            </div>

            <div className={styles.field}>
              <label>Transaction Types:</label>
              <div className={styles.transactionTypes}>
                {[
                  "SEND",
                  "RECEIVE",
                  "PAYMENT",
                  "VORTEXPAYMENT",
                  "SWAP",
                  "TRANSFER",
                ].map((type) => (
                  <div key={type} className={styles.checkbox}>
                    <input
                      id={`type-${type}`}
                      type="checkbox"
                      checked={tempTransactionTypes.includes(type)}
                      onChange={() => {
                        if (tempTransactionTypes.includes(type)) {
                          setTempTransactionTypes(
                            tempTransactionTypes.filter((t) => t !== type)
                          )
                        } else {
                          setTempTransactionTypes([
                            ...tempTransactionTypes,
                            type,
                          ])
                        }
                      }}
                    />
                    <label htmlFor={`type-${type}`}>{type}</label>
                  </div>
                ))}
              </div>
            </div>

            <div className={styles.modalRow}>
              <div className={styles.field}>
                <label>Currency Type:</label>
                <select
                  value={tempCurrencyType || ""}
                  onChange={(e) => setTempCurrencyType(e.target.value || null)}
                  className={styles.select}
                >
                  <option value="">All</option>
                  <option value="FIAT">Fiat</option>
                  <option value="CRYPTO">Crypto</option>
                </select>
              </div>
              <div className={styles.field}>
                <label>Currency ISO Code:</label>
                <Input
                  type="text"
                  placeholder="e.g., USD, BTC"
                  value={tempCurrencyIsoCode || ""}
                  onChange={(e) =>
                    setTempCurrencyIsoCode(e.target.value.toUpperCase() || null)
                  }
                />
              </div>
            </div>

            <div className={styles.buttons}>
              <Button onClick={resetSettings} variant="secondary">
                Reset
              </Button>
              <Button onClick={applySettings}>Apply</Button>
            </div>
          </div>
        </Modal>
      )}
    </>
  )
}

export default TransactionList
