import { SlArrowRight } from "react-icons/sl"
import styles from "../styles/UI/LabelButton.module.css"

const LabelButton = ({
  icon,
  label,
  onClick,
  disabled,
  arrow,
  active = true,
  size = "normal",
  withBackground = true,
  children,
  error,
}) => {
  const buttonClass = `${styles.labelButton} ${
    disabled ? styles.disabled : ""
  } ${!active ? styles.inactive : ""} ${size === "small" ? styles.small : ""} ${
    withBackground ? styles.withBackground : styles.noBackground
  }`

  return (
    <div className={styles.container}>
      {label && <label className={styles.label}>{label}</label>}
      <button
        className={buttonClass}
        onClick={onClick}
        disabled={disabled}
        style={{ cursor: onClick && !disabled ? "pointer" : "auto" }}
      >
        <div
          className={`${styles.content} ${
            size === "small" ? styles.small : ""
          }`}
        >
          {icon && <img src={icon} alt="icon" className={styles.icon} />}
          {children}
        </div>
        {arrow && <SlArrowRight />}
      </button>
      {error && <span className={styles.errorMessage}>{error}</span>}
    </div>
  )
}

export default LabelButton
