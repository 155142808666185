import { useState } from "react"
import LabelButton from "../assets/UI/LabelButton"
import Modal from "../assets/UI/Modal"
import SearchBar from "../assets/UI/SearchBar"
import { useQuery } from "@apollo/client"
import { ALL_COUNTRIES } from "../apollo/graphql/Query/allCountries"
import styles from "../assets/styles/Components/CountrySelector.module.css"

const CountrySelector = ({ selectedCountry, setSelectedCountry, onNext }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [searchTerm, setSearchTerm] = useState("")
  const { loading, error, data } = useQuery(ALL_COUNTRIES)

  const filteredCountries = data?.allCountries
    .filter((country) =>
      country.name.toLowerCase().includes(searchTerm.toLowerCase())
    )
    .sort((a, b) => a.name.localeCompare(b.name))

  const handleCountryClick = (country) => {
    setSelectedCountry(country)
    setIsModalOpen(false)
    onNext(country)
  }

  return (
    <div className={styles.container}>
      <LabelButton
        label="Country"
        icon={selectedCountry ? selectedCountry.logoUrl : ""}
        onClick={() => setIsModalOpen(true)}
        arrow
      >
        {selectedCountry ? selectedCountry.name : "Choose a country"}
      </LabelButton>

      {isModalOpen && (
        <Modal
          title="Select a Country"
          onClose={() => setIsModalOpen(false)}
          firstBlockSpacing={true}
          width="70vh"
          height="70vh"
        >
          <SearchBar
            placeholder="Search for a country..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          {loading ? (
            <p>Loading...</p>
          ) : error ? (
            <p>Error loading countries</p>
          ) : (
            filteredCountries.map((country) => (
              <LabelButton
                key={country.id}
                onClick={() => handleCountryClick(country)}
                icon={country.logoUrl}
                size={"small"}
              >
                {country.name}
              </LabelButton>
            ))
          )}
        </Modal>
      )}
    </div>
  )
}

export default CountrySelector
