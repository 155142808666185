import { useNavigate } from "react-router-dom"
import TotalBalance from "../assets/UI/TotalBalance"
import ActionButton from "../assets/UI/ActionButton"
import TabMenu from "../assets/UI/TabMenu"
import CryptoList from "../components/CryptoList"
import FiatList from "../components/FiatList"
import TransactionList from "../components/TransactionList"
import { useSelector } from "react-redux"
import styles from "../assets/styles/Pages/WalletPage.module.css"

function WalletPage() {
  const walletId = useSelector((state) => state.wallet.wallet.id)

  const navigate = useNavigate()

  const tabs = [
    {
      key: "Crypto",
      label: "Crypto",
      content: <CryptoList walletId={walletId} />,
    },
    {
      key: "Fiat",
      label: "Fiat",
      content: <FiatList walletId={walletId} />,
    },
    {
      key: "Transactions",
      label: "Transactions",
      content: <TransactionList walletId={walletId} />,
    },
  ]

  const handleSendClick = () => {
    navigate("/send")
  }

  const handleReceiveClick = () => {
    navigate("/receive")
  }

  const handleSwapClick = () => {
    navigate("/swap")
  }

  return (
    <div className={styles.container}>
      <div className={styles.balance}>
        <TotalBalance walletId={walletId} />
        <div className={styles.actionButton}>
          <ActionButton action={"Send"} onClick={handleSendClick} />
          <ActionButton action={"Receive"} onClick={handleReceiveClick} />
          <ActionButton action={"Swap"} onClick={handleSwapClick} />
        </div>
      </div>
      <TabMenu tabs={tabs} />
    </div>
  )
}

export default WalletPage
