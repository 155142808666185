import { useQuery } from "@apollo/client"
import { useParams, useNavigate } from "react-router-dom"
import { CONVERT_SINGLE_CURRENCY } from "../apollo/graphql/Query/convertSingleCurrency"
import { ALL_TRANSACTIONS } from "../apollo/graphql/Query/allTransactions"
import Currency from "../assets/UI/Currency"
import formatBalance from "../utils/formatBalance"
import ActionButton from "../assets/UI/ActionButton"
import TransactionContainer from "../assets/UI/TransactionContainer"
import { useSelector } from "react-redux"
import Skeleton from "react-loading-skeleton"
import "react-loading-skeleton/dist/skeleton.css"
import styles from "../assets/styles/Pages/CurrencyPage.module.css"

const CurrencyPage = () => {
  const { isoCode } = useParams()
  const walletId = useSelector((state) => state.wallet.wallet.id)
  const navigate = useNavigate()

  const {
    loading: conversionLoading,
    error: conversionError,
    data: conversionData,
  } = useQuery(CONVERT_SINGLE_CURRENCY, {
    variables: { walletId, currency: isoCode },
  })

  const {
    loading: transactionsLoading,
    error: transactionsError,
    data: transactionsData,
  } = useQuery(ALL_TRANSACTIONS, {
    variables: { walletId, currencyIsoCode: isoCode },
    fetchPolicy: "network-only",
  })

  if (conversionError) return <p>Error: {conversionError.message}</p>
  if (transactionsError) return <p>Error: {transactionsError.message}</p>

  const { originalAmount, convertedAmount, originalCurrency, targetCurrency } =
    conversionData?.convertSingleCurrency || {}

  const groupTransactionsByDate = (transactions) => {
    return transactions.reduce((groups, transaction) => {
      const date = new Date(transaction.createdAt).toLocaleDateString("en-GB", {
        day: "numeric",
        month: "long",
        year: "numeric",
      })

      if (!groups[date]) {
        groups[date] = []
      }
      groups[date].push(transaction)
      return groups
    }, {})
  }

  const groupedTransactions = transactionsData
    ? groupTransactionsByDate(transactionsData.allTransactions)
    : {}

  const handleSendClick = () => {
    navigate(`/send/${isoCode}`)
  }

  const handleReceiveClick = () => {
    navigate(`/receive/${isoCode}`)
  }

  const handleSwapClick = () => {
    navigate(`/swap`)
  }

  return (
    <>
      <div className={styles.container}>
        <div className={styles.currencyContainer}>
          {conversionLoading ? (
            <Skeleton
              height={50}
              width={150}
              baseColor="#070707"
              highlightColor="#1e1e1e"
            />
          ) : (
            <Currency currency={originalCurrency} variant="simple" />
          )}
        </div>
        <div className={styles.amount}>
          <p className={styles.originalCurrency}>
            {conversionLoading ? (
              <Skeleton
                height={40}
                width={200}
                baseColor="#070707"
                highlightColor="#1e1e1e"
              />
            ) : originalCurrency.currencyType === "FIAT" ? (
              <>
                {originalCurrency.symbol}
                {formatBalance(originalAmount)}
              </>
            ) : (
              <>
                {formatBalance(originalAmount)}
                {originalCurrency.symbol && ` ${originalCurrency.symbol}`}
              </>
            )}
          </p>
          <p className={styles.targetCurrency}>
            {conversionLoading ? (
              <Skeleton
                height={20}
                width={150}
                baseColor="#070707"
                highlightColor="#1e1e1e"
              />
            ) : targetCurrency.currencyType === "FIAT" ? (
              <>
                {targetCurrency.symbol}
                {formatBalance(convertedAmount)}
              </>
            ) : (
              <>
                {formatBalance(convertedAmount)}
                {targetCurrency.symbol && ` ${targetCurrency.symbol}`}
              </>
            )}
          </p>
        </div>
        <div className={styles.actionButton}>
          <ActionButton action="Send" onClick={handleSendClick} />
          <ActionButton action="Receive" onClick={handleReceiveClick} />
          <ActionButton action="Swap" onClick={handleSwapClick} />
        </div>
      </div>
      <div className={styles.transactionsContainer}>
        {transactionsLoading
          ? Array(5)
              .fill()
              .map((_, index) => (
                <Skeleton
                  key={index}
                  height={60}
                  width="100%"
                  baseColor="#070707"
                  highlightColor="#1e1e1e"
                />
              ))
          : Object.keys(groupedTransactions).map((date) => (
              <div key={date}>
                <div className={styles.dateHeader}>{date}</div>
                <div className={styles.transactions}>
                  {groupedTransactions[date].map((transaction) => (
                    <TransactionContainer
                      key={transaction.id}
                      transaction={transaction}
                    />
                  ))}
                </div>
              </div>
            ))}
      </div>
    </>
  )
}

export default CurrencyPage
