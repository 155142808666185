import { gql } from "@apollo/client"

export const CREATE_STATIC_INVOICE = gql`
  mutation CreateStaticInvoice(
    $walletId: UUID!
    $name: String!
    $network: String!
  ) {
    createStaticInvoice(walletId: $walletId, name: $name, network: $network) {
      ok
      staticInvoice {
        id
        name
        status
        network {
          name
          symbol
        }
        address {
          subAddress
        }
      }
      message
    }
  }
`
