import { useSelector } from "react-redux"
import TabMenu from "../assets/UI/TabMenu"
import AllStaticInvoice from "../components/AllStaticInvoice"
import Invoice from "../components/Invoice"
import StaticInvoice from "../components/StaticInvoice"

function InvoicePage() {
  const walletId = useSelector((state) => state.wallet.wallet.id)

  const tabs = [
    {
      key: "Invoice",
      label: "Invoice",
      content: <Invoice walletId={walletId} />,
    },
    {
      key: "Static Invoice",
      label: "Static Invoice",
      content: <StaticInvoice walletId={walletId} />,
    },
    {
      key: "All Static Invoice",
      label: "All Static Invoice",
      content: <AllStaticInvoice walletId={walletId} />,
    },
  ]
  return (
    <>
      <TabMenu tabs={tabs} />
    </>
  )
}

export default InvoicePage
