import { useQuery } from "@apollo/client"
import { TOTAL_CONVERTED_BALANCE } from "../../apollo/graphql/Query/totalConvertedBalance"
import formatBalance from "../../utils/formatBalance"
import Skeleton, { SkeletonTheme } from "react-loading-skeleton"
import "react-loading-skeleton/dist/skeleton.css"
import styles from "../styles/UI/TotalBalance.module.css"

const TotalBalance = ({ walletId }) => {
  const { loading, error, data } = useQuery(TOTAL_CONVERTED_BALANCE, {
    variables: { walletId },
    fetchPolicy: "network-only",
  })

  if (error) return <p>Error fetching balance: {error.message}</p>

  const balance = data?.totalConvertedBalance?.totalConvertedBalance
  const currencySymbol = data?.totalConvertedBalance?.targetCurrency?.symbol
  const currencyType = data?.totalConvertedBalance?.targetCurrency?.currencyType

  return (
    <div className={styles.totalBalanceContainer}>
      <SkeletonTheme baseColor="#070707" highlightColor="#1e1e1e">
        <div className={styles.label}>
          {loading ? <Skeleton width={200} height={37} /> : "Total Balance"}
        </div>
        <div className={styles.balance}>
          {loading ? (
            <Skeleton width={300} height={77} />
          ) : currencyType === "FIAT" ? (
            <>
              {currencySymbol}
              {formatBalance(balance)}
            </>
          ) : (
            <>
              {formatBalance(balance)} {currencySymbol}
            </>
          )}
        </div>
      </SkeletonTheme>
    </div>
  )
}

export default TotalBalance
