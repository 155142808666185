import { useSelector } from "react-redux"
import TabMenu from "../assets/UI/TabMenu"
import Wallets from "../components/Wallets"
import Settings from "../components/Settings"

const AccountPage = () => {
  const currentWallet = useSelector((state) => state.wallet.wallet)

  const tabs = [
    {
      key: "wallets",
      label: "Wallets",
      content: <Wallets currentWallet={currentWallet} />,
    },
    {
      key: "settings",
      label: "Settings",
      content: <Settings currentWallet={currentWallet} />,
    },
  ]

  return (
    <div>
      <TabMenu tabs={tabs} />
    </div>
  )
}

export default AccountPage
