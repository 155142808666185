import { gql } from "@apollo/client"

export const CHANGE_PASSWORD_MUTATION = gql`
  mutation ChangePassword(
    $oldPassword: String!
    $password: String!
    $confirmPassword: String!
  ) {
    changePassword(
      oldPassword: $oldPassword
      password: $password
      confirmPassword: $confirmPassword
    ) {
      ok
      message
    }
  }
`
