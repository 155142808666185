import { gql } from "@apollo/client"

export const GET_INVOICE_OR_STATIC_INVOICE_BY_ID = gql`
  query GetInvoiceOrStaticInvoiceById($invoiceId: UUID!) {
    getInvoiceOrStaticInvoiceById(invoiceId: $invoiceId) {
      __typename
      ... on InvoiceType {
        id
        wallet {
          id
          user {
            id
            email
            firstName
            lastName
          }
          business {
            id
            legalName
          }
          walletType
        }
        amount
        currency {
          id
          name
          symbol
          isoCode
          logoUrl
          currencyType
        }
        supportedCurrencies {
          id
          currency {
            id
            name
            symbol
            isoCode
            logoUrl
            currencyType
          }
          blockchain {
            id
            fullName
            name
            symbol
            logoUrl
          }
        }
        selectedCurrency {
          id
          currency {
            id
            name
            symbol
            isoCode
            logoUrl
          }
          blockchain {
            id
            fullName
            name
            symbol
            logoUrl
          }
        }
        address {
          id
          subAddress
        }
        expectedAmount
        receivedAmount
        expireTime
        expirationDate
        invoiceStatus: status
      }
      ... on StaticInvoiceType {
        id
        wallet {
          id
          user {
            id
            email
            firstName
            lastName
          }
          business {
            id
            legalName
          }
          walletType
        }
        name
        network {
          id
          fullName
          name
          symbol
          logoUrl
        }
        address {
          id
          subAddress
        }
        staticInvoiceStatus: status
      }
    }
  }
`
