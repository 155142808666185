import { useState } from "react"
import { useMutation } from "@apollo/client"
import { useNavigate } from "react-router-dom"
import Button from "../assets/UI/Button"
import Modal from "../assets/UI/Modal"
import LabelButton from "../assets/UI/LabelButton"
import Input from "../assets/UI/Input"
import { UPDATE_OFFLINE_STORE } from "../apollo/graphql/Mutation/updateOfflineStore"
import { DELETE_OFFLINE_STORE } from "../apollo/graphql/Mutation/deleteOfflineStore"
import styles from "../assets/styles/Components/OfflineStoreSettings.module.css"

const OfflineStoreSettings = ({
  offlineStore,
  refetchOfflineStores,
  refetchOfflineStore,
}) => {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [modalTitle, setModalTitle] = useState("")
  const [modalValue, setModalValue] = useState("")
  const [updateField, setUpdateField] = useState("")
  const [errors, setErrors] = useState({})
  const [updateOfflineStore, { loading: updateLoading }] =
    useMutation(UPDATE_OFFLINE_STORE)
  const [deleteOfflineStore, { loading: deleteLoading }] =
    useMutation(DELETE_OFFLINE_STORE)

  const navigate = useNavigate()

  const handleOpenEditModal = (field, value) => {
    setModalTitle(field === "name" ? "Edit Store Name" : "Edit Address")
    setModalValue(value)
    setUpdateField(field)
    setIsEditModalOpen(true)
  }

  const handleUpdateValue = () => {
    if (!modalValue.trim()) {
      setErrors({ [updateField]: `Please enter a valid ${updateField}.` })
      return
    }

    const updatedData = {
      storeId: offlineStore.id,
      [updateField]: modalValue.trim(),
    }

    updateOfflineStore({
      variables: updatedData,
    })
      .then(({ data }) => {
        if (data.updateOfflineStore.ok) {
          alert("Offline store updated successfully.")
          refetchOfflineStore()
          setIsEditModalOpen(false)
          setErrors({})
        } else {
          alert(`Error: ${data.updateOfflineStore.message}`)
        }
      })
      .catch((error) => {
        console.error("Error updating offline store:", error)
        alert("An unexpected error occurred while updating the offline store.")
      })
  }

  const handleDeleteStore = async () => {
    try {
      const { data } = await deleteOfflineStore({
        variables: {
          storeId: offlineStore.id,
        },
      })

      if (data.deleteOfflineStore.ok) {
        alert("Offline store deleted successfully.")
        setIsDeleteModalOpen(false)
        refetchOfflineStores()
        navigate("/integration")
      } else {
        alert(`Error: ${data.deleteOfflineStore.message}`)
      }
    } catch (error) {
      console.error("Error deleting offline store:", error)
      alert("An unexpected error occurred while deleting the offline store.")
    }
  }

  return (
    <div className={styles.container}>
      <LabelButton
        label="Store Name"
        onClick={() => handleOpenEditModal("name", offlineStore.name)}
        arrow
      >
        {offlineStore.name}
      </LabelButton>

      <LabelButton
        label="Address"
        onClick={() => handleOpenEditModal("address", offlineStore.address)}
        arrow
      >
        {offlineStore.address}
      </LabelButton>

      <div style={{ display: "flex", gap: "10px" }}>
        <Button onClick={() => setIsDeleteModalOpen(true)} danger>
          Delete Store
        </Button>
      </div>

      {isEditModalOpen && (
        <Modal title={modalTitle} onClose={() => setIsEditModalOpen(false)}>
          <Input
            label={modalTitle}
            value={modalValue}
            onChange={(e) => setModalValue(e.target.value)}
            placeholder={`Enter ${updateField}`}
            error={errors[updateField]}
          />
          <div
            style={{
              display: "flex",
              gap: "10px",
              justifyContent: "center",
              marginTop: "20px",
            }}
          >
            <Button onClick={handleUpdateValue} disabled={updateLoading}>
              {updateLoading ? "Updating..." : "Update"}
            </Button>
            <Button onClick={() => setIsEditModalOpen(false)}>Cancel</Button>
          </div>
        </Modal>
      )}

      {isDeleteModalOpen && (
        <Modal title="Delete Store" onClose={() => setIsDeleteModalOpen(false)}>
          <p>Are you sure you want to delete this store?</p>
          <p>This action cannot be undone!</p>
          <div
            style={{
              display: "flex",
              gap: "10px",
              justifyContent: "center",
            }}
          >
            <Button onClick={handleDeleteStore} disabled={deleteLoading} danger>
              {deleteLoading ? "Deleting..." : "Yes, Delete"}
            </Button>
            <Button onClick={() => setIsDeleteModalOpen(false)}>Cancel</Button>
          </div>
        </Modal>
      )}
    </div>
  )
}

export default OfflineStoreSettings
