import BigNumber from "bignumber.js"

function formatBalance(value, isCrypto = false) {
  if (value instanceof BigNumber) {
    value = value.toString()
  }

  const numberValue = Number(value)

  if (isNaN(numberValue) || value === "") {
    return value
  }

  if (isCrypto) {
    return numberValue
      .toLocaleString("en-US", {
        minimumFractionDigits: 0,
        maximumFractionDigits: 6,
      })
      .replace(/(\.\d*?[1-9])0+$/g, "$1")
  }

  return numberValue.toLocaleString("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })
}

export default formatBalance
