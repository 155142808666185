import { gql } from "@apollo/client"

export const ALL_CURRENCIES = gql`
  query AllCurrencies(
    $walletId: UUID!
    $withPositiveBalance: Boolean
    $currencyType: String
  ) {
    allCurrencies(
      walletId: $walletId
      withPositiveBalance: $withPositiveBalance
      currencyType: $currencyType
    ) {
      currency {
        id
        name
        isoCode
        symbol
        currencyType
        logoUrl
        isActive
      }
      balance
      blockchains {
        blockchain {
          id
          fullName
          name
          logoUrl
        }
        isActive
        tokenAddress
        withdrawalCommission
        minimumWithdrawalAmount
      }
    }
  }
`
