import { useQuery } from "@apollo/client"
import { useDispatch } from "react-redux"
import { USER_WALLETS } from "../apollo/graphql/Query/userWallets"
import LabelButton from "../assets/UI/LabelButton"
import { useNavigate } from "react-router-dom"
import { setWallet } from "../redux/walletSlice"
import styles from "../assets/styles/Components/WalletList.module.css"

const WalletList = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { loading, error, data } = useQuery(USER_WALLETS)

  const handleSelectWallet = (wallet) => {
    dispatch(setWallet(wallet))
    navigate("/wallet")
  }

  if (loading) return <p>Loading wallets...</p>
  if (error) return <p>Error loading wallets: {error.message}</p>

  const sortedWallets = [...data.userWallets].sort((a, b) => {
    if (a.walletType === "PERSONAL" && b.walletType === "BUSINESS") {
      return -1
    } else if (a.walletType === "BUSINESS" && b.walletType === "PERSONAL") {
      return 1
    }
    return 0
  })

  return (
    <div className={styles.walletList}>
      <h2>Select a Wallet</h2>
      <div className={styles.walletListContainer}>
        {sortedWallets.map((wallet) => (
          <LabelButton
            key={wallet.id}
            onClick={() => handleSelectWallet(wallet)}
            label={
              wallet.walletType === "PERSONAL"
                ? "Personal"
                : wallet.walletType === "BUSINESS"
                ? "Business"
                : "Unknown Wallet"
            }
            icon={
              wallet.walletType === "PERSONAL" && wallet.user
                ? wallet.user.avatarUrl
                : wallet.business?.avatarUrl
            }
            size="normal"
            withBackground={true}
          >
            {wallet.walletType === "PERSONAL" && wallet.user && (
              <div>
                <p>
                  {wallet.user.firstName} {wallet.user.lastName}
                </p>
              </div>
            )}
            {wallet.walletType === "BUSINESS" && wallet.business && (
              <div>
                <p>{wallet.business.legalName}</p>
              </div>
            )}
          </LabelButton>
        ))}
      </div>
    </div>
  )
}

export default WalletList
