import { gql } from "@apollo/client"

export const UPDATE_OFFLINE_STORE_CURRENCY_BLOCKCHAINS = gql`
  mutation UpdateOfflineStoreCurrencies(
    $storeId: UUID!
    $currencyId: ID!
    $blockchainIds: [ID!]!
  ) {
    updateOfflineStoreCurrencies(
      storeId: $storeId
      currencyId: $currencyId
      blockchainIds: $blockchainIds
    ) {
      ok
      offlineStore {
        id
        name
        supportedCurrencies {
          id
          currency {
            id
            name
            symbol
            isoCode
            logoUrl
            isActive
          }
          blockchain {
            id
            fullName
            name
            symbol
            logoUrl
            isActive
          }
        }
      }
      message
    }
  }
`
