import { gql } from "@apollo/client"

export const CHANGE_PHONE_NUMBER_MUTATION = gql`
  mutation ChangePhoneNumber($newPhoneNumber: String!) {
    changePhoneNumber(newPhoneNumber: $newPhoneNumber) {
      ok
      message
      token
      refreshToken
    }
  }
`
