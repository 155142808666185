import { gql } from "@apollo/client"

export const UPDATE_WEBSITE = gql`
  mutation UpdateWebsite(
    $websiteId: UUID!
    $name: String
    $url: String
    $expireTime: Int
  ) {
    updateWebsite(
      websiteId: $websiteId
      name: $name
      url: $url
      expireTime: $expireTime
    ) {
      ok
      website {
        id
        name
        url
        expireTime
        isActive
        isVerified
        createdAt
      }
      message
    }
  }
`
