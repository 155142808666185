import { useState } from "react"
import { useMutation } from "@apollo/client"
import { VERIFY_USER } from "../apollo/graphql/Mutation/verifyUser"
import Input from "../assets/UI/Input"
import Button from "../assets/UI/Button"
import styles from "../assets/styles/Components/LoginInputs.module.css"

const LoginInputs = ({
  email,
  setEmail,
  setPassword,
  onSuccess,
  setTwoFactorAuthenticated,
}) => {
  const [password, setPasswordState] = useState("")
  const [emailError, setEmailError] = useState(null)
  const [passwordError, setPasswordError] = useState(null)
  const [verifyUser, { loading }] = useMutation(VERIFY_USER)

  const handleVerifyUser = async () => {
    setEmailError(null)
    setPasswordError(null)

    if (!email) {
      setEmailError("Email is required")
      return
    }

    if (!password) {
      setPasswordError("Password is required")
      return
    }

    try {
      const { data } = await verifyUser({
        variables: { email, password },
      })

      if (data.verifyUser.success) {
        setEmailError(null)
        setPasswordError(null)
        setPassword(password)
        onSuccess(true)
      } else {
        const errors = data.verifyUser.errors || ["Verification failed"]
        const emailRelatedErrors = errors.filter((error) =>
          error.toLowerCase().includes("email")
        )
        const passwordRelatedErrors = errors.filter((error) =>
          error.toLowerCase().includes("password")
        )

        setEmailError(emailRelatedErrors.join(", ") || null)
        setPasswordError(passwordRelatedErrors.join(", ") || null)

        if (!emailRelatedErrors.length && !passwordRelatedErrors.length) {
          setPasswordError(errors.join(", "))
        }

        setTwoFactorAuthenticated(false)
        onSuccess(false)
      }
    } catch {
      setEmailError("An error occurred. Please try again.")
      setPasswordError("An error occurred. Please try again.")
      setTwoFactorAuthenticated(false)
      onSuccess(false)
    }
  }

  return (
    <div className={styles.container}>
      <Input
        id="email"
        label="Email"
        type="email"
        placeholder="Enter your email"
        name="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        error={emailError}
        onFocus={() => setEmailError(null)}
      />
      <Input
        id="password"
        label="Password"
        type="password"
        placeholder="Enter your password"
        name="password"
        value={password}
        onChange={(e) => {
          setPasswordState(e.target.value)
          setPassword(e.target.value)
        }}
        error={passwordError}
        onFocus={() => setPasswordError(null)}
      />
      <Button
        onClick={handleVerifyUser}
        disabled={loading}
        className={styles.loginButton}
      >
        {loading ? "Verifying..." : "Verify"}
      </Button>
    </div>
  )
}

export default LoginInputs
