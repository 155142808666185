import { gql } from "@apollo/client"

export const TOGGLE_PINNED_CONTACT = gql`
  mutation TogglePinnedContact($walletId: UUID!, $contactUserId: UUID!) {
    togglePinnedContact(walletId: $walletId, contactUserId: $contactUserId) {
      ok
      contact {
        id
        contactUser {
          id
          email
        }
        contactType
      }
    }
  }
`
