import { useState, useEffect } from "react"
import { useParams, useNavigate } from "react-router-dom"
import { useQuery, useMutation } from "@apollo/client"
import { CURRENCY_BY_ISO } from "../apollo/graphql/Query/currencyByIso"
import { SEND_TRANSACTION } from "../apollo/graphql/Mutation/sendTransaction"
import LabelButton from "../assets/UI/LabelButton"
import Input from "../assets/UI/Input"
import Button from "../assets/UI/Button"
import Modal from "../assets/UI/Modal"
import formatBalance from "../utils/formatBalance"
import {
  isValidPositiveNumber,
  normalizeDecimalSeparator,
} from "../utils/inputValidation"
import Currency from "../assets/UI/Currency"
import { useSelector } from "react-redux"
import BigNumber from "bignumber.js"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import styles from "../assets/styles/Pages/SendPage.module.css"

const SendPage = () => {
  const { isoCode } = useParams()
  const walletId = useSelector((state) => state.wallet.wallet.id)
  const navigate = useNavigate()

  const [isNetworkModalOpen, setIsNetworkModalOpen] = useState(false)
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false)
  const [selectedNetwork, setSelectedNetwork] = useState(null)
  const [amount, setAmount] = useState("")
  const [address, setAddress] = useState("")
  const [error, setError] = useState("")
  const [totalCommission, setTotalCommission] = useState(new BigNumber(0))

  const {
    loading,
    error: queryError,
    data,
  } = useQuery(CURRENCY_BY_ISO, {
    variables: { walletId, isoCode },
    fetchPolicy: "network-only",
    skip: !walletId || !isoCode,
  })

  const [
    sendTransaction,
    { loading: sendingTransaction, error: transactionError },
  ] = useMutation(SEND_TRANSACTION)

  useEffect(() => {
    if (data && data.currencyByIso) {
      const blockchains = data.currencyByIso.blockchains
      if (blockchains.length === 1) {
        setSelectedNetwork(blockchains[0])
      } else if (blockchains.length > 1) {
        setIsNetworkModalOpen(true)
      }
    }
  }, [data])

  const calculateTotalCommission = (currentAmount = amount) => {
    if (!selectedNetwork) {
      setTotalCommission(new BigNumber(0))
      return
    }

    const fixedCommission = new BigNumber(
      selectedNetwork.withdrawalCommission || 0
    ).decimalPlaces(6, BigNumber.ROUND_DOWN)

    const multiplierPercentage = new BigNumber(
      selectedNetwork.withdrawalCommissionMultiplierPercentage || 0
    )

    let amountValue
    if (currentAmount === "" || currentAmount === ".") {
      amountValue = new BigNumber(0)
    } else {
      amountValue = new BigNumber(currentAmount)
    }

    const variableCommission = amountValue
      .multipliedBy(multiplierPercentage.dividedBy(100))
      .decimalPlaces(6, BigNumber.ROUND_DOWN)

    const total = fixedCommission
      .plus(variableCommission)
      .decimalPlaces(6, BigNumber.ROUND_DOWN)

    setTotalCommission(total)
  }

  const handleAmountChange = (e) => {
    const value = e.target.value
    if (isValidPositiveNumber(value)) {
      const normalizedValue = normalizeDecimalSeparator(value)
      setAmount(normalizedValue)
      calculateTotalCommission(normalizedValue)
      setError("")
    } else {
      setError("Please enter a valid positive number.")
    }
  }

  useEffect(() => {
    calculateTotalCommission()
  }, [selectedNetwork])

  if (loading) return <p>Loading...</p>
  if (queryError) return <p>Error: {queryError.message}</p>

  const currencyData = data.currencyByIso
  const selectedBlockchain = selectedNetwork?.blockchain
  const minimumWithdrawalAmount = new BigNumber(
    selectedNetwork?.minimumWithdrawalAmount || 0
  ).decimalPlaces(6, BigNumber.ROUND_DOWN)

  const handleMaxClick = () => {
    const balance = new BigNumber(currencyData.balance || 0)
    const fixedCommission = new BigNumber(
      selectedNetwork?.withdrawalCommission || 0
    ).decimalPlaces(6, BigNumber.ROUND_DOWN)

    const multiplierPercentage = new BigNumber(
      selectedNetwork?.withdrawalCommissionMultiplierPercentage || 0
    )

    const percentageMultiplier = new BigNumber(1).plus(
      multiplierPercentage.dividedBy(100)
    )

    if (balance.isGreaterThan(fixedCommission)) {
      const maxAmount = balance
        .minus(fixedCommission)
        .dividedBy(percentageMultiplier)
        .decimalPlaces(6, BigNumber.ROUND_DOWN)

      setAmount(maxAmount.toString())
      calculateTotalCommission(maxAmount.toString())
      setError("")
    } else {
      setAmount("0")
      setError("Insufficient funds to cover the commission.")
    }
  }

  const handleBlur = () => {
    const amountValue =
      amount === "" || amount === "." ? new BigNumber(0) : new BigNumber(amount)
    const balanceValue = new BigNumber(currencyData.balance || 0)

    if (amountValue.plus(totalCommission).isGreaterThan(balanceValue)) {
      setError(
        "Total amount exceeds your available balance including commission."
      )
    } else if (amountValue.isLessThan(minimumWithdrawalAmount)) {
      setError(
        `Minimum withdrawal amount is ${formatBalance(
          minimumWithdrawalAmount,
          true
        )} ${currencyData.currency.isoCode}`
      )
    } else {
      setError("")
    }
  }

  const handleFocus = () => {
    setError("")
  }

  const handleOpenNetworkModal = () => {
    setIsNetworkModalOpen(true)
  }

  const handleCloseNetworkModal = () => {
    setIsNetworkModalOpen(false)
  }

  const handleOpenConfirmModal = () => {
    if (error || !amount || amount === "." || parseFloat(amount) <= 0) {
      toast.error("Please enter a valid amount before confirming.")
      return
    }
    setIsConfirmModalOpen(true)
  }

  const handleCloseConfirmModal = () => {
    setIsConfirmModalOpen(false)
  }

  const isFormValid =
    !!address &&
    !!amount &&
    amount !== "." &&
    !!selectedNetwork &&
    !error &&
    parseFloat(amount) > 0

  const handleConfirmTransaction = () => {
    sendTransaction({
      variables: {
        walletId,
        receiverAddress: address,
        amount: parseFloat(amount),
        currency: currencyData.currency.isoCode,
        network: selectedBlockchain.name,
      },
    })
      .then(({ data }) => {
        const { ok, message } = data.sendTransaction
        if (ok) {
          navigate("/wallet")
        } else {
          toast.error("Transaction error: " + message)
        }
        handleCloseConfirmModal()
      })
      .catch((e) => {
        toast.error("An error occurred: " + e.message)
      })
  }

  return (
    <>
      <div className={styles.container}>
        <p className={styles.pageName}>Send</p>
        <LabelButton
          icon={currencyData.currency.logoUrl}
          arrow={true}
          onClick={() => navigate("/send")}
        >
          {currencyData.currency.name}
        </LabelButton>

        <Input
          label="Address"
          value={address}
          rightButtonLabel="Paste"
          onRightButtonClick={() => {
            navigator.clipboard
              .readText()
              .then(setAddress)
              .catch((err) => {
                setError("Failed to paste address: " + err.message)
              })
          }}
          onChange={(e) => setAddress(e.target.value)}
        />

        <LabelButton
          icon={selectedNetwork ? selectedNetwork.blockchain.logoUrl : null}
          active={false}
          arrow={true}
          onClick={handleOpenNetworkModal}
        >
          {selectedNetwork
            ? selectedNetwork.blockchain.fullName
            : "Select Network"}
        </LabelButton>

        <Input
          label="Amount"
          type="text"
          placeholder="0"
          rightText={currencyData.currency.isoCode}
          rightButtonLabel="MAX"
          value={amount}
          onChange={handleAmountChange}
          onBlur={handleBlur}
          onFocus={handleFocus}
          onRightButtonClick={handleMaxClick}
          secondaryLabel={`Available: ${formatBalance(
            currencyData.balance,
            true
          )} ${currencyData.currency.isoCode}`}
          error={error}
        />

        {selectedBlockchain && (
          <>
            <p>
              Commission: {formatBalance(totalCommission.toString(), true)}{" "}
              {currencyData.currency.isoCode}
            </p>
            <p>
              Minimum withdrawal amount:{" "}
              {formatBalance(minimumWithdrawalAmount.toString(), true)}{" "}
              {currencyData.currency.isoCode}
            </p>
          </>
        )}
        <div className={styles.sendBtn}>
          <Button disabled={!isFormValid} onClick={handleOpenConfirmModal}>
            Send
          </Button>
        </div>
      </div>

      {isNetworkModalOpen && (
        <Modal
          title="Select Network"
          onClose={handleCloseNetworkModal}
          width="50vh"
        >
          {currencyData.blockchains.map((blockchain, index) => (
            <LabelButton
              key={index}
              icon={blockchain.blockchain.logoUrl}
              onClick={() => {
                setSelectedNetwork(blockchain)
                handleCloseNetworkModal()
              }}
              disabled={!blockchain.isActive}
              size="small"
            >
              {blockchain.blockchain.name}
            </LabelButton>
          ))}
        </Modal>
      )}

      {isConfirmModalOpen && (
        <Modal title="Confirm Transaction" onClose={handleCloseConfirmModal}>
          <Currency currency={currencyData.currency} variant="simple" />
          <p>
            Amount: {amount} {currencyData.currency.isoCode}
          </p>
          <p>
            Commission: {formatBalance(totalCommission.toString(), true)}{" "}
            {currencyData.currency.isoCode}
          </p>
          <p>Network: {selectedBlockchain.fullName}</p>
          <p>Address: {address}</p>
          <div className={styles.sendBtn}>
            <Button
              onClick={handleConfirmTransaction}
              disabled={sendingTransaction}
            >
              {sendingTransaction ? "Processing..." : "Confirm"}
            </Button>
          </div>
          {transactionError && <p>Error: {transactionError.message}</p>}
        </Modal>
      )}
      <ToastContainer />
    </>
  )
}

export default SendPage
