import { gql } from "@apollo/client"

export const SUBMIT_KYB = gql`
  mutation SUBMIT_KYB(
    $businessId: ID!
    $document: Upload!
    $proofOfAddress: Upload!
  ) {
    submitKyb(
      businessId: $businessId
      document: $document
      proofOfAddress: $proofOfAddress
    ) {
      ok
      message
    }
  }
`
