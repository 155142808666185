import { gql } from "@apollo/client"

export const OFFLINE_STORE = gql`
  query OfflineStore($offlineStoreId: UUID!) {
    offlineStore(offlineStoreId: $offlineStoreId) {
      id
      name
      address
      isActive
      createdAt
      expireTime
      supportedCurrencies {
        id
        currency {
          id
          name
          symbol
          isoCode
          logoUrl
          isActive
        }
        blockchain {
          id
          fullName
          name
          symbol
          logoUrl
          isActive
        }
      }
      devices {
        id
        owner {
          firstName
          lastName
          avatarUrl
        }
        apiKey
        createdAt
      }
    }
  }
`
