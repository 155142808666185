import { useState } from "react"
import { useMutation } from "@apollo/client"
import { useNavigate } from "react-router-dom"
import Button from "../assets/UI/Button"
import LabelButton from "../assets/UI/LabelButton"
import Modal from "../assets/UI/Modal"
import Input from "../assets/UI/Input"
import { UPDATE_WEBSITE } from "../apollo/graphql/Mutation/updateWebsite"
import { DELETE_WEBSITE } from "../apollo/graphql/Mutation/deleteWebsite"
import styles from "../assets/styles/Components/WebsiteSettings.module.css"

const WebsiteSettings = ({ website, refetchWebsite, refetchWebsites }) => {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [modalTitle, setModalTitle] = useState("")
  const [modalValue, setModalValue] = useState("")
  const [updateField, setUpdateField] = useState("")
  const [updateWebsite, { loading: updateLoading }] =
    useMutation(UPDATE_WEBSITE)
  const [deleteWebsite, { loading: deleteLoading }] =
    useMutation(DELETE_WEBSITE)
  const navigate = useNavigate()
  const [errors, setErrors] = useState({})

  const handleOpenEditModal = (field, value) => {
    setModalTitle(
      field === "name"
        ? "Edit Website Name"
        : field === "url"
        ? "Edit Website URL"
        : "Edit Expire Time"
    )
    setModalValue(value)
    setUpdateField(field)
    setIsEditModalOpen(true)
  }

  const handleUpdateValue = () => {
    if (!modalValue.trim()) {
      setErrors({ [updateField]: `Please enter a valid ${updateField}.` })
      return
    }

    const variables = {
      websiteId: website.id,
    }

    if (updateField === "name") variables.name = modalValue
    if (updateField === "url") variables.url = modalValue
    if (updateField === "expireTime")
      variables.expireTime = parseInt(modalValue)

    updateWebsite({
      variables,
    })
      .then(({ data }) => {
        if (data.updateWebsite.ok) {
          alert("Website updated successfully.")
          refetchWebsite()
          setIsEditModalOpen(false)
          setErrors({})
        } else {
          alert(`Error: ${data.updateWebsite.message}`)
        }
      })
      .catch((error) => {
        console.error("Error updating website:", error)
        alert("An unexpected error occurred while updating the website.")
      })
  }

  const handleDeleteWebsite = async () => {
    try {
      const { data } = await deleteWebsite({
        variables: { websiteId: website.id },
      })

      if (data.deleteWebsite.ok) {
        alert("Website deleted successfully.")
        setIsDeleteModalOpen(false)
        refetchWebsites()
        navigate("/integration")
      } else {
        alert(`Error: ${data.deleteWebsite.message}`)
      }
    } catch (error) {
      console.error("Error deleting website:", error)
      alert("An unexpected error occurred while deleting the website.")
    }
  }

  return (
    <div className={styles.container}>
      <LabelButton
        label="Website Name"
        onClick={() => handleOpenEditModal("name", website.name)}
        arrow
      >
        {website.name}
      </LabelButton>

      <LabelButton
        label="Website URL"
        onClick={() => handleOpenEditModal("url", website.url)}
        arrow
      >
        {website.url}
      </LabelButton>

      <LabelButton
        label="Expire Time (minutes, max 60)"
        onClick={() => handleOpenEditModal("expireTime", website.expireTime)}
        arrow
      >
        {website.expireTime}
      </LabelButton>

      <div style={{ display: "flex", gap: "10px" }}>
        <Button onClick={() => setIsDeleteModalOpen(true)} danger>
          Delete Website
        </Button>
      </div>

      {isEditModalOpen && (
        <Modal title={modalTitle} onClose={() => setIsEditModalOpen(false)}>
          <Input
            label={modalTitle}
            value={modalValue}
            onChange={(e) => setModalValue(e.target.value)}
            placeholder={`Enter ${updateField}`}
            error={errors[updateField]}
            type={updateField === "expireTime" ? "number" : "text"}
            min={updateField === "expireTime" ? "1" : undefined}
            max={updateField === "expireTime" ? "60" : undefined}
          />
          <div
            style={{
              display: "flex",
              gap: "10px",
              justifyContent: "center",
              marginTop: "20px",
            }}
          >
            <Button onClick={handleUpdateValue} disabled={updateLoading}>
              {updateLoading ? "Updating..." : "Update"}
            </Button>
            <Button onClick={() => setIsEditModalOpen(false)}>Cancel</Button>
          </div>
        </Modal>
      )}

      {isDeleteModalOpen && (
        <Modal
          title="Delete Website"
          onClose={() => setIsDeleteModalOpen(false)}
        >
          <p>Are you sure you want to delete this website?</p>
          <p>This action cannot be undone!</p>
          <div
            style={{ display: "flex", gap: "10px", justifyContent: "center" }}
          >
            <Button
              onClick={handleDeleteWebsite}
              disabled={deleteLoading}
              danger
            >
              {deleteLoading ? "Deleting..." : "Yes, Delete"}
            </Button>
            <Button onClick={() => setIsDeleteModalOpen(false)}>Cancel</Button>
          </div>
        </Modal>
      )}
    </div>
  )
}

export default WebsiteSettings
