import { useParams } from "react-router-dom"
import { useQuery } from "@apollo/client"
import { OFFLINE_STORE } from "../apollo/graphql/Query/offlineStore"
import { ALL_OFFLINE_STORES } from "../apollo/graphql/Query/allOfflineStores"
import TabMenu from "../assets/UI/TabMenu"
import OfflineStoreDevices from "../components/OfflineStoreDevices"
import OfflineStoreSettings from "../components/OfflineStoreSettings"
import OfflineStoreSupportedCurrencies from "../components/OfflineStoreSupportedCurrencies"
import { useSelector } from "react-redux"
import styles from "../assets/styles/Pages/OfflineStorePage.module.css"

const OfflineStorePage = () => {
  const walletId = useSelector((state) => state.wallet.wallet.id)

  const { offlineStoreId } = useParams()
  const { loading, error, data, refetch } = useQuery(OFFLINE_STORE, {
    variables: { offlineStoreId },
  })
  const { refetch: refetchOfflineStores } = useQuery(ALL_OFFLINE_STORES, {
    variables: { walletId },
  })

  if (loading) return <p>Loading offline store data...</p>
  if (error) return <p>Error loading offline store data: {error.message}</p>

  const offlineStore = data.offlineStore

  const tabs = [
    {
      key: "devices",
      label: "Devices",
      content: (
        <OfflineStoreDevices
          offlineStoreId={offlineStoreId}
          devices={offlineStore.devices}
          refetchOfflineStore={refetch}
        />
      ),
    },
    {
      key: "settings",
      label: "Settings",
      content: (
        <OfflineStoreSettings
          offlineStore={offlineStore}
          refetchOfflineStore={refetch}
          refetchOfflineStores={refetchOfflineStores}
        />
      ),
    },
    {
      key: "currencies",
      label: "Supported Currencies",
      content: (
        <OfflineStoreSupportedCurrencies
          walletId={walletId}
          offlineStore={offlineStore}
          refetchOfflineStore={refetch}
        />
      ),
    },
  ]

  return (
    <div className={styles.container}>
      <p className={styles.storeName}>{offlineStore.name}</p>
      <p className={styles.storeAddress}>{offlineStore.address}</p>
      <TabMenu tabs={tabs} />
    </div>
  )
}

export default OfflineStorePage
