import { useState } from "react"
import Modal from "../assets/UI/Modal"
import Input from "../assets/UI/Input"
import OTPInput from "../assets/UI/OTPInput"
import { useMutation } from "@apollo/client"
import { CHECK_AND_SEND_EMAIL_OTP } from "../apollo/graphql/Mutation/checkAndSendEmailOtp"
import { VERIFY_EMAIL_OTP } from "../apollo/graphql/Mutation/verifyEmailOtp"
import { CHECK_AND_SEND_PHONE_OTP } from "../apollo/graphql/Mutation/checkAndSendPhoneOtp"
import { VERIFY_PHONE_OTP } from "../apollo/graphql/Mutation/verifyPhoneOtp"
import { CHANGE_EMAIL_MUTATION } from "../apollo/graphql/Mutation/changeEmail"
import { CHANGE_PHONE_NUMBER_MUTATION } from "../apollo/graphql/Mutation/changePhoneNumber"
import { CREATE_OR_UPDATE_USERNAME_TAG } from "../apollo/graphql/Mutation/createOrUpdateUsernameTag"
import { CHANGE_PASSWORD_MUTATION } from "../apollo/graphql/Mutation/changePassword"

const Settings = ({ currentWallet }) => {
  const [activeModal, setActiveModal] = useState(null)
  const [email, setEmail] = useState("")
  const [emailOtpSent, setEmailOtpSent] = useState(false)
  const [emailVerified, setEmailVerified] = useState(false)
  const [phoneNumber, setPhoneNumber] = useState("")
  const [phoneOtpSent, setPhoneOtpSent] = useState(false)
  const [phoneVerified, setPhoneVerified] = useState(false)
  const [usernameTag, setUsernameTag] = useState("")
  const [passwords, setPasswords] = useState({
    newPassword: "",
    confirmPassword: "",
  })
  const [passwordOtpSent, setPasswordOtpSent] = useState(false)
  const [passwordOtpVerified, setPasswordOtpVerified] = useState(false)

  // GraphQL Mutations
  const [checkAndSendEmailOtp] = useMutation(CHECK_AND_SEND_EMAIL_OTP)
  const [verifyEmailOtp] = useMutation(VERIFY_EMAIL_OTP)
  const [changeEmail] = useMutation(CHANGE_EMAIL_MUTATION)
  const [checkAndSendPhoneOtp] = useMutation(CHECK_AND_SEND_PHONE_OTP)
  const [verifyPhoneOtp] = useMutation(VERIFY_PHONE_OTP)
  const [changePhoneNumber] = useMutation(CHANGE_PHONE_NUMBER_MUTATION)
  const [createOrUpdateUsernameTag] = useMutation(CREATE_OR_UPDATE_USERNAME_TAG)
  const [changePassword] = useMutation(CHANGE_PASSWORD_MUTATION)

  const isBusinessWallet = currentWallet.type === "Business"

  const openModal = (modalName) => {
    setActiveModal(modalName)
  }

  const closeModal = () => {
    setActiveModal(null)
    // Reset all state variables related to modals
    setEmail("")
    setEmailOtpSent(false)
    setEmailVerified(false)
    setPhoneNumber("")
    setPhoneOtpSent(false)
    setPhoneVerified(false)
    setUsernameTag("")
    setPasswords({ newPassword: "", confirmPassword: "" })
    setPasswordOtpSent(false)
    setPasswordOtpVerified(false)
  }

  const saveTokens = (token, refreshToken) => {
    localStorage.setItem("token", token)
    localStorage.setItem("refreshToken", refreshToken)
  }

  const clearTokens = () => {
    localStorage.removeItem("token")
    localStorage.removeItem("refreshToken")
  }

  return (
    <div>
      <h1>Settings</h1>
      <div>
        <h2>Personal Settings</h2>
        <ul>
          <li onClick={() => openModal("contactInfo")}>Contact Info</li>
          <li>Personal Info</li>
          <li>Supported Currencies</li>
          <li>Language</li>
          <li>Currency</li>
        </ul>
      </div>
      {isBusinessWallet && (
        <div>
          <h2>Business Settings</h2>
          <ul>
            <li>Business Info</li>
            <li>Supported Currencies</li>
            <li>Expire Time</li>
          </ul>
        </div>
      )}

      {/* Modals */}
      {activeModal === "contactInfo" && (
        <Modal title="Contact Info" onClose={closeModal}>
          <ul>
            <li onClick={() => openModal("changeEmail")}>Change Email</li>
            <li onClick={() => openModal("changePhone")}>
              Change Phone Number
            </li>
            <li onClick={() => openModal("changeUsernameTag")}>
              Change Username Tag
            </li>
            <li onClick={() => openModal("changePassword")}>Change Password</li>
          </ul>
        </Modal>
      )}

      {/* Change Email Modal */}
      {activeModal === "changeEmail" && (
        <Modal title="Change Email" onClose={closeModal}>
          {!emailOtpSent && !emailVerified && (
            <div>
              <Input
                label="New Email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <button
                onClick={async () => {
                  const { data } = await checkAndSendEmailOtp({
                    variables: { email, userRegistered: false },
                  })
                  if (data.checkAndSendEmailOtp.success) {
                    setEmailOtpSent(true)
                  }
                }}
              >
                Confirm
              </button>
            </div>
          )}
          {emailOtpSent && !emailVerified && (
            <OTPInput
              title="Enter OTP"
              onComplete={async (otp) => {
                const { data } = await verifyEmailOtp({
                  variables: { email, otp },
                })
                if (data.verifyEmailOtp.success) {
                  setEmailVerified(true)
                }
              }}
              secondLabel="Resend OTP"
              onSecondLabelClick={async () => {
                await checkAndSendEmailOtp({
                  variables: { email, userRegistered: false },
                })
              }}
            />
          )}
          {emailVerified && (
            <div>
              <p>Email verified successfully!</p>
              <button
                onClick={async () => {
                  const { data } = await changeEmail({
                    variables: { newEmail: email },
                  })
                  if (data.changeEmail.ok) {
                    const { token, refreshToken } = data.changeEmail
                    saveTokens(token, refreshToken)
                    alert("Email updated successfully")
                    closeModal()
                  } else {
                    alert(data.changeEmail.message)
                  }
                }}
              >
                Update Email
              </button>
            </div>
          )}
        </Modal>
      )}

      {/* Change Phone Number Modal */}
      {activeModal === "changePhone" && (
        <Modal title="Change Phone Number" onClose={closeModal}>
          {!phoneOtpSent && !phoneVerified && (
            <div>
              <Input
                label="New Phone Number"
                type="tel"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
              <button
                onClick={async () => {
                  const { data } = await checkAndSendPhoneOtp({
                    variables: { phoneNumber },
                  })
                  if (data.checkAndSendPhoneOtp.success) {
                    setPhoneOtpSent(true)
                  }
                }}
              >
                Confirm
              </button>
            </div>
          )}
          {phoneOtpSent && !phoneVerified && (
            <OTPInput
              title="Enter OTP"
              onComplete={async (otp) => {
                const { data } = await verifyPhoneOtp({
                  variables: { phoneNumber, otp },
                })
                if (data.verifyPhoneOtp.success) {
                  setPhoneVerified(true)
                }
              }}
              secondLabel="Resend OTP"
              onSecondLabelClick={async () => {
                await checkAndSendPhoneOtp({ variables: { phoneNumber } })
              }}
            />
          )}
          {phoneVerified && (
            <div>
              <p>Phone number verified successfully!</p>
              <button
                onClick={async () => {
                  const { data } = await changePhoneNumber({
                    variables: { newPhoneNumber: phoneNumber },
                  })
                  if (data.changePhoneNumber.ok) {
                    const { token, refreshToken } = data.changePhoneNumber
                    saveTokens(token, refreshToken)
                    alert("Phone number updated successfully")
                    closeModal()
                  } else {
                    alert(data.changePhoneNumber.message)
                  }
                }}
              >
                Update Phone Number
              </button>
            </div>
          )}
        </Modal>
      )}

      {/* Change Password Modal */}
      {activeModal === "changePassword" && (
        <Modal title="Change Password" onClose={closeModal}>
          {!passwordOtpSent && !passwordOtpVerified && (
            <div>
              <p>
                We need to verify your identity. Please check your email for an
                OTP.
              </p>
              <button
                onClick={async () => {
                  const { data } = await checkAndSendEmailOtp({
                    variables: {
                      email: currentWallet.email,
                      userRegistered: true,
                    },
                  })
                  if (data.checkAndSendEmailOtp.success) {
                    setPasswordOtpSent(true)
                  }
                }}
              >
                Send OTP
              </button>
            </div>
          )}
          {passwordOtpSent && !passwordOtpVerified && (
            <OTPInput
              title="Enter OTP"
              onComplete={async (otp) => {
                const { data } = await verifyEmailOtp({
                  variables: { email: currentWallet.email, otp },
                })
                if (data.verifyEmailOtp.success) {
                  setPasswordOtpVerified(true)
                }
              }}
              secondLabel="Resend OTP"
              onSecondLabelClick={async () => {
                await checkAndSendEmailOtp({
                  variables: {
                    email: currentWallet.email,
                    userRegistered: true,
                  },
                })
              }}
            />
          )}
          {passwordOtpVerified && (
            <div>
              <Input
                label="New Password"
                type="password"
                value={passwords.newPassword}
                onChange={(e) =>
                  setPasswords({ ...passwords, newPassword: e.target.value })
                }
              />
              <Input
                label="Confirm Password"
                type="password"
                value={passwords.confirmPassword}
                onChange={(e) =>
                  setPasswords({
                    ...passwords,
                    confirmPassword: e.target.value,
                  })
                }
              />
              <button
                onClick={async () => {
                  const { data } = await changePassword({
                    variables: {
                      password: passwords.newPassword,
                      confirmPassword: passwords.confirmPassword,
                    },
                  })
                  if (data.changePassword.ok) {
                    clearTokens()
                    alert("Password updated successfully. Please log in again.")
                    closeModal()
                  } else {
                    alert(data.changePassword.message)
                  }
                }}
              >
                Update Password
              </button>
            </div>
          )}
        </Modal>
      )}
    </div>
  )
}

export default Settings
