import { gql } from "@apollo/client"

export const DELETE_DEVICE = gql`
  mutation DELETE_DEVICE($userId: UUID!, $deviceId: UUID!) {
    deleteDevice(userId: $userId, deviceId: $deviceId) {
      ok
      message
    }
  }
`
