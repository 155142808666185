import { gql } from "@apollo/client"

export const TOGGLE_STATIC_INVOICE_STATUS = gql`
  mutation ToggleStaticInvoiceStatus($invoiceId: UUID!, $walletId: UUID!) {
    toggleStaticInvoiceStatus(invoiceId: $invoiceId, walletId: $walletId) {
      ok
      invoice {
        id
        status
      }
      message
    }
  }
`
