import { gql } from "@apollo/client"

export const PAY_INVOICE = gql`
  mutation PayInvoice($walletId: UUID!, $invoiceId: UUID!) {
    payInvoice(walletId: $walletId, invoiceId: $invoiceId) {
      ok
      message
    }
  }
`
