import { useState } from "react"
import { useMutation } from "@apollo/client"
import { useNavigate, useLocation } from "react-router-dom"
import { LOGIN_USER_MUTATION } from "../apollo/graphql/Mutation/loginUser"
import OTPEmailVerification from "../components/OTPEmailVerification"
import LoginInputs from "../components/LoginInputs"
import styles from "../assets/styles/Pages/LoginPage.module.css"
import { toast, ToastContainer } from "react-toastify"

const LoginPage = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [email, setEmail] = useState(location.state?.email || "")
  const [password, setPassword] = useState("")
  const [otpVerified, setOtpVerified] = useState(false)
  const [twoFactorAuthenticated, setTwoFactorAuthenticated] = useState(
    location.state?.twoFactorAuthenticated || false
  )
  const isAddingBusiness = location.state?.isAddingBusiness || false

  const [loginUser] = useMutation(LOGIN_USER_MUTATION)

  const handleOtpSuccess = async () => {
    try {
      const { data } = await loginUser({
        variables: { email, password },
      })

      if (data.loginUser.success) {
        localStorage.setItem("token", data.loginUser.token)
        localStorage.setItem("refreshToken", data.loginUser.refreshToken)

        if (isAddingBusiness) {
          navigate("/register", { state: { isAddingBusiness: true } })
        } else {
          navigate("/wallet")
        }
      } else {
        toast.error("Login failed. Please try again.")
      }
    } catch {
      toast.error("An error occurred during login. Please try again.")
    }
  }

  const handleLogin = async () => {
    try {
      const { data } = await loginUser({
        variables: { email, password },
      })

      if (data.loginUser.success) {
        localStorage.setItem("token", data.loginUser.token)
        localStorage.setItem("refreshToken", data.loginUser.refreshToken)

        if (isAddingBusiness) {
          navigate("/register", { state: { isAddingBusiness: true } })
        } else {
          navigate("/wallet")
        }
      } else {
        toast.error("Login failed. Please check your credentials.")
      }
    } catch {
      toast.error("An error occurred during login. Please try again.")
    }
  }

  return (
    <div className={styles.container}>
      <ToastContainer />
      <p className={styles.pageName}>Login</p>
      {!otpVerified ? (
        <LoginInputs
          email={email}
          setEmail={setEmail}
          setPassword={setPassword}
          setTwoFactorAuthenticated={setTwoFactorAuthenticated}
          onSuccess={(success) => {
            if (
              success &&
              twoFactorAuthenticated &&
              email === location.state?.email
            ) {
              handleLogin()
            } else if (success) {
              setOtpVerified(true)
            }
          }}
        />
      ) : (
        <OTPEmailVerification email={email} onSuccess={handleOtpSuccess} />
      )}
    </div>
  )
}

export default LoginPage
