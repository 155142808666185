import { gql } from "@apollo/client"

export const ALL_TRANSACTIONS = gql`
  query AllTransactions(
    $walletId: UUID!
    $fromDate: DateTime
    $toDate: DateTime
    $transactionTypes: [TransactionType]
    $currencyType: String
    $currencyIsoCode: String
  ) {
    allTransactions(
      walletId: $walletId
      fromDate: $fromDate
      toDate: $toDate
      transactionTypes: $transactionTypes
      currencyType: $currencyType
      currencyIsoCode: $currencyIsoCode
    ) {
      ... on CryptocurrencySendTransactionType {
        id
        sender
        receiver
        currency {
          name
          symbol
          isoCode
          logoUrl
          currencyType
        }
        amount
        network {
          name
          symbol
          logoUrl
        }
        txh
        fee
        sendNationalCurrency: nationalCurrency {
          name
          symbol
          isoCode
          logoUrl
          currencyType
        }
        nationalAmount
        sendExchangeRate: exchangeRate
        sendStatus: status
        createdAt
      }
      ... on CryptocurrencyReceiveTransactionType {
        id
        sender
        receiver
        currency {
          name
          symbol
          isoCode
          logoUrl
          currencyType
        }
        amount
        network {
          name
          symbol
          logoUrl
        }
        txh
        receiveNationalCurrency: nationalCurrency {
          name
          symbol
          isoCode
          logoUrl
          currencyType
        }
        nationalAmount
        receiveExchangeRate: exchangeRate
        receiveStatus: status
        createdAt
      }
      ... on SwapTransactionType {
        id
        fromCurrency {
          name
          symbol
          isoCode
          logoUrl
          currencyType
        }
        toCurrency {
          name
          symbol
          isoCode
          logoUrl
          currencyType
        }
        fromAmount
        toAmount
        exchangeRate
        fee
        swapNationalCurrency: nationalCurrency {
          name
          symbol
          isoCode
          logoUrl
          currencyType
        }
        nationalAmount
        nationalExchangeRate
        swapStatus: status
        createdAt
      }
      ... on TransferTransactionType {
        id
        senderWallet {
          id
          user {
            id
            email
            firstName
            lastName
            avatarUrl
          }
          business {
            id
            legalName
            avatarUrl
          }
          walletType
        }
        receiverWallet {
          id
          user {
            id
            email
            firstName
            lastName
            avatarUrl
          }
          business {
            id
            legalName
            avatarUrl
          }
          walletType
        }
        currency {
          name
          symbol
          isoCode
          logoUrl
          currencyType
        }
        amount
        description
        fee
        transferNationalCurrency: nationalCurrency {
          name
          symbol
          isoCode
          logoUrl
          currencyType
        }
        nationalAmount
        transferExchangeRate: exchangeRate
        transferStatus: status
        createdAt
      }
      ... on PaymentTransactionType {
        id
        sender
        receiver
        invoice
        currency {
          name
          symbol
          isoCode
          logoUrl
          currencyType
        }
        amount
        network {
          name
          symbol
          logoUrl
        }
        device {
          id
          owner {
            id
            email
            firstName
            lastName
            avatarUrl
          }
        }
        txh
        fee
        paymentNationalCurrency: nationalCurrency {
          name
          symbol
          isoCode
          logoUrl
          currencyType
        }
        nationalAmount
        paymentExchangeRate: exchangeRate
        paymentStatus: status
        createdAt
      }
      ... on PaymentVortexTransactionType {
        id
        senderWallet {
          id
          user {
            id
            email
            firstName
            lastName
            avatarUrl
          }
          business {
            id
            legalName
            avatarUrl
          }
          walletType
        }
        receiverWallet {
          id
          user {
            id
            email
            firstName
            lastName
            avatarUrl
          }
          business {
            id
            legalName
            avatarUrl
          }
          walletType
        }
        invoice
        currency {
          name
          symbol
          isoCode
          logoUrl
          currencyType
        }
        amount
        device {
          id
          owner {
            id
            email
            firstName
            lastName
            avatarUrl
          }
        }
        fee
        vortexNationalCurrency: nationalCurrency {
          name
          symbol
          isoCode
          logoUrl
          currencyType
        }
        nationalAmount
        vortexExchangeRate: exchangeRate
        paymentVortexStatus: status
        createdAt
      }
    }
  }
`
