import { gql } from "@apollo/client"

export const SUBMIT_KYC = gql`
  mutation SUBMIT_KYC(
    $nationalityId: ID!
    $document: Upload!
    $proofOfAddress: Upload!
  ) {
    submitKyc(
      nationalityId: $nationalityId
      document: $document
      proofOfAddress: $proofOfAddress
    ) {
      ok
      message
    }
  }
`
