import { gql } from "@apollo/client"

export const DELETE_OFFLINE_STORE = gql`
  mutation DeleteOfflineStore($storeId: UUID!) {
    deleteOfflineStore(storeId: $storeId) {
      ok
      message
    }
  }
`
