import { gql } from "@apollo/client"

export const USER_SUMMARY = gql`
  query UserSummary($identifier: String!) {
    userSummary(identifier: $identifier) {
      id
      email
      firstName
      lastName
      usernameTag
      phoneNumber
      avatarUrl
      wallets {
        id
        business {
          legalName
          avatarUrl
        }
        walletType
      }
    }
  }
`
