import { useNavigate } from "react-router-dom"
import formatBalance from "../../utils/formatBalance"
import renderFormattedCurrency from "../../utils/renderFormattedCurrency"
import styles from "../styles/UI/Currency.module.css"

const Currency = ({
  originalAmount,
  convertedAmount,
  currency = {},
  targetCurrency = {},
  exchangeRate,
  percentChange24h,
  isSelected = true,
  action,
  variant = "combined",
  onClick,
}) => {
  const navigate = useNavigate()

  const {
    id: originalCurrencyId,
    logoUrl: originalCurrencyLogoUrl,
    name: originalCurrencyName,
    isoCode: originalCurrencyIsoCode,
    currencyType: originalCurrencyType,
  } = currency || {}

  const { symbol: targetCurrencySymbol, currencyType: targetCurrencyType } =
    targetCurrency || {}

  const handleCurrencyClick = () => {
    if (onClick) {
      onClick(originalCurrencyId)
    }
    if (action) {
      navigate(`/${action}/${originalCurrencyIsoCode}`)
    }
  }

  const formattedOriginalBalance =
    originalAmount !== undefined
      ? formatBalance(originalAmount, originalCurrencyType === "CRYPTO")
      : ""

  const formattedConvertedBalance =
    convertedAmount !== undefined ? formatBalance(convertedAmount) : ""

  const formattedExchangeRate =
    exchangeRate !== undefined ? formatBalance(exchangeRate) : ""

  const formattedPercentChange24h =
    percentChange24h !== undefined ? formatBalance(percentChange24h) : ""

  const currencyClass = isSelected ? "" : styles.currencyNoSelected

  const cursorStyle = onClick || action ? "pointer" : "auto"

  return variant === "simple" ? (
    <div
      className={`${styles.currency} ${currencyClass}`}
      onClick={handleCurrencyClick}
      style={{ cursor: cursorStyle }}
    >
      {originalCurrencyLogoUrl && (
        <img
          src={originalCurrencyLogoUrl}
          alt={`${originalCurrencyName} logo`}
          className={styles.currencyLogo}
        />
      )}
      <div className={styles.currencyContainerSimple}>
        {originalCurrencyIsoCode && (
          <span className={styles.currencyCodeSimple}>
            {originalCurrencyIsoCode}
          </span>
        )}
        {originalCurrencyName && (
          <span className={styles.currencyNameSimple}>
            {originalCurrencyName}
          </span>
        )}
      </div>
    </div>
  ) : variant === "detailed" ? (
    <div
      className={`${styles.currency} ${currencyClass}`}
      onClick={handleCurrencyClick}
      style={{ cursor: cursorStyle }}
    >
      {originalCurrencyLogoUrl && (
        <img
          src={originalCurrencyLogoUrl}
          alt={`${originalCurrencyName} logo`}
          className={styles.currencyLogo}
        />
      )}
      <div className={styles.currencyContainer}>
        <div className={styles.currencyName}>
          {originalCurrencyName && (
            <span className={styles.currencyTitle}>{originalCurrencyName}</span>
          )}

          {(formattedExchangeRate || formattedPercentChange24h) && (
            <div className={styles.currencyExchangeRate}>
              {formattedExchangeRate && (
                <span className={styles.exchangeRate}>
                  {renderFormattedCurrency(
                    targetCurrencyType,
                    targetCurrencySymbol,
                    formattedExchangeRate
                  )}
                </span>
              )}
              {formattedPercentChange24h && (
                <span
                  className={
                    formattedPercentChange24h >= 0
                      ? styles.percentChangePositive
                      : styles.percentChangeNegative
                  }
                >
                  {formattedPercentChange24h > 0
                    ? `+${formattedPercentChange24h}%`
                    : `${formattedPercentChange24h}%`}
                </span>
              )}
            </div>
          )}
        </div>
        <div className={styles.currencyBalance}>
          {formattedOriginalBalance && (
            <span className={styles.balance}>
              {formattedOriginalBalance} {originalCurrencyIsoCode}
            </span>
          )}
          {formattedConvertedBalance && (
            <span className={styles.convertedBalance}>
              {renderFormattedCurrency(
                targetCurrencyType,
                targetCurrencySymbol,
                formattedConvertedBalance
              )}
            </span>
          )}
        </div>
      </div>
    </div>
  ) : (
    <div
      className={`${styles.currency} ${currencyClass}`}
      onClick={handleCurrencyClick}
      style={{ cursor: cursorStyle }}
    >
      {originalCurrencyLogoUrl && (
        <img
          src={originalCurrencyLogoUrl}
          alt={`${originalCurrencyName} logo`}
          className={styles.currencyLogo}
        />
      )}
      <div className={styles.currencyContainerCombined}>
        <div className={styles.currencyLeft}>
          {originalCurrencyIsoCode && (
            <span className={styles.currencyCodeSimple}>
              {originalCurrencyIsoCode}
            </span>
          )}
          {originalCurrencyName && (
            <span className={styles.currencyNameSimple}>
              {originalCurrencyName}
            </span>
          )}
        </div>
        <div className={styles.currencyRight}>
          <div className={styles.currencyBalance}>
            {formattedOriginalBalance && (
              <span className={styles.balance}>
                {formattedOriginalBalance} {originalCurrencyIsoCode}
              </span>
            )}
            {formattedConvertedBalance && (
              <span className={styles.convertedBalance}>
                {renderFormattedCurrency(
                  targetCurrencyType,
                  targetCurrencySymbol,
                  formattedConvertedBalance
                )}
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Currency
