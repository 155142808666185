import { useQuery } from "@apollo/client"
import { ALL_CURRENCIES } from "../apollo/graphql/Query/allCurrencies"
import { useNavigate } from "react-router-dom"
import TabMenu from "../assets/UI/TabMenu"
import Currency from "../assets/UI/Currency"
import { useSelector } from "react-redux"
import styles from "../assets/styles/Pages/SelectReceivePage.module.css"

const SelectReceivePage = () => {
  const walletId = useSelector((state) => state.wallet.wallet.id)

  const { loading, error, data } = useQuery(ALL_CURRENCIES, {
    variables: {
      walletId,
      withPositiveBalance: false,
    },
    skip: !walletId,
  })

  const CryptoReceiveList = () => {
    if (loading) return <p>Loading...</p>
    if (error) return <p>Error fetching cryptocurrencies: {error.message}</p>

    const cryptoCurrencies = data.allCurrencies.filter(
      (currencyObj) => currencyObj.currency.currencyType === "CRYPTO"
    )

    if (cryptoCurrencies.length === 0)
      return <p>No cryptocurrencies available</p>

    return (
      <div className={styles.container}>
        {cryptoCurrencies.map((currencyObj) => {
          const { currency } = currencyObj
          return (
            <Currency
              key={currency.id}
              currency={currency}
              variant="simple"
              action={"receive"}
            />
          )
        })}
      </div>
    )
  }

  const FiatReceiveList = () => {
    if (loading) return <p>Loading...</p>
    if (error) return <p>Error fetching fiat currencies: {error.message}</p>

    const fiatCurrencies = data.allCurrencies.filter(
      (currencyObj) => currencyObj.currency.currencyType === "FIAT"
    )

    if (fiatCurrencies.length === 0) return <p>No fiat currencies available</p>

    return (
      <div className={styles.container}>
        {fiatCurrencies.map((currencyObj) => {
          const { currency } = currencyObj
          return (
            <Currency
              key={currency.id}
              currency={currency}
              // onClick={() => handleCurrencySelect(currencyObj)}
              variant="simple"
            />
          )
        })}
      </div>
    )
  }

  const tabs = [
    {
      key: "Crypto",
      label: "Crypto",
      content: <CryptoReceiveList />,
    },
    {
      key: "Fiat",
      label: "Fiat",
      content: <FiatReceiveList />,
    },
  ]

  return (
    <div className={styles.container}>
      <TabMenu tabs={tabs} />
    </div>
  )
}

export default SelectReceivePage
