import { gql } from "@apollo/client"

export const CREATE_OR_UPDATE_USERNAME_TAG = gql`
  mutation CreateOrUpdateUsernameTag($usernameTag: String!) {
    createOrUpdateUsernameTag(usernameTag: $usernameTag) {
      success
      errors
      usernameTag
    }
  }
`
