import { useState } from "react"
import Input from "../assets/UI/Input"
import Button from "../assets/UI/Button"
import styles from "../assets/styles/Components/PasswordInput.module.css"

function PasswordInput({ onNext }) {
  const [password, setPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const [passwordError, setPasswordError] = useState(null)
  const [confirmPasswordError, setConfirmPasswordError] = useState(null)

  const handleNext = () => {
    let hasError = false

    if (password.length < 8) {
      setPasswordError("Password must be at least 8 characters long")
      hasError = true
    } else {
      setPasswordError(null)
    }

    if (password !== confirmPassword) {
      setConfirmPasswordError("Passwords do not match")
      hasError = true
    } else {
      setConfirmPasswordError(null)
    }

    if (!hasError) {
      onNext({ password, confirmPassword })
    }
  }

  return (
    <div className={styles.container}>
      <Input
        id="password"
        label="Password"
        type="password"
        placeholder="Enter your password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        error={passwordError}
        onFocus={() => setPasswordError(null)}
      />
      <Input
        id="confirmPassword"
        label="Confirm Password"
        type="password"
        placeholder="Confirm your password"
        value={confirmPassword}
        onChange={(e) => setConfirmPassword(e.target.value)}
        error={confirmPasswordError}
        onFocus={() => setConfirmPasswordError(null)}
      />
      <Button onClick={handleNext}>Next</Button>
    </div>
  )
}

export default PasswordInput
