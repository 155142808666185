import { useState } from "react"
import { useMutation } from "@apollo/client"
import { useDispatch, useSelector } from "react-redux"
import { SUBMIT_KYB } from "../../apollo/graphql/Mutation/submitKyb"
import { setWallet } from "../../redux/walletSlice"
import Modal from "./Modal"
import LabelButton from "./LabelButton"
import StatusBadge from "./StatusBadge"
import { toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import styles from "../styles/UI/KYB.module.css"

const KYB = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [document, setDocument] = useState(null)
  const [proofOfAddress, setProofOfAddress] = useState(null)
  const [currentStep, setCurrentStep] = useState(1)

  const dispatch = useDispatch()
  const wallet = useSelector((state) => state.wallet.wallet)
  const businessId = wallet?.business?.id
  const kybStatus = wallet?.business?.kybStatus || "NONE"

  const [submitKYB, { loading: loadingSubmit }] = useMutation(SUBMIT_KYB)

  const handleOpenModal = () => setIsModalOpen(true)
  const handleCloseModal = () => {
    setIsModalOpen(false)
    setDocument(null)
    setProofOfAddress(null)
    setCurrentStep(1)
  }

  const handleFileChange = (e, setFile) => {
    const file = e.target.files[0]
    setFile(file)
  }

  const handleNextStep = () => {
    if (currentStep === 1 && !document) {
      toast.warn("Please upload your business document.")
    } else if (currentStep === 2 && !proofOfAddress) {
      toast.warn("Please upload proof of address.")
    } else {
      setCurrentStep(currentStep + 1)
    }
  }

  const handleSubmit = () => {
    if (document && proofOfAddress && businessId) {
      submitKYB({
        variables: {
          businessId,
          document,
          proofOfAddress,
        },
      })
        .then((response) => {
          toast.success(response.data.submitKyb.message)
          dispatch(
            setWallet({
              ...wallet,
              business: { ...wallet.business, kybStatus: "PENDING" },
            })
          )
          handleCloseModal()
        })
        .catch((error) => {
          console.error("Error submitting KYB:", error)
          toast.error("Failed to submit KYB. Please try again.")
        })
    } else {
      toast.warn("Please complete all steps and upload all required documents.")
    }
  }

  return (
    <>
      <ToastContainer />
      <LabelButton onClick={handleOpenModal} arrow>
        KYB <StatusBadge status={kybStatus} />
      </LabelButton>

      {isModalOpen && (
        <Modal title="Submit KYB" onClose={handleCloseModal}>
          <div className={styles.kybForm}>
            {currentStep === 1 && (
              <div className={styles.field}>
                <label>Upload Business Document:</label>
                <input
                  type="file"
                  accept=".png,.jpeg,.jpg,.pdf"
                  onChange={(e) => handleFileChange(e, setDocument)}
                />
                <LabelButton onClick={handleNextStep}>Next</LabelButton>
              </div>
            )}

            {currentStep === 2 && (
              <div className={styles.field}>
                <label>Upload Proof of Address:</label>
                <input
                  type="file"
                  accept=".png,.jpeg,.jpg,.pdf"
                  onChange={(e) => handleFileChange(e, setProofOfAddress)}
                />
                <LabelButton onClick={handleNextStep}>Next</LabelButton>
              </div>
            )}

            {currentStep === 3 && (
              <div className={styles.field}>
                <LabelButton onClick={handleSubmit} disabled={loadingSubmit}>
                  {loadingSubmit ? "Submitting..." : "Submit KYB"}
                </LabelButton>
              </div>
            )}
          </div>
        </Modal>
      )}
    </>
  )
}

export default KYB
