import { useEffect, useState } from "react"
import Header from "../assets/UI/Header"
import Logo from "../assets/UI/Logo"
import TabMenu from "../assets/UI/TabMenu"
import Currency from "../assets/UI/Currency"
import Input from "../assets/UI/Input"
import formatBalance from "../utils/formatBalance"
import Button from "../assets/UI/Button"
import Modal from "../assets/UI/Modal"
import LabelButton from "../assets/UI/LabelButton"
import Timer from "../assets/UI/Timer"
import { QRCodeCanvas } from "qrcode.react"
import TransactionContainer from "../assets/UI/TransactionContainer"
import { ReactTyped } from "react-typed"
import { useNavigate } from "react-router-dom"
import styles from "../assets/styles/Pages/VortexBusinessPage.module.css"

// Данные для Business аккаунта
const businessCryptoCurrencies = [
  {
    logoUrl:
      "https://vortex-app-media-storage.s3.ap-southeast-1.amazonaws.com/logo/cryptocurrency/bitcoin.png",
    name: "Bitcoin",
    isoCode: "BTC",
    symbol: "$",
    originalAmount: 2,
    convertedAmount: 118074,
    exchangeRate: 59037,
    percentChange24h: 3.2,
    currencyType: "Crypto",
  },
  {
    logoUrl:
      "https://vortex-app-media-storage.s3.ap-southeast-1.amazonaws.com/logo/cryptocurrency/Ethereum+(ETH).png",
    name: "Ethereum",
    isoCode: "ETH",
    symbol: "$",
    originalAmount: 20,
    convertedAmount: 54340,
    exchangeRate: 2717,
    percentChange24h: -0.5,
    currencyType: "Crypto",
  },
  {
    logoUrl:
      "https://vortex-app-media-storage.s3.ap-southeast-1.amazonaws.com/logo/cryptocurrency/Tether+(USDT).png",
    name: "Tether",
    isoCode: "USDT",
    symbol: "$",
    originalAmount: 17000,
    convertedAmount: 17000,
    exchangeRate: 1,
    percentChange24h: 0.01,
    currencyType: "Crypto",
  },
]

const businessFiatCurrencies = [
  {
    logoUrl:
      "https://vortex-app-media-storage.s3.ap-southeast-1.amazonaws.com/logo/country/US+United+States+of+America+(the).png",
    name: "USD",
    isoCode: "USD",
    symbol: "$",
    originalAmount: 50000,
    convertedAmount: 50000,
    exchangeRate: 1,
    currencyType: "Fiat",
  },
  {
    logoUrl:
      "https://vortex-app-media-storage.s3.ap-southeast-1.amazonaws.com/logo/country/EU+European+Union.png",
    name: "EUR",
    isoCode: "EUR",
    symbol: "$",
    originalAmount: 30000,
    convertedAmount: 33000,
    exchangeRate: 1.1,
    currencyType: "Fiat",
  },
  {
    logoUrl:
      "https://vortex-app-media-storage.s3.ap-southeast-1.amazonaws.com/logo/country/SG+Singapore.png",
    name: "SGD",
    isoCode: "SGD",
    symbol: "$",
    originalAmount: 21000,
    convertedAmount: 16170,
    exchangeRate: 0.77,
    currencyType: "Fiat",
  },
]

// Данные для Personal аккаунта
const personalCryptoCurrencies = [
  {
    logoUrl:
      "https://vortex-app-media-storage.s3.ap-southeast-1.amazonaws.com/logo/cryptocurrency/Solana.png",
    name: "Solana",
    isoCode: "SOL",
    symbol: "$",
    originalAmount: 150,
    convertedAmount: 22500, // 150 * 150
    exchangeRate: 150,
    percentChange24h: 0.8,
    currencyType: "Crypto",
  },
  {
    logoUrl:
      "https://vortex-app-media-storage.s3.ap-southeast-1.amazonaws.com/logo/cryptocurrency/Ethereum+(ETH).png",
    name: "Ethereum",
    isoCode: "ETH",
    symbol: "$",
    originalAmount: 5,
    convertedAmount: 13585, // 5 * 2717
    exchangeRate: 2717,
    percentChange24h: -1.2,
    currencyType: "Crypto",
  },
  {
    logoUrl:
      "https://vortex-app-media-storage.s3.ap-southeast-1.amazonaws.com/logo/cryptocurrency/Toncoin.png",
    name: "Toncoin",
    isoCode: "TON",
    symbol: "$",
    originalAmount: 570,
    convertedAmount: 3072.3,
    exchangeRate: 5.39,
    percentChange24h: 1.1,
    currencyType: "Crypto",
  },
]

const personalFiatCurrencies = [
  {
    logoUrl:
      "https://vortex-app-media-storage.s3.ap-southeast-1.amazonaws.com/logo/country/US+United+States+of+America+(the).png",
    name: "USD",
    isoCode: "USD",
    symbol: "$",
    originalAmount: 37000,
    convertedAmount: 37000,
    exchangeRate: 1,
    currencyType: "Fiat",
  },
  {
    logoUrl:
      "https://vortex-app-media-storage.s3.ap-southeast-1.amazonaws.com/logo/country/SG+Singapore.png",
    name: "SGD",
    isoCode: "SGD",
    symbol: "$",
    originalAmount: 2000,
    convertedAmount: 1540, // 2000 * 0.77
    exchangeRate: 0.77,
    currencyType: "Fiat",
  },
  {
    logoUrl:
      "https://vortex-app-media-storage.s3.ap-southeast-1.amazonaws.com/logo/country/EU+European+Union.png",
    name: "EUR",
    isoCode: "EUR",
    symbol: "$",
    originalAmount: 1000,
    convertedAmount: 1100, // 1000 * 1.1
    exchangeRate: 1.1,
    currencyType: "Fiat",
  },
]

const cryptocurrencies = [
  {
    logoUrl:
      "https://vortex-app-media-storage.s3.ap-southeast-1.amazonaws.com/logo/cryptocurrency/bitcoin.png",
    name: "Bitcoin",
    isoCode: "BTC",
  },
  {
    logoUrl:
      "https://vortex-app-media-storage.s3.ap-southeast-1.amazonaws.com/logo/cryptocurrency/Ethereum+(ETH).png",
    name: "Ethereum",
    isoCode: "ETH",
  },
  {
    logoUrl:
      "https://vortex-app-media-storage.s3.ap-southeast-1.amazonaws.com/logo/cryptocurrency/Tether+(USDT).png",
    name: "Tether",
    isoCode: "USDT",
  },
  {
    logoUrl:
      "https://vortex-app-media-storage.s3.ap-southeast-1.amazonaws.com/logo/cryptocurrency/Toncoin.png",
    name: "Toncoin",
    isoCode: "TON",
  },
  {
    logoUrl:
      "https://vortex-app-media-storage.s3.ap-southeast-1.amazonaws.com/logo/cryptocurrency/Solana.png",
    name: "Solana",
    isoCode: "SOL",
  },
]

const transactions = [
  {
    id: "qqq",
    invoice: "qqq",
    sender: "qqq",
    receiver: "qqq",
    currency: {
      name: "Solana",
      symbol: "SOL",
      isoCode: "SOL",
      logoUrl:
        "https://vortex-app-media-storage.s3.ap-southeast-1.amazonaws.com/logo/cryptocurrency/Solana.png",
      currencyType: "CRYPTO",
      __typename: "CurrencyType",
    },
    amount: "10.0000000000",
    network: {
      name: "Solana",
      symbol: "SOL",
      logoUrl: null,
      __typename: "BlockchainNetworkType",
    },
    txh: "qqq",
    fee: "1",
    paymentStatus: "COMPLETED",
    createdAt: "2024-08-23T12:57:00.273863+00:00",
    __typename: "PaymentTransactionType",
  },
  {
    id: "qqq",
    sender: "qqq",
    receiver: "qqq",
    currency: {
      name: "Toncoin",
      symbol: "TON",
      isoCode: "TON",
      logoUrl:
        "https://vortex-app-media-storage.s3.ap-southeast-1.amazonaws.com/logo/cryptocurrency/Toncoin.png",
      currencyType: "CRYPTO",
      __typename: "CurrencyType",
    },
    amount: "111.0000000000",
    network: {
      name: "TON",
      symbol: "TON",
      logoUrl: null,
      __typename: "BlockchainNetworkType",
    },
    txh: "qqq",
    receiveStatus: "COMPLETED",
    createdAt: "2024-08-22T11:11:39.984958+00:00",
    __typename: "CryptocurrencyReceiveTransactionType",
  },
  {
    id: "qqq",
    sender: "qqq",
    receiver: "qqq",
    currency: {
      name: "US Dollar",
      symbol: "$",
      isoCode: "USD",
      logoUrl:
        "https://vortex-app-media-storage.s3.ap-southeast-1.amazonaws.com/logo/country/US+United+States+of+America+(the).png",
      currencyType: "FIAT",
      __typename: "CurrencyType",
    },
    amount: "15000.0000000000",
    network: {
      name: "BANK",
      symbol: "BANK",
      logoUrl: null,
      __typename: "BlockchainNetworkType",
    },
    txh: "qqq",
    fee: "1",
    sendStatus: "COMPLETED",
    createdAt: "2024-08-22T09:33:01.051774+00:00",
    __typename: "CryptocurrencySendTransactionType",
  },
]

const generateRandomAddress = (currency) => {
  let address = ""

  switch (currency.isoCode) {
    case "BTC":
      const btcPrefixes = ["1", "3", "bc1"]
      const btcPrefix =
        btcPrefixes[Math.floor(Math.random() * btcPrefixes.length)]
      address = btcPrefix + Math.random().toString(36).substring(2, 34)
      break

    case "ETH":
      address =
        "0x" +
        Array.from({ length: 40 }, () =>
          Math.floor(Math.random() * 16).toString(16)
        ).join("")
      break

    case "SOL":
      address = Array.from({ length: 44 }, () =>
        Math.random().toString(36).charAt(2)
      ).join("")
      break

    case "TON":
      address =
        "UQ" +
        Array.from({ length: 46 }, () =>
          Math.random().toString(36).charAt(2)
        ).join("")
      break

    default:
      address = Array.from({ length: 34 }, () =>
        Math.random().toString(36).charAt(2)
      ).join("")
      break
  }

  return address
}

const VortexBusinessPage = () => {
  const navigate = useNavigate()
  const [qrSize, setQrSize] = useState(210)
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const handleResize = () => {
    if (window.innerWidth <= 768) {
      setQrSize(170)
    } else {
      setQrSize(210)
    }
  }

  useEffect(() => {
    window.addEventListener("resize", handleResize)
    handleResize()

    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])
  const [businessTotalBalance, setBusinessTotalBalance] = useState(0)
  const [personalTotalBalance, setPersonalTotalBalance] = useState(0)

  // Временные значения полей, которые будут обновляться при вводе данных
  const [tempSelectedCurrency, setTempSelectedCurrency] = useState(
    cryptocurrencies[3]
  ) // Solana by default
  const [tempAmount, setTempAmount] = useState("100")
  const [tempExpireTime, setTempExpireTime] = useState("60")

  // Значения, которые применяются после нажатия кнопки "Create"
  const [selectedCurrency, setSelectedCurrency] = useState(cryptocurrencies[3]) // Solana by default
  const [amount, setAmount] = useState("100")
  const [expireTime, setExpireTime] = useState("60")
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isCreated, setIsCreated] = useState(true) // Инвойс создан при загрузке страницы
  const [errors, setErrors] = useState({})

  // Сгенерированный адрес
  const [address, setAddress] = useState(
    generateRandomAddress(selectedCurrency)
  )

  useEffect(() => {
    // Подсчет общего баланса для Business аккаунта
    const totalBusinessBalance = [
      ...businessCryptoCurrencies,
      ...businessFiatCurrencies,
    ].reduce((sum, currency) => sum + currency.convertedAmount, 0)
    setBusinessTotalBalance(totalBusinessBalance)

    // Подсчет общего баланса для Personal аккаунта
    const totalPersonalBalance = [
      ...personalCryptoCurrencies,
      ...personalFiatCurrencies,
    ].reduce((sum, currency) => sum + currency.convertedAmount, 0)
    setPersonalTotalBalance(totalPersonalBalance)
  }, [])

  const handleCurrencySelect = (currency) => {
    setTempSelectedCurrency(currency)
    setIsModalOpen(false)
  }

  const handleCreateClick = () => {
    const newErrors = {}
    if (!tempAmount || !tempExpireTime) {
      if (!tempAmount) newErrors.amount = "Amount is required."
      if (!tempExpireTime) newErrors.expireTime = "Expire time is required."
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors)
      return
    }

    // Применение временных значений к основным значениям
    setSelectedCurrency(tempSelectedCurrency)
    setAmount(tempAmount)
    setExpireTime(tempExpireTime)

    // Генерация нового адреса при создании инвойса
    setAddress(generateRandomAddress(tempSelectedCurrency))

    setIsCreated(true)
    setErrors({})
  }

  const generateQRCodeValue = () => {
    return address
  }

  const calculateExpireTime = () => {
    const now = new Date()
    now.setMinutes(now.getMinutes() + parseInt(expireTime, 10))
    return now
  }

  const tabsForBusiness = [
    {
      key: "Crypto",
      label: "Crypto",
      content: (
        <div className={styles.currencyListBusiness}>
          {businessCryptoCurrencies.map((currency) => (
            <Currency
              key={currency.isoCode}
              currency={currency}
              originalAmount={currency.originalAmount}
              convertedAmount={currency.convertedAmount}
              exchangeRate={currency.exchangeRate}
              percentChange24h={currency.percentChange24h}
              targetCurrency={{
                symbol: currency.symbol,
                currencyType: currency.currencyType,
              }}
            />
          ))}
        </div>
      ),
    },
    {
      key: "Fiat",
      label: "Fiat",
      content: (
        <div className={styles.currencyListBusiness}>
          {businessFiatCurrencies.map((currency) => (
            <Currency
              key={currency.isoCode}
              currency={currency}
              originalAmount={currency.originalAmount}
              convertedAmount={currency.convertedAmount}
              exchangeRate={currency.exchangeRate}
              percentChange24h={currency.percentChange24h}
              targetCurrency={{
                symbol: currency.symbol,
                currencyType: currency.currencyType,
              }}
            />
          ))}
        </div>
      ),
    },
  ]

  const tabsForPersonal = [
    {
      key: "Crypto",
      label: "Crypto",
      content: (
        <div className={styles.currencyListPersonal}>
          {personalCryptoCurrencies.map((currency) => (
            <Currency
              key={currency.isoCode}
              currency={currency}
              originalAmount={currency.originalAmount}
              convertedAmount={currency.convertedAmount}
              exchangeRate={currency.exchangeRate}
              percentChange24h={currency.percentChange24h}
              targetCurrency={{
                symbol: currency.symbol,
                currencyType: currency.currencyType,
              }}
            />
          ))}
        </div>
      ),
    },
    {
      key: "Fiat",
      label: "Fiat",
      content: (
        <div className={styles.currencyListPersonal}>
          {personalFiatCurrencies.map((currency) => (
            <Currency
              key={currency.isoCode}
              currency={currency}
              originalAmount={currency.originalAmount}
              convertedAmount={currency.convertedAmount}
              exchangeRate={currency.exchangeRate}
              percentChange24h={currency.percentChange24h}
              targetCurrency={{
                symbol: currency.symbol,
                currencyType: currency.currencyType,
              }}
            />
          ))}
        </div>
      ),
    },
  ]

  return (
    <>
      <Header logoType={"Business"} />
      <div className={styles.container}>
        <div className={styles.firstContainer}>
          <ReactTyped
            strings={["Everything <br> What your business needs"]}
            typeSpeed={70}
            showCursor={true}
            className={styles.typedText}
          />
        </div>

        <div className={styles.secondContainer}>
          <div className={styles.secondText}>
            <p>One account</p>
            <p>
              For <span className={styles.secondLightText}>Business</span> &{" "}
              <span className={styles.secondLightText}>Personal</span>
            </p>
          </div>
          <div className={styles.secondContent}>
            <div className={styles.secondContentBusinessContainer}>
              <div className={styles.logoContainer}>
                <Logo type={"Business"} />
              </div>
              <div className={styles.secondContentBusiness}>
                <p className={styles.balanceBusiness}>
                  ${formatBalance(businessTotalBalance)}
                </p>
                <TabMenu tabs={tabsForBusiness} />
              </div>
            </div>

            <div className={styles.secondContentPersonalContainer}>
              <div className={styles.logoContainer}>
                <Logo type={"Personal"} />
              </div>
              <div className={styles.secondContentPersonal}>
                <p className={styles.balancePersonal}>
                  ${formatBalance(personalTotalBalance)}
                </p>
                <TabMenu tabs={tabsForPersonal} />
              </div>
            </div>
          </div>
        </div>

        <div className={styles.thirdContainer}>
          <div className={styles.thirdTextContainer}>
            <div className={styles.thirdText}>
              <div className={styles.logoContainer}>
                <Logo type={"Payment"} />
              </div>
              <p>Business invoice In one click</p>
            </div>
            <Button
              onClick={() => {
                navigate("/vortex-payment")
              }}
            >
              Learn more
            </Button>
          </div>
          <div className={styles.thirdContent}>
            <div className={styles.thirdFirstContent}>
              <div className={styles.invoiceContent}>
                <LabelButton
                  onClick={() => setIsModalOpen(true)}
                  icon={tempSelectedCurrency.logoUrl}
                  arrow={true}
                >
                  {tempSelectedCurrency.name}
                </LabelButton>
                <Input
                  label={"Amount"}
                  type={"number"}
                  placeholder={"Enter amount"}
                  value={tempAmount}
                  onChange={(e) => setTempAmount(e.target.value)}
                  error={errors.amount}
                />
                <Input
                  label={"Expire Time"}
                  type={"number"}
                  placeholder={"Enter expire time in minutes"}
                  value={tempExpireTime}
                  onChange={(e) => setTempExpireTime(e.target.value)}
                  error={errors.expireTime}
                />
                <Button onClick={handleCreateClick}>Create</Button>
              </div>
            </div>

            <div className={styles.thirdSecondContent}>
              {isCreated && (
                <div>
                  <Currency currency={selectedCurrency} variant="simple" />
                  <div className={styles.qrCodeContainer}>
                    <div className={styles.qrCode}>
                      <QRCodeCanvas
                        value={generateQRCodeValue()}
                        size={qrSize}
                      />
                    </div>
                    <p className={styles.qrAmount}>
                      {amount} {selectedCurrency.isoCode}
                    </p>
                    <div className={styles.address}>
                      <p>{address}</p>
                    </div>
                  </div>
                  <div className={styles.timer}>
                    <Timer expireTime={calculateExpireTime()} />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className={styles.fourthContainer}>
          <div className={styles.fourthTextContainer}>
            <div>
              <div className={styles.fourthLogoContainer}>
                <Logo type={"Taxes"} />
              </div>
              <p className={styles.fourthText}>Tax return</p>
            </div>
            <div className={styles.fourthSecondTextContainer}>
              <p className={styles.fourthSecondText}>
                Effortlessly manage and generate accurate tax reports for all
                your cryptocurrency transactions with Vortex Taxes, ensuring
                compliance
              </p>
              <Button
                onClick={() => {
                  navigate("/taxes")
                }}
              >
                Learn more
              </Button>
            </div>
          </div>
          <div className={styles.fourthContent}>
            {transactions.map((transaction) => (
              <TransactionContainer
                key={transaction.id}
                transaction={transaction}
              />
            ))}
          </div>
        </div>
      </div>

      {isModalOpen && (
        <Modal
          title="Select Cryptocurrency"
          onClose={() => setIsModalOpen(false)}
          width="80vh"
          height="70vh"
        >
          {cryptocurrencies.map((currency) => (
            <LabelButton
              key={currency.isoCode}
              onClick={() => handleCurrencySelect(currency)}
              icon={currency.logoUrl}
            >
              {currency.name}
            </LabelButton>
          ))}
        </Modal>
      )}
    </>
  )
}

export default VortexBusinessPage
