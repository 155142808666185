import { gql } from "@apollo/client"

export const UPDATE_OFFLINE_STORE = gql`
  mutation UpdateOfflineStore(
    $storeId: UUID!
    $name: String
    $address: String
    $expireTime: Int
  ) {
    updateOfflineStore(
      storeId: $storeId
      name: $name
      address: $address
      expireTime: $expireTime
    ) {
      ok
      offlineStore {
        id
        name
        address
        expireTime
      }
      message
    }
  }
`
