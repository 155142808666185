import { gql } from "@apollo/client"

export const ADDRESSES_BY_NETWORK = gql`
  query AddressesByNetwork($walletId: UUID!, $networkName: String!) {
    addressesByNetwork(walletId: $walletId, networkName: $networkName) {
      id
      address
      network {
        id
        fullName
        name
        symbol
        logoUrl
        isActive
      }
    }
  }
`
