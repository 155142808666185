import styles from "../styles/UI/Card.module.css"

const Card = ({ children, onClick, disabled, width, height, inactive }) => {
  const handleClick = (e) => {
    if (!disabled && onClick) {
      onClick(e)
    }
  }

  const cardStyles = {
    width: width || "300px",
    height: height || "300px",
  }

  return (
    <div
      className={`${styles.card} ${disabled ? styles.disabled : ""} ${
        inactive ? styles.inactive : ""
      }`}
      style={cardStyles}
      onClick={handleClick}
    >
      {children}
    </div>
  )
}

export default Card
