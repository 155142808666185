import { useQuery } from "@apollo/client"
import { CONVERT_ALL_CURRENCIES } from "../apollo/graphql/Query/convertAllCurrencies"
import Currency from "../assets/UI/Currency"
import TabMenu from "../assets/UI/TabMenu"
import styles from "../assets/styles/Pages/SelectSendPage.module.css"
import { useSelector } from "react-redux"

const SelectSendPage = () => {
  const walletId = useSelector((state) => state.wallet.wallet.id)

  const CryptoSendList = () => {
    const { loading, error, data } = useQuery(CONVERT_ALL_CURRENCIES, {
      variables: {
        walletId,
        currencyType: "CRYPTO",
      },
    })

    if (loading) return <p>Loading...</p>
    if (error) return <p>Error fetching cryptocurrencies: {error.message}</p>

    if (!data || data.convertAllCurrencies.length === 0) {
      return <p>No cryptocurrencies available</p>
    }

    return (
      <div className={styles.container}>
        {data.convertAllCurrencies.map((currencyData) => (
          <Currency
            key={currencyData.originalCurrency.id}
            originalAmount={currencyData.originalAmount}
            convertedAmount={currencyData.convertedAmount}
            currency={currencyData.originalCurrency}
            targetCurrency={currencyData.targetCurrency}
            action={"send"}
          />
        ))}
      </div>
    )
  }

  const FiatSendList = () => {
    const { loading, error, data } = useQuery(CONVERT_ALL_CURRENCIES, {
      variables: {
        walletId,
        currencyType: "FIAT",
      },
    })

    if (loading) return <p>Loading...</p>
    if (error) return <p>Error fetching fiats: {error.message}</p>

    if (!data || data.convertAllCurrencies.length === 0) {
      return <p>No fiat currencies available.</p>
    }

    return (
      <div className={styles.container}>
        {data.convertAllCurrencies.map((currencyData) => (
          <Currency
            key={currencyData.originalCurrency.id}
            originalAmount={currencyData.originalAmount}
            convertedAmount={currencyData.convertedAmount}
            currency={currencyData.originalCurrency}
            targetCurrency={currencyData.targetCurrency}
          />
        ))}
      </div>
    )
  }

  const tabs = [
    {
      key: "Crypto",
      label: "Crypto",
      content: <CryptoSendList />,
    },
    {
      key: "Fiat",
      label: "Fiat",
      content: <FiatSendList />,
    },
  ]

  return (
    <div className={styles.container}>
      <TabMenu tabs={tabs} />
    </div>
  )
}

export default SelectSendPage
