import { gql } from "@apollo/client"

export const SELECT_INVOICE_CURRENCY = gql`
  mutation SelectInvoiceCurrency(
    $invoiceId: UUID!
    $blockchainCurrencyId: ID!
  ) {
    selectInvoiceCurrency(
      invoiceId: $invoiceId
      blockchainCurrencyId: $blockchainCurrencyId
    ) {
      ok
      invoice {
        id
        wallet {
          id
          user {
            id
            email
            firstName
            lastName
          }
          business {
            id
            legalName
          }
          walletType
        }
        amount
        currency {
          id
          name
          symbol
          isoCode
          logoUrl
        }
        supportedCurrencies {
          id
          currency {
            id
            name
            symbol
            isoCode
            logoUrl
          }
          blockchain {
            id
            fullName
            name
            symbol
            logoUrl
          }
        }
        selectedCurrency {
          id
          currency {
            id
            name
            symbol
            isoCode
            logoUrl
          }
          blockchain {
            id
            fullName
            name
            symbol
            logoUrl
          }
        }
        address {
          id
          subAddress
        }
        expectedAmount
        receivedAmount
        expireTime
        expirationDate
        status
      }
      message
    }
  }
`
