import { useState, useEffect } from "react"
import { useLazyQuery, useMutation } from "@apollo/client"
import { useNavigate } from "react-router-dom"
import { USER_SUMMARY } from "../apollo/graphql/Query/userSummary"
import { ALL_CURRENCIES } from "../apollo/graphql/Query/allCurrencies"
import { TRANSFER_MUTATION } from "../apollo/graphql/Mutation/transfer"
import { GET_TRANSFER_CONTACTS } from "../apollo/graphql/Query/transferContacts"
import { TOGGLE_PINNED_CONTACT } from "../apollo/graphql/Mutation/togglePinnedContact"
import Input from "../assets/UI/Input"
import LabelButton from "../assets/UI/LabelButton"
import Modal from "../assets/UI/Modal"
import TabMenu from "../assets/UI/TabMenu"
import Button from "../assets/UI/Button"
import {
  isValidPositiveNumber,
  normalizeDecimalSeparator,
} from "../utils/inputValidation"
import ToggleButton from "../assets/UI/ToggleButton"
import { useSelector } from "react-redux"
import styles from "../assets/styles/Pages/TransferPage.module.css"
import formatBalance from "../utils/formatBalance"

const TransferPage = () => {
  const walletId = useSelector((state) => state.wallet.wallet.id)
  const senderWalletId = walletId
  const navigate = useNavigate()
  const [identifier, setIdentifier] = useState("")
  const [searchQuery, setSearchQuery] = useState("")
  const [getUserSummary, { loading, data, error }] = useLazyQuery(USER_SUMMARY)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false)
  const [selectedUser, setSelectedUser] = useState(null)
  const [selectedWallet, setSelectedWallet] = useState(null)
  const [selectedCurrency, setSelectedCurrency] = useState(null)
  const [amount, setAmount] = useState("")
  const [description, setDescription] = useState("")
  const [commission, setCommission] = useState(0)
  const [errorMessage, setErrorMessage] = useState("")

  const [transfer, { loading: transferLoading, error: transferError }] =
    useMutation(TRANSFER_MUTATION)

  const receiverWalletId = selectedWallet?.id
  const [
    getAllCurrencies,
    { loading: currenciesLoading, data: currenciesData },
  ] = useLazyQuery(ALL_CURRENCIES)

  const [
    getTransferContacts,
    { data: transferContactsData, refetch: refetchTransferContacts },
  ] = useLazyQuery(GET_TRANSFER_CONTACTS, {
    fetchPolicy: "network-only",
  })

  const [togglePinnedContact, { loading: toggleLoading }] = useMutation(
    TOGGLE_PINNED_CONTACT,
    {
      onCompleted: () => {
        refetchTransferContacts()
      },
      onError: (error) => {
        console.error("Error toggling pinned contact:", error)
      },
    }
  )

  useEffect(() => {
    if (searchQuery) {
      const delayDebounceFn = setTimeout(() => {
        getUserSummary({ variables: { identifier: searchQuery } })
      }, 2000)

      return () => clearTimeout(delayDebounceFn)
    }
  }, [searchQuery, getUserSummary])

  useEffect(() => {
    if (amount) {
      const calculatedCommission = (parseFloat(amount) * 0.001).toFixed(6)
      setCommission(calculatedCommission)
    } else {
      setCommission(0)
    }
  }, [amount])

  useEffect(() => {
    if (senderWalletId) {
      getTransferContacts({ variables: { walletId: senderWalletId } })
    }
  }, [senderWalletId, getTransferContacts])

  const handleInputChange = (e) => {
    setIdentifier(e.target.value)
    setSearchQuery(e.target.value)
  }

  const handleUserClick = (user) => {
    setSelectedUser(user)
    setIsModalOpen(true)
  }

  const handleModalClose = () => {
    setIsModalOpen(false)
    setSelectedUser(null)
  }

  const handleConfirmModalClose = () => {
    setIsConfirmModalOpen(false)
  }

  const handleWalletSelect = (wallet) => {
    if (wallet.id !== senderWalletId) {
      setSelectedWallet(wallet)
      setIsModalOpen(false)

      getAllCurrencies({
        variables: { walletId: senderWalletId, withPositiveBalance: true },
      })
    } else {
      setErrorMessage("You cannot select your own wallet.")
    }
  }

  const handleCurrencySelect = () => {
    setIsModalOpen(true)
  }

  const handleCurrencyClick = (currency) => {
    setSelectedCurrency(currency)
    setIsModalOpen(false)
  }

  const handleSendClick = () => {
    setIsConfirmModalOpen(true)
  }

  const handleConfirmTransaction = () => {
    if (!senderWalletId || !receiverWalletId) {
      setErrorMessage("Please select both sender and receiver wallets.")
      return
    }

    transfer({
      variables: {
        senderWalletId,
        receiverWalletId: selectedWallet.id,
        currency: selectedCurrency.currency.isoCode,
        amount: parseFloat(amount),
        description: description || "",
      },
    }).then((response) => {
      const { ok } = response.data.transfer
      if (ok) {
        setIsConfirmModalOpen(false)
        navigate("/wallet")
      } else {
        setErrorMessage("Transaction failed. Please try again.")
      }
    })
  }

  const handleMaxClick = () => {
    const balance = parseFloat(selectedCurrency?.balance || 0)
    const maxAmount = (balance / (1 + 0.001)).toFixed(6)
    setAmount(maxAmount)

    if (balance < parseFloat(maxAmount) + parseFloat(commission)) {
      setErrorMessage(
        "Amount exceeds available balance, considering commission."
      )
    } else {
      setErrorMessage("")
    }
  }

  const handleAmountChange = (e) => {
    const value = e.target.value

    if (isValidPositiveNumber(value)) {
      setAmount(normalizeDecimalSeparator(value))
    }
  }

  const cryptoCurrencies =
    currenciesData?.allCurrencies.filter(
      (currency) => currency.currency.currencyType === "CRYPTO"
    ) || []
  const fiatCurrencies =
    currenciesData?.allCurrencies.filter(
      (currency) => currency.currency.currencyType === "FIAT"
    ) || []

  const CryptoTab = () => (
    <div>
      {cryptoCurrencies.map((currency) => (
        <div
          className={styles.modalLabelButton}
          key={currency.currency.isoCode}
        >
          <LabelButton
            icon={currency.currency.logoUrl}
            onClick={() => handleCurrencyClick(currency)}
          >
            {currency.currency.name}
          </LabelButton>
        </div>
      ))}
    </div>
  )

  const FiatTab = () => (
    <div>
      {fiatCurrencies.map((currency) => (
        <div
          className={styles.modalLabelButton}
          key={currency.currency.isoCode}
        >
          <LabelButton
            icon={currency.currency.logoUrl}
            onClick={() => handleCurrencyClick(currency)}
          >
            {currency.currency.name}
          </LabelButton>
        </div>
      ))}
    </div>
  )

  const tabs = [
    { key: "crypto", label: "Crypto", content: <CryptoTab /> },
    { key: "fiat", label: "Fiat", content: <FiatTab /> },
  ]

  const renderContacts = (contactType) => {
    return transferContactsData?.transferContacts
      .filter((contact) => contact.contactType === contactType)
      .map((contact) => (
        <LabelButton
          key={contact.id}
          icon={contact.contactUser.avatarUrl}
          onClick={() => handleUserClick(contact.contactUser)}
        >
          {`${contact.contactUser.firstName} ${contact.contactUser.lastName}`}
        </LabelButton>
      ))
  }

  const renderAllContacts = () => {
    if (!transferContactsData?.transferContacts.length) {
      return (
        <p>
          No contacts available. Please search for a user to start a transfer.
        </p>
      )
    }

    const uniqueContacts = transferContactsData?.transferContacts.reduce(
      (acc, contact) => {
        if (!acc.some((c) => c.contactUser.id === contact.contactUser.id)) {
          acc.push(contact)
        }
        return acc
      },
      []
    )

    return uniqueContacts.map((contact) => (
      <LabelButton
        key={contact.id}
        icon={contact.contactUser.avatarUrl}
        onClick={() => handleUserClick(contact.contactUser)}
      >
        {`${contact.contactUser.firstName} ${contact.contactUser.lastName}`}
      </LabelButton>
    ))
  }

  const isUserPinned = () => {
    return (
      transferContactsData?.transferContacts.some(
        (contact) =>
          contact.contactUser.id === selectedUser.id &&
          contact.contactType === "PINNED"
      ) || false
    )
  }

  const handleTogglePinnedContact = () => {
    if (!selectedUser) return

    togglePinnedContact({
      variables: {
        walletId: senderWalletId,
        contactUserId: selectedUser.id,
      },
    })
  }

  return (
    <div>
      {selectedWallet ? (
        <div className={styles.transferContainer}>
          <p className={styles.pageName}>Transfer</p>
          <LabelButton
            label={"Select Currency"}
            onClick={handleCurrencySelect}
            icon={selectedCurrency ? selectedCurrency.currency.logoUrl : null}
            arrow={true}
          >
            {selectedCurrency
              ? selectedCurrency.currency.name
              : "Choose a currency"}
          </LabelButton>
          <Input
            label="Amount"
            type="text"
            placeholder="0"
            value={amount}
            onChange={handleAmountChange}
            rightButtonLabel={"MAX"}
            onRightButtonClick={handleMaxClick}
            rightText={selectedCurrency?.currency.isoCode || ""}
            secondaryLabel={
              selectedCurrency
                ? `Balance: ${
                    formatBalance(selectedCurrency.balance, true) || 0
                  }`
                : null
            }
            error={errorMessage}
          />
          <Input
            label="Description"
            placeholder="Description of the transfer"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
          {selectedCurrency && (
            <p>
              Commission: {formatBalance(commission, true)}{" "}
              {selectedCurrency?.currency.isoCode}
            </p>
          )}
          <div className={styles.transferBtn}>
            <Button
              onClick={handleSendClick}
              disabled={!selectedCurrency || !amount}
            >
              Send
            </Button>
          </div>
        </div>
      ) : (
        <div className={styles.contentContainer}>
          <div>
            <p className={styles.pageName}>Transfer</p>
            <Input
              placeholder={"Enter email, tagname or phone"}
              value={identifier}
              onChange={handleInputChange}
            />
            <div className={styles.content}>
              {loading && <p>Loading...</p>}
              {error && <p>Error fetching user: {error.message}</p>}
              {data && data.userSummary && (
                <div className={styles.labelButton}>
                  <LabelButton
                    icon={data.userSummary.avatarUrl}
                    arrow={true}
                    onClick={() => handleUserClick(data.userSummary)}
                  >
                    {`${data.userSummary.firstName} ${data.userSummary.lastName}`}
                  </LabelButton>
                </div>
              )}
            </div>
          </div>

          {transferContactsData?.transferContacts.some(
            (contact) => contact.contactType === "RECENT"
          ) && (
            <div className={styles.content}>
              <h3>Recent</h3>
              {renderContacts("RECENT")}
            </div>
          )}

          {transferContactsData?.transferContacts.some(
            (contact) => contact.contactType === "PINNED"
          ) && (
            <div className={styles.content}>
              <h3>Pinned</h3>
              {renderContacts("PINNED")}
            </div>
          )}

          <div className={styles.content}>
            <h3>All</h3>
            {renderAllContacts()}
          </div>
        </div>
      )}

      {isModalOpen && selectedUser && !selectedWallet && (
        <Modal title="Select Wallet" onClose={handleModalClose}>
          {selectedUser.wallets
            .filter((wallet) => wallet.walletType === "PERSONAL")
            .filter((wallet) => wallet.id !== senderWalletId)
            .map((wallet) => (
              <div key={wallet.id}>
                <LabelButton
                  icon={selectedUser.avatarUrl}
                  onClick={() => handleWalletSelect(wallet)}
                  label={"Personal wallet"}
                >
                  {`${selectedUser.firstName} ${selectedUser.lastName}`}
                </LabelButton>
              </div>
            ))}
          {selectedUser.wallets
            .filter((wallet) => wallet.walletType === "BUSINESS")
            .filter((wallet) => wallet.id !== senderWalletId)
            .map((wallet) => (
              <div key={wallet.id}>
                <LabelButton
                  icon={wallet.business.avatarUrl}
                  onClick={() => handleWalletSelect(wallet)}
                  label={"Business wallet"}
                >
                  {wallet.business.legalName}
                </LabelButton>
              </div>
            ))}
          <div className={styles.pinnedToggle}>
            <p>Pinned User</p>
            <ToggleButton
              isChecked={isUserPinned()}
              onToggle={handleTogglePinnedContact}
              disabled={toggleLoading}
            />
          </div>
        </Modal>
      )}

      {isModalOpen && selectedWallet && (
        <Modal title="Select Currency" onClose={handleModalClose}>
          <TabMenu tabs={tabs} />
        </Modal>
      )}

      {isConfirmModalOpen && (
        <Modal title="Confirm Transaction" onClose={handleConfirmModalClose}>
          <p>Amount: {amount}</p>
          <p>Currency: {selectedCurrency?.currency.name}</p>
          <p>Description: {description}</p>
          {selectedCurrency && (
            <p>
              Commission: {formatBalance(commission, true)}{" "}
              {selectedCurrency?.currency.isoCode}
            </p>
          )}
          <div className={styles.transferBtn}>
            <Button onClick={handleConfirmTransaction}>
              {transferLoading ? "Processing..." : "Confirm Send"}
            </Button>
          </div>
          {transferError && <p>Error: {transferError.message}</p>}
        </Modal>
      )}
    </div>
  )
}

export default TransferPage
