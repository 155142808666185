import { useState, useRef } from "react"
import { useMutation } from "@apollo/client"
import { UPLOAD_AVATAR } from "../../apollo/graphql/Mutation/uploadAvatar"
import { toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import styles from "../styles/UI/AvatarUpload.module.css"

const AvatarUpload = ({ wallet, avatarUrl }) => {
  const [uploadAvatar, { loading }] = useMutation(UPLOAD_AVATAR)
  const [previewUrl, setPreviewUrl] = useState(avatarUrl)
  const fileInputRef = useRef(null)

  const handleAvatarChange = (e) => {
    const file = e.target.files[0]
    if (file) {
      const reader = new FileReader()
      reader.onload = () => {
        setPreviewUrl(reader.result)
      }
      reader.readAsDataURL(file)

      uploadAvatar({
        variables: {
          walletId: wallet.id,
          avatarFile: file,
        },
      })
        .then((response) => {
          if (response.data.uploadAvatar.success) {
            const newAvatarUrl = response.data.uploadAvatar.avatarUrl
            setPreviewUrl(newAvatarUrl)
            toast.success("Avatar updated successfully")
          } else {
            toast.error(response.data.uploadAvatar.errors.join(", "))
          }
        })
        .catch((error) => {
          console.error("Error uploading avatar:", error)
          toast.error("Failed to upload avatar. Please try again.")
        })
    }
  }

  const handleAvatarClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  return (
    <div className={styles.avatarContainer}>
      <ToastContainer />
      <input
        ref={fileInputRef}
        type="file"
        accept="image/*"
        className={styles.fileInput}
        onChange={handleAvatarChange}
      />
      <img
        src={previewUrl || avatarUrl}
        alt="Avatar"
        className={styles.avatar}
        onClick={handleAvatarClick}
      />
    </div>
  )
}

export default AvatarUpload
