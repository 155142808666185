import { gql } from "@apollo/client"

export const CHANGE_EMAIL_MUTATION = gql`
  mutation ChangeEmail($newEmail: String!) {
    changeEmail(newEmail: $newEmail) {
      ok
      message
      token
      refreshToken
    }
  }
`
