import { useEffect, useState } from "react"
import { NavLink, useNavigate } from "react-router-dom"
import ClickableCard from "./ClickableCard"
import styles from "../styles/UI/Sidebar.module.css"

const Sidebar = ({ wallet }) => {
  const [walletType, setWalletType] = useState(null)
  const [userName, setUserName] = useState("")
  const [avatarUrl, setAvatarUrl] = useState("")
  const navigate = useNavigate()

  useEffect(() => {
    if (wallet) {
      setWalletType(wallet.walletType)

      if (wallet && wallet.walletType === "BUSINESS") {
        setUserName(wallet.business?.legalName || "Unknown Business")
        setAvatarUrl(wallet.business?.avatarUrl || "")
      } else if (
        wallet &&
        (wallet.walletType === "PERSONAL" || wallet.walletType === "USER")
      ) {
        setUserName(
          `${wallet.user?.firstName || ""} ${wallet.user?.lastName || ""}`
        )
        setAvatarUrl(wallet.user?.avatarUrl || "")
      }
    }
  }, [wallet])

  if (!walletType) return null

  if (
    walletType !== "BUSINESS" &&
    walletType !== "PERSONAL" &&
    walletType !== "USER"
  ) {
    throw new Error(
      `Invalid walletType: ${walletType}. Expected "BUSINESS" or "PERSONAL".`
    )
  }

  const businessLinks = [
    { name: "Wallet", path: "/wallet" },
    { name: "Transfer", path: "/transfer" },
    { name: "Invoice", path: "/invoice" },
    { name: "Integration", path: "/integration" },
  ]

  const personalLinks = [
    { name: "Wallet", path: "/wallet" },
    { name: "Transfer", path: "/transfer" },
    { name: "Invoice", path: "/invoice" },
    { name: "Payment", path: "/payment" },
  ]

  const links = walletType === "BUSINESS" ? businessLinks : personalLinks

  const handleUserClick = () => {
    navigate("/account")
  }

  return (
    <div className={styles.sidebar}>
      <div className={styles.userCard}>
        <ClickableCard
          logoUrl={avatarUrl}
          text={userName}
          onClick={handleUserClick}
        />
      </div>
      <nav className={styles.navLinks}>
        {links.map((link) => (
          <NavLink
            to={link.path}
            key={link.name}
            className={({ isActive }) =>
              isActive ? `${styles.navLink} ${styles.active}` : styles.navLink
            }
          >
            {link.name}
          </NavLink>
        ))}
      </nav>
    </div>
  )
}

export default Sidebar
