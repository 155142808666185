import { useSelector } from "react-redux"
import TabMenu from "../assets/UI/TabMenu"
import OfflineStoreList from "../components/OfflineStoreList"
import WebsiteList from "../components/WebsiteList"

function IntegrationPage() {
  const walletId = useSelector((state) => state.wallet.wallet.id)

  const tabs = [
    {
      key: "Offline Store",
      label: "Offline Store",
      content: <OfflineStoreList walletId={walletId} />,
    },
    {
      key: "Website",
      label: "Website",
      content: <WebsiteList walletId={walletId} />,
    },
  ]
  return (
    <>
      <TabMenu tabs={tabs} />
    </>
  )
}

export default IntegrationPage
