import { useState } from "react"
import Input from "../assets/UI/Input"
import Button from "../assets/UI/Button"
import styles from "../assets/styles/Components/PersonalInfo.module.css"

function PersonalInfo({ onNext }) {
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [dateOfBirth, setDateOfBirth] = useState("")
  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    dateOfBirth: "",
  })

  const isOver18 = (dob) => {
    const birthDate = new Date(dob)
    const today = new Date()
    const age = today.getFullYear() - birthDate.getFullYear()
    const monthDifference = today.getMonth() - birthDate.getMonth()

    return (
      age > 18 ||
      (age === 18 &&
        monthDifference >= 0 &&
        today.getDate() >= birthDate.getDate())
    )
  }

  const handleNext = () => {
    let valid = true
    const newErrors = { firstName: "", lastName: "", dateOfBirth: "" }

    if (!firstName) {
      newErrors.firstName = "First name is required."
      valid = false
    }

    if (!lastName) {
      newErrors.lastName = "Last name is required."
      valid = false
    }

    if (!dateOfBirth) {
      newErrors.dateOfBirth = "Date of birth is required."
      valid = false
    } else if (!isOver18(dateOfBirth)) {
      newErrors.dateOfBirth = "You must be at least 18 years old."
      valid = false
    }

    setErrors(newErrors)

    if (valid) {
      onNext({ firstName, lastName, dateOfBirth })
    }
  }

  return (
    <div className={styles.container}>
      <Input
        id="firstName"
        label="First Name"
        type="text"
        placeholder="Enter your first name"
        value={firstName}
        onChange={(e) => setFirstName(e.target.value)}
        onFocus={() => setErrors({ ...errors, firstName: "" })}
        error={errors.firstName}
      />
      <Input
        id="lastName"
        label="Last Name"
        type="text"
        placeholder="Enter your last name"
        value={lastName}
        onChange={(e) => setLastName(e.target.value)}
        onFocus={() => setErrors({ ...errors, lastName: "" })}
        error={errors.lastName}
      />
      <Input
        id="dateOfBirth"
        label="Date of Birth"
        type="date"
        placeholder="Enter your date of birth"
        value={dateOfBirth}
        onChange={(e) => setDateOfBirth(e.target.value)}
        onFocus={() => setErrors({ ...errors, dateOfBirth: "" })}
        error={errors.dateOfBirth}
      />
      <Button onClick={handleNext}>Next</Button>
    </div>
  )
}

export default PersonalInfo
