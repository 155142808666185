import { useQuery } from "@apollo/client"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { USER_WALLETS } from "../apollo/graphql/Query/userWallets"
import WalletList from "../components/WalletList"
import Sidebar from "../assets/UI/Sidebar"
import { Outlet } from "react-router-dom"
import { setWallet } from "../redux/walletSlice"
import styles from "./Layout.module.css"

const Layout = () => {
  const { data } = useQuery(USER_WALLETS)
  const dispatch = useDispatch()
  const wallet = useSelector((state) => state.wallet.wallet)

  useEffect(() => {
    if (data) {
      const updatedWallet = data.userWallets.find((w) => w.id === wallet?.id)
      if (updatedWallet) {
        dispatch(setWallet(updatedWallet))
      }
    }
  }, [data, dispatch])

  if (!wallet) {
    return <WalletList />
  }

  return (
    <div className={styles.layout}>
      <Sidebar wallet={wallet} />
      <div className={styles.content}>
        <Outlet />
      </div>
    </div>
  )
}

export default Layout
