import { useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { setWallet } from "../../redux/walletSlice"
import { useMutation } from "@apollo/client"
import { CREATE_OR_UPDATE_USERNAME_TAG } from "../../apollo/graphql/Mutation/createOrUpdateUsernameTag"
import Button from "./Button"
import Modal from "./Modal"
import Input from "./Input"
import { toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import styles from "../styles/UI/UsernameTag.module.css"

const UsernameTag = () => {
  const dispatch = useDispatch()
  const wallet = useSelector((state) => state.wallet.wallet)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [usernameTagInput, setUsernameTagInput] = useState("")
  const [error, setError] = useState("")

  const [createOrUpdateUsernameTag, { loading }] = useMutation(
    CREATE_OR_UPDATE_USERNAME_TAG,
    {
      onCompleted: (data) => {
        if (data.createOrUpdateUsernameTag.success) {
          const updatedTag = data.createOrUpdateUsernameTag.usernameTag
          const updatedWallet = {
            ...wallet,
            user: { ...wallet.user, usernameTag: updatedTag },
          }
          dispatch(setWallet(updatedWallet))
          setIsModalOpen(false)
          setUsernameTagInput("")
          setError("")
          toast.success("Username tag saved successfully.")
        } else {
          setError(data.createOrUpdateUsernameTag.errors.join(", "))
        }
      },
      onError: (error) => {
        setError(error.message)
      },
    }
  )

  const handleAddTagClick = () => {
    setIsModalOpen(true)
  }

  const handleTagSubmit = () => {
    if (usernameTagInput.trim() === "") {
      setError("Tag cannot be empty")
      return
    }

    createOrUpdateUsernameTag({
      variables: {
        usernameTag: usernameTagInput.trim(),
      },
    })
  }

  const handleTagClick = () => {
    if (wallet?.user?.usernameTag) {
      const tagWithPrefix = `@${wallet.user.usernameTag}`
      navigator.clipboard.writeText(tagWithPrefix).then(
        () => {
          toast.success("Tag copied to clipboard")
        },
        () => {
          toast.error("Failed to copy tag")
        }
      )
    }
  }

  return (
    <div className={styles.container}>
      <ToastContainer />
      {wallet?.user?.usernameTag ? (
        <Button onClick={handleTagClick} withBackground={false}>
          @{wallet.user.usernameTag}
        </Button>
      ) : (
        <Button onClick={handleAddTagClick}>Add Tag</Button>
      )}

      {isModalOpen && (
        <Modal title="Add Username Tag" onClose={() => setIsModalOpen(false)}>
          <div className={styles.modalContent}>
            <Input
              label="Username Tag"
              value={usernameTagInput}
              onChange={(e) => setUsernameTagInput(e.target.value)}
              error={error}
            />
            <div className={styles.modalButtons}>
              <Button onClick={handleTagSubmit} disabled={loading}>
                {loading ? "Saving..." : "Save"}
              </Button>
            </div>
          </div>
        </Modal>
      )}
    </div>
  )
}

export default UsernameTag
