import { gql } from "@apollo/client"

export const UPLOAD_AVATAR = gql`
  mutation UploadAvatar($walletId: UUID!, $avatarFile: Upload!) {
    uploadAvatar(walletId: $walletId, avatarFile: $avatarFile) {
      success
      avatarUrl
      errors
    }
  }
`
