import { useState } from "react"
import { useLazyQuery, useMutation, useQuery } from "@apollo/client"
import { FIND_INVOICE_OR_STATIC_INVOICE } from "../apollo/graphql/Query/findInvoiceOrStaticInvoiceByAddress"
import { PAY_INVOICE } from "../apollo/graphql/Mutation/payInvoice"
import { PAY_STATIC_INVOICE } from "../apollo/graphql/Mutation/payStaticInvoice"
import { ALL_CURRENCIES } from "../apollo/graphql/Query/allCurrencies"
import Input from "../assets/UI/Input"
import Button from "../assets/UI/Button"
import Currency from "../assets/UI/Currency"
import Modal from "../assets/UI/Modal"
import LabelButton from "../assets/UI/LabelButton"
import { toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { useSelector } from "react-redux"
import formatBalance from "../utils/formatBalance"
import styles from "../assets/styles/Pages/PaymentPage.module.css"

const PaymentPage = () => {
  const walletId = useSelector((state) => state.wallet.wallet.id)
  const [address, setAddress] = useState("")
  const [invoiceData, setInvoiceData] = useState(null)
  const [errorMessage, setErrorMessage] = useState("")
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [selectedCurrency, setSelectedCurrency] = useState(null)
  const [amount, setAmount] = useState("")

  const { loading: currenciesLoading, data: currenciesData } = useQuery(
    ALL_CURRENCIES,
    {
      variables: { walletId: walletId },
      skip: !invoiceData || invoiceData.__typename !== "StaticInvoiceType",
    }
  )

  const availableCurrencies = currenciesData?.allCurrencies || []

  const [fetchInvoice, { loading }] = useLazyQuery(
    FIND_INVOICE_OR_STATIC_INVOICE,
    {
      onCompleted: (data) => {
        if (data.findInvoiceOrStaticInvoiceByAddress) {
          setInvoiceData(data.findInvoiceOrStaticInvoiceByAddress)
          setErrorMessage("")
        } else {
          toast.error("Invoice not found.")
        }
      },
      onError: (error) => {
        toast.error(error.message)
        setInvoiceData(null)
      },
      fetchPolicy: "network-only",
    }
  )

  const [payInvoice, { loading: payInvoiceLoading }] = useMutation(
    PAY_INVOICE,
    {
      onCompleted: (data) => {
        if (data.payInvoice.ok) {
          toast.success("Invoice paid successfully.")
          setInvoiceData(null)
          setAddress("")
        } else {
          toast.error(`Error: ${data.payInvoice.message}`)
        }
      },
      onError: (error) => {
        toast.error(`Error: ${error.message}`)
      },
    }
  )

  const [payStaticInvoice, { loading: payStaticInvoiceLoading }] = useMutation(
    PAY_STATIC_INVOICE,
    {
      onCompleted: (data) => {
        if (data.payStaticInvoice.ok) {
          toast.success("Static Invoice paid successfully.")
          setInvoiceData(null)
          setAddress("")
          setIsModalOpen(false)
        } else {
          toast.error(`Error: ${data.payStaticInvoice.message}`)
        }
      },
      onError: (error) => {
        toast.error(`Error: ${error.message}`)
      },
    }
  )

  const handleSearch = () => {
    if (address.trim() === "") {
      toast.error("Please enter an address.")
      return
    }
    fetchInvoice({ variables: { address } })
  }

  const handlePayInvoice = () => {
    if (!walletId) {
      toast.error("Wallet not found. Please log in.")
      return
    }

    payInvoice({
      variables: {
        walletId: walletId,
        invoiceId: invoiceData.id,
      },
    })
  }

  const handleOpenModal = () => {
    if (!walletId) {
      toast.error("Wallet not found. Please log in.")
      return
    }
    setIsModalOpen(true)
  }

  const handlePayStaticInvoice = () => {
    if (!walletId) {
      toast.error("Wallet not found. Please log in.")
      return
    }

    if (!selectedCurrency || !amount) {
      toast.error("Please select a currency and enter an amount.")
      return
    }

    payStaticInvoice({
      variables: {
        walletId: walletId,
        staticInvoiceId: invoiceData.id,
        currency: selectedCurrency.isoCode,
        amount: parseFloat(amount),
      },
    })
  }

  const handleCurrencySelect = (currency) => {
    setSelectedCurrency(currency)
    setIsModalOpen(false)
  }

  const getAvailableBalance = () => {
    if (selectedCurrency && currenciesData && currenciesData.allCurrencies) {
      const currencyData = currenciesData.allCurrencies.find(
        (currency) => currency.currency.isoCode === selectedCurrency.isoCode
      )
      return currencyData ? parseFloat(currencyData.balance) : 0
    }
    return 0
  }

  const handleMaxButtonClick = () => {
    const availableBalance = getAvailableBalance()
    setAmount(availableBalance.toString())
  }

  return (
    <div className={styles.container}>
      {!invoiceData && (
        <div>
          <p className={styles.pageName}>Search Invoice</p>
          <Input
            placeholder="Enter address"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            error={errorMessage}
            onFocus={() => setErrorMessage(null)}
          />
          <Button onClick={handleSearch} disabled={loading}>
            {loading ? "Searching..." : "Search"}
          </Button>
        </div>
      )}

      {invoiceData && invoiceData.__typename === "InvoiceType" && (
        <div className={styles.container}>
          {invoiceData.selectedCurrency?.currency && (
            <Currency
              currency={invoiceData.selectedCurrency.currency}
              variant="simple"
            />
          )}
          <p className={styles.balance}>
            {formatBalance(invoiceData.expectedAmount, true)}{" "}
            {invoiceData.selectedCurrency.currency.isoCode}
          </p>
          <p className={styles.networkName}>
            {invoiceData.selectedCurrency.blockchain.fullName}
          </p>
          <div className={styles.invoiceBtn}>
            <Button onClick={handlePayInvoice} disabled={payInvoiceLoading}>
              {payInvoiceLoading ? "Paying..." : "Pay Invoice"}
            </Button>
          </div>
        </div>
      )}

      {invoiceData && invoiceData.__typename === "StaticInvoiceType" && (
        <div className={styles.container}>
          <p className={styles.pageName}>{invoiceData.name}</p>
          <p className={styles.networkName}>{invoiceData.network.fullName}</p>
          <LabelButton
            onClick={handleOpenModal}
            icon={selectedCurrency && selectedCurrency.logoUrl}
            arrow={true}
          >
            {selectedCurrency ? (
              <p>{selectedCurrency.name}</p>
            ) : (
              <p>Select Currency</p>
            )}
          </LabelButton>
          <Input
            label="Amount"
            type="text"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            placeholder="Enter amount"
            secondaryLabel={`Available: ${getAvailableBalance()} ${
              selectedCurrency?.isoCode || ""
            }`}
            rightButtonLabel="MAX"
            onRightButtonClick={handleMaxButtonClick}
            error={errorMessage}
          />
          <div className={styles.invoiceBtn}>
            <Button
              onClick={handlePayStaticInvoice}
              disabled={payStaticInvoiceLoading}
            >
              {payStaticInvoiceLoading ? "Paying..." : "Pay Static Invoice"}
            </Button>
          </div>
        </div>
      )}

      {isModalOpen && (
        <Modal title="Select Currency" onClose={() => setIsModalOpen(false)}>
          <div className={styles.currencyList}>
            {availableCurrencies
              .filter((currency) =>
                currency.blockchains.some(
                  (blockchain) =>
                    blockchain.blockchain.name === invoiceData.network.name
                )
              )
              .map((currency) => (
                <LabelButton
                  key={currency.currency.isoCode}
                  onClick={() => handleCurrencySelect(currency.currency)}
                  icon={currency.currency.logoUrl}
                >
                  {currency.currency.name}
                </LabelButton>
              ))}
          </div>
        </Modal>
      )}
      <ToastContainer />
    </div>
  )
}

export default PaymentPage
