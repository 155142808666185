import { gql } from "@apollo/client"

export const SWAP_MUTATION = gql`
  mutation Swap(
    $walletId: UUID!
    $fromCurrency: String!
    $toCurrency: String!
    $amount: Decimal!
    $swapQuoteId: UUID
  ) {
    swap(
      walletId: $walletId
      fromCurrency: $fromCurrency
      toCurrency: $toCurrency
      amount: $amount
      swapQuoteId: $swapQuoteId
    ) {
      ok
      message
      swapQuote {
        id
        fromAmount
        toAmount
        exchangeRate
        fee
      }
      swapTransaction {
        id
        fromAmount
        toAmount
        status
      }
    }
  }
`
