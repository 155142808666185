import { useState } from "react"
import { useQuery, useMutation } from "@apollo/client"
import { ALL_CURRENCIES } from "../apollo/graphql/Query/allCurrencies"
import { CREATE_INVOICE } from "../apollo/graphql/Mutation/createInvoice"
import Input from "../assets/UI/Input"
import LabelButton from "../assets/UI/LabelButton"
import Button from "../assets/UI/Button"
import ToggleButton from "../assets/UI/ToggleButton"
import Modal from "../assets/UI/Modal"
import TabMenu from "../assets/UI/TabMenu"
import styles from "../assets/styles/Components/Invoice.module.css"
import {
  isValidPositiveNumber,
  normalizeDecimalSeparator,
} from "../utils/inputValidation"
import AddressDisplay from "../assets/UI/AddressDisplay"

const Invoice = ({ walletId }) => {
  const [toggleState, setToggleState] = useState(false)
  const [amount, setAmount] = useState("")
  const [expireTime, setExpireTime] = useState("")
  const [selectedCurrency, setSelectedCurrency] = useState(null)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [invoiceData, setInvoiceData] = useState(null)
  const [errors, setErrors] = useState({})

  const { loading, data, error } = useQuery(ALL_CURRENCIES, {
    variables: { walletId, withPositiveBalance: false },
  })

  const [createInvoice, { loading: createLoading, error: createError }] =
    useMutation(CREATE_INVOICE)

  const handleToggle = () => {
    setToggleState(!toggleState)
  }

  const handleCurrencySelect = (currency) => {
    setSelectedCurrency(currency)
    setIsModalOpen(false)
    setErrors((prevErrors) => ({ ...prevErrors, currency: "" }))
  }

  const handleExpireTimeBlur = () => {
    let time = parseInt(expireTime)
    if (isNaN(time) || time < 5) {
      setExpireTime("5")
    } else if (time > 60) {
      setExpireTime("60")
    } else {
      setExpireTime(time.toString())
    }
  }

  const handleExpireTimeChange = (e) => {
    const value = e.target.value
    if (isValidPositiveNumber(value)) {
      setExpireTime(normalizeDecimalSeparator(value))
      setErrors((prevErrors) => ({ ...prevErrors, expireTime: "" }))
    }
  }

  const handleAmountChange = (e) => {
    const value = e.target.value
    if (isValidPositiveNumber(value)) {
      setAmount(normalizeDecimalSeparator(value))
      setErrors((prevErrors) => ({ ...prevErrors, amount: "" }))
    }
  }

  const handleCreateInvoice = () => {
    const newErrors = {}
    if (!selectedCurrency) {
      newErrors.currency = "Please select a currency."
    }
    if (!amount) {
      newErrors.amount = "Please enter an amount."
    } else if (isNaN(amount) || parseFloat(amount) <= 0) {
      newErrors.amount = "Amount must be a positive number."
    }
    if (!expireTime) {
      newErrors.expireTime = "Please enter expire time."
    } else if (
      isNaN(expireTime) ||
      parseInt(expireTime) < 5 ||
      parseInt(expireTime) > 60
    ) {
      newErrors.expireTime = "Expire time must be between 5 and 60 minutes."
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors)
      return
    } else {
      setErrors({})
    }

    createInvoice({
      variables: {
        walletId,
        amount: parseFloat(amount),
        currency: selectedCurrency.currency.symbol,
        expireTime: parseInt(expireTime),
      },
    })
      .then((response) => {
        const { ok, invoice } = response.data.createInvoice
        if (ok) {
          setInvoiceData(invoice)
          setAmount("")
          setExpireTime("")
          setSelectedCurrency(null)
          setErrors({})

          if (!toggleState) {
            window.open(
              `https://vortexonline.xyz/invoice/${invoice.id}`,
              "_blank"
            )
            setInvoiceData(null)
          }
        } else {
          setErrors({ general: "Error creating invoice." })
        }
      })
      .catch(() => {
        setErrors({ general: "An error occurred while creating the invoice." })
      })
  }

  const cryptoCurrencies =
    data?.allCurrencies.filter(
      (currency) => currency.currency.currencyType === "CRYPTO"
    ) || []
  const fiatCurrencies =
    data?.allCurrencies.filter(
      (currency) => currency.currency.currencyType === "FIAT"
    ) || []

  const CryptoTab = () => (
    <div>
      {cryptoCurrencies.map((currency) => (
        <div
          key={currency.currency.isoCode}
          className={styles.modalLabelButton}
        >
          <LabelButton
            icon={currency.currency.logoUrl}
            onClick={() => handleCurrencySelect(currency)}
          >
            {currency.currency.name}
          </LabelButton>
        </div>
      ))}
    </div>
  )

  const FiatTab = () => (
    <div>
      {fiatCurrencies.map((currency) => (
        <div
          key={currency.currency.isoCode}
          className={styles.modalLabelButton}
        >
          <LabelButton
            icon={currency.currency.logoUrl}
            onClick={() => handleCurrencySelect(currency)}
          >
            {currency.currency.name}
          </LabelButton>
        </div>
      ))}
    </div>
  )

  const tabs = [
    { key: "crypto", label: "Crypto", content: <CryptoTab /> },
    { key: "fiat", label: "Fiat", content: <FiatTab /> },
  ]

  return (
    <div className={styles.container}>
      {invoiceData && toggleState ? (
        <div className={styles.content}>
          <AddressDisplay
            address={`https://vortexonline.xyz/invoice/${invoiceData.id}`}
          />
          <Button onClick={() => setInvoiceData(null)}>
            Create New Invoice
          </Button>
        </div>
      ) : (
        <>
          <LabelButton
            label={"Select Currency"}
            onClick={() => setIsModalOpen(true)}
            arrow={true}
            error={errors.currency}
            icon={selectedCurrency ? selectedCurrency.currency.logoUrl : null}
          >
            {selectedCurrency
              ? `${selectedCurrency.currency.name}`
              : "Choose a currency"}
          </LabelButton>

          <Input
            label="Amount"
            value={amount}
            onChange={handleAmountChange}
            placeholder="Enter amount"
            error={errors.amount}
            onFocus={() =>
              setErrors((prevErrors) => ({ ...prevErrors, amount: "" }))
            }
          />

          <Input
            label="Expire Time (minutes)"
            value={expireTime}
            onChange={handleExpireTimeChange}
            onBlur={handleExpireTimeBlur}
            placeholder="Enter expire time"
            error={errors.expireTime}
            onFocus={() =>
              setErrors((prevErrors) => ({ ...prevErrors, expireTime: "" }))
            }
          />

          <div className={styles.toggleBtn}>
            <span>Only link</span>
            <ToggleButton isChecked={toggleState} onToggle={handleToggle} />
          </div>

          <div className={styles.invoiceBtn}>
            <Button onClick={handleCreateInvoice} disabled={createLoading}>
              {createLoading ? "Creating..." : "Create"}
            </Button>
          </div>

          {isModalOpen && (
            <Modal
              title="Select Currency"
              onClose={() => setIsModalOpen(false)}
            >
              <TabMenu tabs={tabs} />
            </Modal>
          )}
        </>
      )}
    </div>
  )
}

export default Invoice
