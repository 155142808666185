import { useState } from "react"
import { useQuery, useMutation } from "@apollo/client"
import { useNavigate } from "react-router-dom"
import { toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import Button from "../assets/UI/Button"
import Card from "../assets/UI/Card"
import Input from "../assets/UI/Input"
import Modal from "../assets/UI/Modal"
import { ALL_WEBSITES } from "../apollo/graphql/Query/allWebsites"
import { CREATE_WEBSITE } from "../apollo/graphql/Mutation/createWebsite"
import styles from "../assets/styles/Components/WebsiteList.module.css"

const WebsiteList = ({ walletId }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [newWebsiteName, setNewWebsiteName] = useState("")
  const [newWebsiteUrl, setNewWebsiteUrl] = useState("")
  const [errors, setErrors] = useState({})

  const navigate = useNavigate()

  const { loading, data, error, refetch } = useQuery(ALL_WEBSITES, {
    variables: { walletId },
    fetchPolicy: "network-only",
  })

  const [createWebsite, { loading: createLoading }] =
    useMutation(CREATE_WEBSITE)

  const toastOptions = {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
    style: {
      backgroundColor: "#070707",
      color: "#ffffff",
    },
  }

  const handleCreateClick = () => {
    setIsModalOpen(true)
    setErrors({})
  }

  const handleCreateWebsite = async () => {
    const newErrors = {}
    if (!newWebsiteName) newErrors.name = "Please enter a website name."
    if (!newWebsiteUrl) newErrors.url = "Please enter a URL."

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors)
      return
    }

    try {
      const { data } = await createWebsite({
        variables: {
          walletId,
          name: newWebsiteName,
          url: newWebsiteUrl,
        },
      })

      if (data.createWebsite.ok) {
        toast.success("Website created successfully.", toastOptions)
        setIsModalOpen(false)
        setNewWebsiteName("")
        setNewWebsiteUrl("")
        setErrors({})
        refetch()
      } else {
        toast.error(`Error: ${data.createWebsite.message}`, toastOptions)
      }
    } catch (err) {
      console.error("An error occurred:", err)
      toast.error("An unexpected error occurred.", toastOptions)
    }
  }

  const handleCardClick = (websiteId) => {
    navigate(`/integration/website/${websiteId}`)
  }

  if (loading) return <p>Loading websites...</p>
  if (error) return <p>Error loading websites: {error.message}</p>

  return (
    <>
      <ToastContainer />
      <div className={styles.cardContainer}>
        {data.allWebsites.length > 0 ? (
          data.allWebsites.map((website) => (
            <Card key={website.id} onClick={() => handleCardClick(website.id)}>
              <h3>{website.name}</h3>
              <p>{website.url}</p>
              <p>Status: {website.isVerified ? "Verified" : "Not Verified"}</p>
            </Card>
          ))
        ) : (
          <p>No websites found.</p>
        )}
      </div>
      <div className={styles.websiteBtn}>
        <Button onClick={handleCreateClick}>Create</Button>
      </div>

      {isModalOpen && (
        <Modal
          title="Create Website"
          onClose={() => setIsModalOpen(false)}
          height="70vh"
        >
          <Input
            label="Website Name"
            value={newWebsiteName}
            onChange={(e) => setNewWebsiteName(e.target.value)}
            placeholder="Enter website name"
            error={errors.name}
          />
          <Input
            label="URL"
            value={newWebsiteUrl}
            onChange={(e) => setNewWebsiteUrl(e.target.value)}
            placeholder="Enter website URL"
            error={errors.url}
          />
          <div className={styles.websiteBtn}>
            <Button onClick={handleCreateWebsite} disabled={createLoading}>
              {createLoading ? "Creating..." : "Create Website"}
            </Button>
          </div>
        </Modal>
      )}
    </>
  )
}

export default WebsiteList
