import { ApolloClient, InMemoryCache } from "@apollo/client"
import { setContext } from "@apollo/client/link/context"
import { onError } from "@apollo/client/link/error"
import { ApolloLink, Observable } from "@apollo/client"
import createUploadLink from "apollo-upload-client/createUploadLink.mjs"
import { REFRESH_TOKEN_MUTATION } from "./graphql/Mutation/refreshToken"

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem("token")

  return {
    headers: {
      ...headers,
      Authorization: token ? `JWT ${token}` : "",
    },
  }
})

const errorLink = onError(({ graphQLErrors, operation, forward }) => {
  if (graphQLErrors) {
    for (let err of graphQLErrors) {
      if (err.message.includes("Signature has expired")) {
        const refreshToken = localStorage.getItem("refreshToken")

        if (refreshToken) {
          localStorage.removeItem("token")
          return new Observable((observer) => {
            client
              .mutate({
                mutation: REFRESH_TOKEN_MUTATION,
                variables: { refreshToken },
              })
              .then(({ data }) => {
                const { token, refreshToken: newRefreshToken } =
                  data.refreshToken

                localStorage.setItem("token", token)
                localStorage.setItem("refreshToken", newRefreshToken)

                const oldHeaders = operation.getContext().headers
                operation.setContext({
                  headers: {
                    ...oldHeaders,
                    Authorization: `JWT ${token}`,
                  },
                })

                forward(operation).subscribe({
                  next: observer.next.bind(observer),
                  error: observer.error.bind(observer),
                  complete: observer.complete.bind(observer),
                })
              })
              .catch((error) => {
                console.error("Failed to refresh token", error)
                localStorage.removeItem("token")
                localStorage.removeItem("refreshToken")
                window.location.href = "/login"
                observer.error(error)
              })
          })
        } else {
          window.location.href = "/login"
        }
      } else if (err.message.includes("Refresh token is expired")) {
        localStorage.removeItem("token")
        localStorage.removeItem("refreshToken")
        window.location.href = "/login"
      }
    }
  }
})

const uploadLink = createUploadLink({
  // uri: "http://localhost:8000/graphql/",
  uri: "https://vortexonline.xyz/api/graphql/",
  credentials: "include",
})

const client = new ApolloClient({
  link: ApolloLink.from([authLink, errorLink, uploadLink]),
  cache: new InMemoryCache(),
})

export default client
