import { useState } from "react"
import { useMutation } from "@apollo/client"
import Card from "../assets/UI/Card"
import Button from "../assets/UI/Button"
import Modal from "../assets/UI/Modal"
import QRCodeDisplay from "../assets/UI/QRCodeDisplay"
import { CREATE_DEVICE } from "../apollo/graphql/Mutation/createDevice"
import { DELETE_DEVICE } from "../apollo/graphql/Mutation/deleteDevice"
import { useSelector } from "react-redux"
import styles from "../assets/styles/Components/OfflineStoreDevices.module.css"

const OfflineStoreDevices = ({
  offlineStoreId,
  devices,
  refetchOfflineStore,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [newDeviceApiKey, setNewDeviceApiKey] = useState(null)
  const [selectedDevice, setSelectedDevice] = useState(null)
  const [createLoading, setCreateLoading] = useState(false)
  const [isConfirmDelete, setIsConfirmDelete] = useState(false)
  const [createDevice] = useMutation(CREATE_DEVICE)
  const [deleteDevice, { loading: deleteLoading }] = useMutation(DELETE_DEVICE)

  const userId = useSelector((state) => state.wallet.wallet.user.id)

  const handleCreateDevice = async () => {
    setCreateLoading(true)
    try {
      const { data } = await createDevice({
        variables: { offlineStoreId },
      })

      if (data.createDevice.ok) {
        setNewDeviceApiKey(data.createDevice.device.apiKey)
        setIsModalOpen(true)
        refetchOfflineStore()
      } else {
        alert(`Error: ${data.createDevice.message}`)
      }
    } catch (error) {
      console.error("Error creating device:", error)
      alert("An unexpected error occurred.")
    } finally {
      setCreateLoading(false)
    }
  }

  const handleDeviceClick = (device) => {
    setSelectedDevice(device)
    setIsModalOpen(true)
  }

  const handleCloseModal = () => {
    setIsModalOpen(false)
    setSelectedDevice(null)
    setNewDeviceApiKey(null)
    setIsConfirmDelete(false)
  }

  const handleDeleteDevice = async () => {
    if (isConfirmDelete) {
      try {
        const { data } = await deleteDevice({
          variables: {
            deviceId: selectedDevice.id,
            userId: userId,
          },
        })

        if (data.deleteDevice.ok) {
          setIsModalOpen(false)
          refetchOfflineStore()
        } else {
          alert(`Error: ${data.deleteDevice.message}`)
        }
      } catch (error) {
        console.error("Error deleting device:", error)
        alert("An unexpected error occurred while deleting the device.")
      }
    } else {
      setIsConfirmDelete(true)
    }
  }

  return (
    <>
      <div className={styles.cardContainer}>
        {devices.length > 0 ? (
          devices.map((device) => (
            <Card key={device.id} onClick={() => handleDeviceClick(device)}>
              <div className={styles.card}>
                {device.owner ? (
                  <>
                    <p className={styles.cardName}>
                      {device.owner.firstName} {device.owner.lastName}
                    </p>
                    <p className={styles.cardText}>
                      Email: {device.owner.email}
                    </p>
                  </>
                ) : (
                  <p className={styles.cardName}>Activate</p>
                )}
                <p className={styles.cardText}>
                  Created At: {new Date(device.createdAt).toLocaleString()}
                </p>
              </div>
            </Card>
          ))
        ) : (
          <p>No devices found.</p>
        )}
      </div>
      <div className={styles.offlineStoreBtn}>
        <Button onClick={handleCreateDevice} disabled={createLoading}>
          {createLoading ? "Creating..." : "Create Device"}
        </Button>
      </div>

      {isModalOpen && newDeviceApiKey && !selectedDevice && (
        <Modal title="Device API Key" onClose={handleCloseModal} height="70vh">
          <div className={styles.modalContent}>
            <QRCodeDisplay value={newDeviceApiKey} size={180} />
            <p className={styles.modalText}>Scan QR Code in the Vortex app</p>
          </div>
        </Modal>
      )}

      {isModalOpen && selectedDevice && (
        <Modal title="Device Info" onClose={handleCloseModal} height="70vh">
          {selectedDevice.owner ? (
            <div className={styles.modalContent}>
              <p>
                <strong>Owner:</strong> {selectedDevice.owner.firstName}{" "}
                {selectedDevice.owner.lastName}
              </p>
              <p className={styles.modalText}>
                <strong>Email:</strong> {selectedDevice.owner.email}
              </p>
            </div>
          ) : (
            <div className={styles.modalContent}>
              <QRCodeDisplay value={selectedDevice.apiKey} size={180} />
              <p className={styles.modalText}>Scan QR Code in the Vortex app</p>
            </div>
          )}
          <div className={styles.offlineStoreBtn}>
            <Button
              onClick={handleDeleteDevice}
              danger
              disabled={deleteLoading}
            >
              {isConfirmDelete
                ? deleteLoading
                  ? "Deleting..."
                  : "Are you sure?"
                : "Delete Device"}
            </Button>
          </div>
        </Modal>
      )}
    </>
  )
}

export default OfflineStoreDevices
