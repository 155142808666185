import { gql } from "@apollo/client"

export const CREATE_OFFLINE_STORE = gql`
  mutation CreateOfflineStore(
    $walletId: UUID!
    $name: String!
    $address: String!
  ) {
    createOfflineStore(walletId: $walletId, name: $name, address: $address) {
      ok
      offlineStore {
        id
        name
        address
        isActive
      }
      message
    }
  }
`
