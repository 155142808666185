import { gql } from "@apollo/client"

export const ALL_NATIONALITIES = gql`
  query ALL_NATIONALITIES {
    allNationalities {
      id
      name
      country {
        id
        name
      }
    }
  }
`
