import { gql } from "@apollo/client"

export const DELETE_WEBSITE = gql`
  mutation DeleteWebsite($websiteId: UUID!) {
    deleteWebsite(websiteId: $websiteId) {
      ok
      message
    }
  }
`
