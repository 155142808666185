import PropTypes from "prop-types"
import styles from "../styles/UI/ClickableCard.module.css"

const ClickableCard = ({ logoUrl, text, onClick }) => {
  return (
    <div onClick={onClick} className={styles.clickableCard}>
      <img src={logoUrl} alt={text} className={styles.logo} />
      <p className={styles.text}>{text}</p>
    </div>
  )
}

ClickableCard.propTypes = {
  logoUrl: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
}

export default ClickableCard
