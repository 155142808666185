import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { useQuery } from "@apollo/client"
import { GET_INVOICE_OR_STATIC_INVOICE_BY_ID } from "../apollo/graphql/Query/getInvoiceOrStaticInvoiceById"
import InvoiceComponent from "../components/InvoiceComponent"
import StaticInvoiceComponent from "../components/StaticInvoiceComponent"
import { ReactTyped } from "react-typed"
import Logo from "../assets/UI/Logo"
import styles from "../assets/styles/Pages/InvoicePayPage.module.css"

const InvoicePayPage = () => {
  const { invoiceId } = useParams()
  const [invoice, setInvoice] = useState(null)

  const { error, data } = useQuery(GET_INVOICE_OR_STATIC_INVOICE_BY_ID, {
    variables: { invoiceId },
  })

  useEffect(() => {
    if (data) {
      setInvoice(data.getInvoiceOrStaticInvoiceById)
    }
  }, [data])

  if (error || !invoice)
    return (
      <>
        <div className={styles.logoContainer}>
          <Logo />
        </div>
        <div className={styles.container}>
          <ReactTyped
            strings={["Invoice Not Found"]}
            typeSpeed={50}
            showCursor={false}
            className={styles.typedText}
          />
        </div>
      </>
    )

  if (invoice.__typename === "InvoiceType") {
    return <InvoiceComponent invoice={invoice} />
  } else if (invoice.__typename === "StaticInvoiceType") {
    return <StaticInvoiceComponent invoice={invoice} />
  } else {
    return (
      <>
        <div className={styles.logoContainer}>
          <Logo />
        </div>
        <div className={styles.container}>
          <ReactTyped
            strings={["Invoice Not Found"]}
            typeSpeed={50}
            showCursor={false}
            className={styles.typedText}
          />
        </div>
      </>
    )
  }
}

export default InvoicePayPage
