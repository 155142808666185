import { gql } from "@apollo/client"

export const ALL_WEBSITES = gql`
  query AllWebsites($walletId: UUID!) {
    allWebsites(walletId: $walletId) {
      id
      name
      url
      isActive
      isVerified
    }
  }
`
