import { gql } from "@apollo/client"

export const CURRENCY_BY_ISO = gql`
  query CurrencyByIso($walletId: UUID!, $isoCode: String!) {
    currencyByIso(walletId: $walletId, isoCode: $isoCode) {
      currency {
        name
        isoCode
        symbol
        currencyType
        logoUrl
        isActive
      }
      balance
      blockchains {
        blockchain {
          fullName
          name
          logoUrl
        }
        isActive
        tokenAddress
        withdrawalCommission
        minimumWithdrawalAmount
        withdrawalCommissionMultiplierPercentage
      }
    }
  }
`
