import { useEffect } from "react"
import { useLazyQuery } from "@apollo/client"
import { useNavigate } from "react-router-dom"
import { USER_SUMMARY } from "../apollo/graphql/Query/userSummary"
import Button from "../assets/UI/Button"
import styles from "../assets/styles/Components/AccountExists.module.css"

const AccountExists = ({ email }) => {
  const navigate = useNavigate()
  const [getUserByEmail, { loading, data, error }] = useLazyQuery(USER_SUMMARY)

  useEffect(() => {
    if (email) {
      getUserByEmail({ variables: { email } })
    }
  }, [email, getUserByEmail])

  if (loading) return <p>Loading...</p>
  if (error) return <p>Error: {error.message}</p>

  const user = data.userSummary

  const handleLoginRedirect = () => {
    navigate("/login", {
      state: {
        email,
        isAddingBusiness: true,
        twoFactorAuthenticated: true,
      },
    })
  }

  return (
    <div className={styles.container}>
      <p>Account Already Registered</p>
      <p>The account with email {email} is already registered.</p>
      <p>
        {user.firstName} {user.lastName}
      </p>
      <Button onClick={handleLoginRedirect}>Login</Button>
    </div>
  )
}

export default AccountExists
