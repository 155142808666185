import OTPInput from "../assets/UI/OTPInput"
import styles from "../assets/styles/Components/OTPEmailVerification.module.css"

const OTPEmailVerification = ({ email, onSuccess }) => {
  return (
    <div className={styles.container}>
      <OTPInput
        length={6}
        email={email}
        type="email"
        title="Check your email"
        onSuccess={onSuccess}
        resendCooldown={60}
      />
    </div>
  )
}

export default OTPEmailVerification
