import { gql } from "@apollo/client"

export const ALL_NETWORKS = gql`
  query {
    allNetworks {
      id
      fullName
      name
      symbol
      logoUrl
      isActive
    }
  }
`
