import { gql } from "@apollo/client"

export const CREATE_DEVICE = gql`
  mutation CreateDevice($offlineStoreId: UUID!) {
    createDevice(offlineStoreId: $offlineStoreId) {
      ok
      device {
        id
        apiKey
        createdAt
      }
      message
    }
  }
`
