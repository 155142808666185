import { gql } from "@apollo/client"

export const GET_TRANSFER_CONTACTS = gql`
  query GetTransferContacts($walletId: UUID!, $contactType: String) {
    transferContacts(walletId: $walletId, contactType: $contactType) {
      id
      contactUser {
        id
        firstName
        lastName
        email
        usernameTag
        phoneNumber
        avatarUrl
        wallets {
          id
          business {
            legalName
            avatarUrl
          }
          walletType
        }
      }
      contactType
      createdAt
    }
  }
`
