import { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import { useQuery, useMutation } from "@apollo/client"
import { CURRENCY_BY_ISO } from "../apollo/graphql/Query/currencyByIso"
import { ADDRESSES_BY_NETWORK } from "../apollo/graphql/Query/addressesByNetwork"
import { CREATE_ADDRESS } from "../apollo/graphql/Mutation/createAddress"
import Modal from "../assets/UI/Modal"
import QRCodeDisplay from "../assets/UI/QRCodeDisplay"
import LabelButton from "../assets/UI/LabelButton"
import Currency from "../assets/UI/Currency"
import AddressDisplay from "../assets/UI/AddressDisplay"
import { useSelector } from "react-redux"
import styles from "../assets/styles/Pages/ReceivePage.module.css"

const ReceivePage = () => {
  const { isoCode } = useParams()
  const walletId = useSelector((state) => state.wallet.wallet.id)
  const [selectedNetwork, setSelectedNetwork] = useState(null)
  const [isModalOpen, setIsModalOpen] = useState(false)

  const {
    loading: currencyLoading,
    data: currencyData,
    error: currencyError,
  } = useQuery(CURRENCY_BY_ISO, {
    variables: { walletId, isoCode },
    skip: !walletId || !isoCode,
  })

  const {
    loading: addressLoading,
    data: addressData,
    refetch,
  } = useQuery(ADDRESSES_BY_NETWORK, {
    variables: { walletId, networkName: selectedNetwork?.blockchain?.name },
    skip: !selectedNetwork || !walletId,
  })

  const [createAddress, { loading: creatingAddress }] =
    useMutation(CREATE_ADDRESS)

  useEffect(() => {
    if (
      currencyData &&
      currencyData.currencyByIso &&
      currencyData.currencyByIso.blockchains.length > 1
    ) {
      setIsModalOpen(true)
    } else if (
      currencyData &&
      currencyData.currencyByIso &&
      currencyData.currencyByIso.blockchains.length === 1
    ) {
      setSelectedNetwork(currencyData.currencyByIso.blockchains[0])
    }
  }, [currencyData])

  useEffect(() => {
    if (
      selectedNetwork &&
      addressData &&
      addressData.addressesByNetwork.length === 0
    ) {
      handleCreateAddress()
    }
  }, [selectedNetwork, addressData])

  const handleCreateAddress = () => {
    createAddress({
      variables: {
        walletId,
        blockchain: selectedNetwork.blockchain.name,
      },
    })
      .then(({ data }) => {
        if (data.createAddress.ok) {
          refetch()
        } else {
          alert(data.createAddress.message)
        }
      })
      .catch((e) => {
        alert(`Error creating address: ${e.message}`)
      })
  }

  const addresses = addressData?.addressesByNetwork || []

  if (currencyLoading || addressLoading)
    return <p>Loading currency details...</p>
  if (currencyError)
    return <p>Error fetching currency details: {currencyError.message}</p>

  const { currency, blockchains } = currencyData.currencyByIso

  return (
    <div>
      {selectedNetwork ? (
        <div>
          {addresses.length > 0 ? (
            <div className={styles.container}>
              <Currency currency={currency} variant="simple" />
              <QRCodeDisplay value={addresses[0].address} />
              <p className={styles.networkName}>
                {selectedNetwork.blockchain.fullName}
              </p>
              <AddressDisplay address={addresses[0].address} />
            </div>
          ) : (
            <div>
              <p>Automatically creating an address...</p>
            </div>
          )}
        </div>
      ) : (
        <p>
          Loading network details for {currency.name} ({currency.isoCode})...
        </p>
      )}

      {isModalOpen && (
        <Modal title="Select Network" onClose={() => setIsModalOpen(false)}>
          {blockchains.map((blockchain, index) => (
            <LabelButton
              key={index}
              icon={blockchain.blockchain.logoUrl}
              onClick={() => {
                setSelectedNetwork(blockchain)
                setIsModalOpen(false)
              }}
            >
              {blockchain.blockchain.fullName}
            </LabelButton>
          ))}
        </Modal>
      )}
    </div>
  )
}

export default ReceivePage
