import { gql } from "@apollo/client"

export const SEND_TRANSACTION = gql`
  mutation SendTransaction(
    $walletId: UUID!
    $receiverAddress: String!
    $amount: Decimal!
    $currency: String!
    $network: String!
  ) {
    sendTransaction(
      walletId: $walletId
      receiverAddress: $receiverAddress
      amount: $amount
      currency: $currency
      network: $network
    ) {
      ok
      message
    }
  }
`
