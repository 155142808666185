import { gql } from "@apollo/client"

export const PAY_STATIC_INVOICE = gql`
  mutation PayStaticInvoice(
    $walletId: UUID!
    $staticInvoiceId: UUID!
    $currency: String!
    $amount: Decimal!
  ) {
    payStaticInvoice(
      walletId: $walletId
      staticInvoiceId: $staticInvoiceId
      currency: $currency
      amount: $amount
    ) {
      ok
      message
    }
  }
`
