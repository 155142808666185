import { useEffect, useState, useRef } from "react"
import { useMutation } from "@apollo/client"
import { SELECT_INVOICE_CURRENCY } from "../apollo/graphql/Mutation/selectInvoiceCurrency"
import Currency from "../assets/UI/Currency"
import QRCodeDisplay from "../assets/UI/QRCodeDisplay"
import Timer from "../assets/UI/Timer"
import Button from "../assets/UI/Button"
import Logo from "../assets/UI/Logo"
import AddressDisplay from "../assets/UI/AddressDisplay"
import Modal from "../assets/UI/Modal"
import LabelButton from "../assets/UI/LabelButton"
import formatBalance from "../utils/formatBalance"
import { toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { ReactTyped } from "react-typed"
import styles from "../assets/styles/Components/InvoiceComponent.module.css"

const InvoiceComponent = ({ invoice }) => {
  const [selectedCurrency, setSelectedCurrency] = useState(null)
  const [selectedBlockchainCurrency, setSelectedBlockchainCurrency] =
    useState(null)
  const [currentModal, setCurrentModal] = useState(null)
  const [currentInvoice, setCurrentInvoice] = useState(invoice)
  const [invoiceStatus, setInvoiceStatus] = useState(invoice.invoiceStatus)
  const [expectedAmount, setExpectedAmount] = useState(
    parseFloat(invoice.expectedAmount)
  )
  const [remainingAmount, setRemainingAmount] = useState(
    parseFloat(invoice.expectedAmount) - parseFloat(invoice.receivedAmount)
  )
  const [isWaitingForPayment, setIsWaitingForPayment] = useState(false)
  const [waitingExpireTime, setWaitingExpireTime] = useState(null)

  const prevRemainingAmountRef = useRef(remainingAmount)
  const isFirstRender = useRef(true)

  const [selectInvoiceCurrency] = useMutation(SELECT_INVOICE_CURRENCY, {
    onCompleted: (data) => {
      if (data.selectInvoiceCurrency.invoice) {
        const updatedInvoice = data.selectInvoiceCurrency.invoice
        setCurrentInvoice(updatedInvoice)
        setInvoiceStatus(updatedInvoice.status)
        setExpectedAmount(parseFloat(updatedInvoice.expectedAmount))
        setRemainingAmount(
          parseFloat(updatedInvoice.expectedAmount) -
            parseFloat(updatedInvoice.receivedAmount)
        )
      }
    },
    onError: (error) => {
      console.error("Error updating invoice currency: ", error)
    },
  })

  useEffect(() => {
    // const ws = new WebSocket(`ws://localhost:8001/ws/invoice/${invoice.id}`)
    const ws = new WebSocket(
      `ws://vortexonline.xyz:8001/ws/invoice/${invoice.id}`
    )

    ws.onmessage = (event) => {
      try {
        const data = JSON.parse(event.data)

        if (data.status) {
          setInvoiceStatus(data.status)
        }

        if (data.remaining_amount !== undefined) {
          const newRemainingAmount = parseFloat(data.remaining_amount)
          const receivedAmount =
            prevRemainingAmountRef.current - newRemainingAmount

          if (receivedAmount > 0) {
            const currencySymbol =
              currentInvoice.selectedCurrency?.currency.symbol || ""
            toast(
              `Received payment of ${formatBalance(
                receivedAmount,
                true
              )} ${currencySymbol}`,
              {
                closeOnClick: true,
              }
            )
          }

          setRemainingAmount(newRemainingAmount)
          prevRemainingAmountRef.current = newRemainingAmount
        }
      } catch (error) {
        console.error("Error parsing WebSocket message:", error)
      }
    }

    ws.onerror = (error) => {
      console.error("WebSocket Error:", error)
    }

    ws.onclose = (event) => {
      if (!event.wasClean) {
        setTimeout(() => {
          // new WebSocket(`ws://localhost:8001/ws/invoice/${invoice.id}`)
          new WebSocket(`ws://vortexonline.xyz/:8001/ws/invoice/${invoice.id}`)
        }, 1000)
      }
    }

    return () => {
      ws.close()
    }
  }, [invoice.id, expectedAmount, currentInvoice])

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false
      prevRemainingAmountRef.current = remainingAmount
      return
    }

    prevRemainingAmountRef.current = remainingAmount
  }, [remainingAmount])

  useEffect(() => {
    if (isWaitingForPayment) {
      const fiveMinutesLater = new Date(Date.now() + 5 * 60 * 1000)
      setWaitingExpireTime(fiveMinutesLater)
    }
  }, [isWaitingForPayment])

  const handleCurrencyClick = (currency) => {
    setSelectedCurrency(currency)
    const blockchains = currentInvoice.supportedCurrencies.filter(
      (c) => c.currency.id === currency.currency.id
    )

    if (blockchains.length === 1) {
      handleBlockchainSelect(blockchains[0])
    } else {
      setCurrentModal("blockchain")
    }
  }

  const handleBlockchainSelect = (blockchainCurrency) => {
    setSelectedBlockchainCurrency(blockchainCurrency)
    selectInvoiceCurrency({
      variables: {
        invoiceId: currentInvoice.id,
        blockchainCurrencyId: blockchainCurrency.id,
      },
    })
    setCurrentModal(null)
    setSelectedCurrency(null)
  }

  const handleTimerEnd = () => {
    if (invoiceStatus !== "PAID") {
      setIsWaitingForPayment(true)
    }
  }

  const handleOpenCurrencyModal = () => {
    setCurrentModal("currency")
  }

  const handleCloseModal = () => {
    setCurrentModal(null)
    setSelectedCurrency(null)
  }

  return (
    <>
      <div className={styles.logoContainer}>
        <Logo />
      </div>
      {invoiceStatus === "CREATED" || invoiceStatus === "PARTIALLY_PAID" ? (
        !currentInvoice.selectedCurrency && !currentInvoice.address ? (
          <div className={styles.container}>
            <p className={styles.invoiceName}>
              {currentInvoice.wallet.walletType === "BUSINESS"
                ? currentInvoice.wallet.business.legalName
                : `${currentInvoice.wallet.user.firstName} ${currentInvoice.wallet.user.lastName}`}
            </p>
            <p className={styles.invoiceAmount}>
              {formatBalance(currentInvoice.amount, true)}{" "}
              {currentInvoice.currency.isoCode}
            </p>
            <div className={styles.invoiceButton}>
              <Button onClick={handleOpenCurrencyModal}>Pay</Button>
            </div>

            {currentModal === "currency" && (
              <Modal title="Select a Currency" onClose={handleCloseModal}>
                {currentInvoice.supportedCurrencies
                  .map((currency) => currency.currency)
                  .filter(
                    (value, index, self) =>
                      index === self.findIndex((c) => c.id === value.id)
                  )
                  .map((currency) => (
                    <LabelButton
                      key={currency.id}
                      onClick={() => handleCurrencyClick({ currency })}
                      icon={currency.logoUrl}
                    >
                      {currency.name}
                    </LabelButton>
                  ))}
              </Modal>
            )}

            {currentModal === "blockchain" && selectedCurrency && (
              <Modal
                title={`Select Blockchain for ${selectedCurrency.currency.name}`}
                onClose={handleCloseModal}
              >
                {currentInvoice.supportedCurrencies
                  .filter((c) => c.currency.id === selectedCurrency.currency.id)
                  .map((blockchainCurrency) => (
                    <LabelButton
                      key={blockchainCurrency.id}
                      onClick={() => handleBlockchainSelect(blockchainCurrency)}
                      icon={blockchainCurrency.blockchain.logoUrl}
                    >
                      {blockchainCurrency.blockchain.fullName}
                    </LabelButton>
                  ))}
              </Modal>
            )}
          </div>
        ) : (
          <div className={styles.container}>
            {isWaitingForPayment ? (
              <>
                <Currency
                  currency={currentInvoice.selectedCurrency.currency}
                  variant="simple"
                />
                <p className={styles.networkName}>
                  {currentInvoice.selectedCurrency.blockchain.fullName}
                </p>
                <p className={styles.invoiceAmount}>
                  {formatBalance(remainingAmount, true)}{" "}
                  {currentInvoice.selectedCurrency.currency.symbol}
                </p>
                <div className={styles.timerContainer}>
                  <Timer
                    expireTime={waitingExpireTime}
                    label="Waiting for payment"
                  />
                </div>
              </>
            ) : (
              <>
                <Currency
                  currency={currentInvoice.selectedCurrency.currency}
                  variant="simple"
                />
                <QRCodeDisplay value={currentInvoice.address.subAddress} />
                <p className={styles.networkName}>
                  {currentInvoice.selectedCurrency.blockchain.fullName}
                </p>
                <p className={styles.invoiceAmount}>
                  {formatBalance(remainingAmount, true)}{" "}
                  {currentInvoice.selectedCurrency.currency.symbol}
                </p>
                <AddressDisplay address={currentInvoice.address.subAddress} />
                <div className={styles.timerContainer}>
                  <Timer
                    expireTime={currentInvoice.expirationDate}
                    onEnd={handleTimerEnd}
                    label="Time left to pay"
                  />
                </div>
              </>
            )}
          </div>
        )
      ) : invoiceStatus === "PAID" ? (
        <div className={styles.statusContainer}>
          <ReactTyped
            strings={["Invoice Paid"]}
            typeSpeed={50}
            showCursor={false}
            className={styles.typedText}
          />
        </div>
      ) : invoiceStatus === "EXPIRED" ? (
        <div className={styles.statusContainer}>
          <ReactTyped
            strings={["Invoice Expired"]}
            typeSpeed={50}
            showCursor={false}
            className={styles.typedText}
          />
        </div>
      ) : (
        <div className={styles.statusContainer}>
          <ReactTyped
            strings={[`Invoice ${invoiceStatus}`]}
            typeSpeed={50}
            showCursor={false}
            className={styles.typedText}
          />
        </div>
      )}
      <ToastContainer />
    </>
  )
}

export default InvoiceComponent
