import styles from "../styles/UI/ToggleButton.module.css"

const ToggleButton = ({ isChecked, onToggle }) => {
  return (
    <label className={styles.toggleSwitch}>
      <input
        type="checkbox"
        checked={isChecked}
        onChange={onToggle}
        className={styles.toggleInput}
      />
      <span className={styles.slider}></span>
    </label>
  )
}

export default ToggleButton
