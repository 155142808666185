import { gql } from "@apollo/client"

export const CREATE_WEBSITE = gql`
  mutation CreateWebsite($walletId: UUID!, $name: String!, $url: String!) {
    createWebsite(walletId: $walletId, name: $name, url: $url) {
      ok
      website {
        id
        name
        url
        isActive
        isVerified
      }
      message
    }
  }
`
