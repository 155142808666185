import { gql } from "@apollo/client"

export const WEBSITE = gql`
  query Website($websiteId: UUID!) {
    website(websiteId: $websiteId) {
      id
      name
      url
      apiKey
      isActive
      isVerified
      createdAt
      expireTime
      supportedCurrencies {
        id
        currency {
          id
          name
          symbol
          isoCode
          logoUrl
          isActive
        }
        blockchain {
          id
          fullName
          name
          symbol
          logoUrl
          isActive
        }
      }
    }
  }
`
