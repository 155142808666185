import { gql } from "@apollo/client"

export const ALL_OFFLINE_STORES = gql`
  query AllOfflineStores($walletId: UUID!) {
    allOfflineStores(walletId: $walletId) {
      id
      name
      address
      isActive
    }
  }
`
