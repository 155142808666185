import { gql } from "@apollo/client"

export const DELETE_STATIC_INVOICE = gql`
  mutation DeleteStaticInvoice($invoiceId: UUID!, $walletId: UUID!) {
    deleteStaticInvoice(invoiceId: $invoiceId, walletId: $walletId) {
      ok
      message
    }
  }
`
