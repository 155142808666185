import { gql } from "@apollo/client"

export const CREATE_ADDRESS = gql`
  mutation CreateAddress($walletId: UUID!, $blockchain: String!) {
    createAddress(walletId: $walletId, blockchain: $blockchain) {
      ok
      address
      message
    }
  }
`
