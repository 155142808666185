import { QRCodeCanvas } from "qrcode.react"
import PropTypes from "prop-types"
import styles from "../styles/UI/QRCodeDisplay.module.css"

const QRCodeDisplay = ({ value, size = 230 }) => {
  return (
    <div className={styles.qrCodeContainer}>
      <QRCodeCanvas value={value} size={size} />
    </div>
  )
}

QRCodeDisplay.propTypes = {
  value: PropTypes.string.isRequired,
  size: PropTypes.number,
}

export default QRCodeDisplay
