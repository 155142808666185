import { gql } from "@apollo/client"

export const USER_WALLETS = gql`
  query UserWallets {
    userWallets {
      id
      user {
        id
        firstName
        lastName
        email
        avatarUrl
        usernameTag
        kycStatus
      }
      business {
        id
        legalName
        avatarUrl
        kybStatus
      }
      walletType
    }
  }
`
