import { useState } from "react"
import { useQuery, useMutation } from "@apollo/client"
import { useNavigate } from "react-router-dom"
import { toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import Button from "../assets/UI/Button"
import Card from "../assets/UI/Card"
import Input from "../assets/UI/Input"
import Modal from "../assets/UI/Modal"
import { ALL_OFFLINE_STORES } from "../apollo/graphql/Query/allOfflineStores"
import { CREATE_OFFLINE_STORE } from "../apollo/graphql/Mutation/createOfflineStore"
import styles from "../assets/styles/Components/OfflineStoreList.module.css"

const OfflineStoreList = ({ walletId }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [newStoreName, setNewStoreName] = useState("")
  const [newStoreAddress, setNewStoreAddress] = useState("")
  const [errors, setErrors] = useState({})

  const navigate = useNavigate()

  const { loading, data, error, refetch } = useQuery(ALL_OFFLINE_STORES, {
    variables: { walletId },
  })

  const [createOfflineStore, { loading: createLoading }] =
    useMutation(CREATE_OFFLINE_STORE)

  const toastOptions = {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
    style: {
      backgroundColor: "#070707",
      color: "#ffffff",
    },
  }

  const handleCreateClick = () => {
    setIsModalOpen(true)
    setErrors({})
  }

  const handleCreateStore = async () => {
    const newErrors = {}
    if (!newStoreName) newErrors.name = "Please enter a store name."
    if (!newStoreAddress) newErrors.address = "Please enter an address."

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors)
      return
    }

    try {
      const { data } = await createOfflineStore({
        variables: {
          walletId,
          name: newStoreName,
          address: newStoreAddress,
        },
      })

      if (data.createOfflineStore.ok) {
        toast.success("Offline store created successfully.", toastOptions)
        setIsModalOpen(false)
        setNewStoreName("")
        setNewStoreAddress("")
        setErrors({})
        refetch()
      } else {
        toast.error(`Error: ${data.createOfflineStore.message}`, toastOptions)
      }
    } catch (err) {
      console.error("An error occurred:", err)
      toast.error("An unexpected error occurred.", toastOptions)
    }
  }

  const handleCardClick = (storeId) => {
    navigate(`/integration/offline-store/${storeId}`)
  }

  if (loading) return <p>Loading offline stores...</p>
  if (error) return <p>Error loading offline stores: {error.message}</p>

  return (
    <>
      <ToastContainer />
      <div className={styles.cardContainer}>
        {data.allOfflineStores.length > 0 ? (
          data.allOfflineStores.map((store) => (
            <Card key={store.id} onClick={() => handleCardClick(store.id)}>
              <div className={styles.card}>
                <p className={styles.cardName}>{store.name}</p>
                <p className={styles.cardText}>{store.address}</p>
              </div>
            </Card>
          ))
        ) : (
          <p>No offline stores found.</p>
        )}
      </div>
      <div className={styles.offlineStoreBtn}>
        <Button onClick={handleCreateClick}>Create</Button>
      </div>

      {isModalOpen && (
        <Modal
          title="Create Offline Store"
          onClose={() => setIsModalOpen(false)}
          height="70vh"
        >
          <Input
            label="Store Name"
            value={newStoreName}
            onChange={(e) => setNewStoreName(e.target.value)}
            placeholder="Enter store name"
            error={errors.name}
          />
          <Input
            label="Address"
            value={newStoreAddress}
            onChange={(e) => setNewStoreAddress(e.target.value)}
            placeholder="Enter address"
            error={errors.address}
          />
          <div className={styles.offlineStoreBtn}>
            <Button onClick={handleCreateStore} disabled={createLoading}>
              {createLoading ? "Creating..." : "Create Store"}
            </Button>
          </div>
        </Modal>
      )}
    </>
  )
}

export default OfflineStoreList
