import { gql } from "@apollo/client"

export const TRANSFER_MUTATION = gql`
  mutation TransferMutation(
    $senderWalletId: UUID!
    $receiverWalletId: UUID!
    $currency: String!
    $amount: Decimal!
    $description: String
  ) {
    transfer(
      senderWalletId: $senderWalletId
      receiverWalletId: $receiverWalletId
      currency: $currency
      amount: $amount
      description: $description
    ) {
      ok
      transaction {
        id
        amount
        currency {
          isoCode
        }
        status
        fee
        createdAt
      }
      message
    }
  }
`
