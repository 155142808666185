import { useState, useEffect } from "react"
import { useQuery, useLazyQuery, useMutation } from "@apollo/client"
import { useNavigate } from "react-router-dom"
import { ALL_CURRENCIES } from "../apollo/graphql/Query/allCurrencies"
import { CONVERT_SINGLE_CURRENCY } from "../apollo/graphql/Query/convertSingleCurrency"
import { SWAP_MUTATION } from "../apollo/graphql/Mutation/swap"
import Button from "../assets/UI/Button"
import Input from "../assets/UI/Input"
import Modal from "../assets/UI/Modal"
import TabMenu from "../assets/UI/TabMenu"
import Currency from "../assets/UI/Currency"
import LabelButton from "../assets/UI/LabelButton"
import Skeleton from "react-loading-skeleton"
import "react-loading-skeleton/dist/skeleton.css"
import {
  isValidPositiveNumber,
  normalizeDecimalSeparator,
} from "../utils/inputValidation"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { useSelector } from "react-redux"
import styles from "../assets/styles/Pages/SwapPage.module.css"

const SwapPage = () => {
  const navigate = useNavigate()
  const walletId = useSelector((state) => state.wallet.wallet.id)

  const [fromCurrency, setFromCurrency] = useState(null)
  const [toCurrency, setToCurrency] = useState(null)
  const [amount, setAmount] = useState("")
  const [convertedAmount, setConvertedAmount] = useState("")
  const [exchangeRate, setExchangeRate] = useState(null)
  const [commission, setCommission] = useState(0)
  const [isCurrencyModalOpen, setIsCurrencyModalOpen] = useState(false)
  const [currencyTypeToSelect, setCurrencyTypeToSelect] = useState(null)
  const [selectedTab, setSelectedTab] = useState("crypto")
  const [isSwapModalOpen, setIsSwapModalOpen] = useState(false)
  const [swapQuoteId, setSwapQuoteId] = useState(null)
  const [swapTimer, setSwapTimer] = useState(5)
  const [swapTimerId, setSwapTimerId] = useState(null)
  const [errorMessage, setErrorMessage] = useState("")

  const {
    loading: currenciesLoading,
    error: currenciesError,
    data: currenciesData,
  } = useQuery(ALL_CURRENCIES, {
    variables: { walletId },
    fetchPolicy: "network-only",
    skip: !walletId,
  })

  useEffect(() => {
    if (
      currenciesData &&
      currenciesData.allCurrencies &&
      !fromCurrency &&
      !toCurrency
    ) {
      const allCurrencies = currenciesData.allCurrencies
      const defaultFromCurrency = allCurrencies.find(
        (currencyData) => currencyData.currency.isoCode === "USD"
      )
      const defaultToCurrency = allCurrencies.find(
        (currencyData) => currencyData.currency.isoCode === "USDT"
      )
      if (defaultFromCurrency) setFromCurrency(defaultFromCurrency)
      if (defaultToCurrency) setToCurrency(defaultToCurrency)
    }
  }, [currenciesData, fromCurrency, toCurrency])

  const [
    convertCurrency,
    {
      data: conversionData,
      loading: conversionLoading,
      error: conversionError,
    },
  ] = useLazyQuery(CONVERT_SINGLE_CURRENCY)

  const [
    swapMutation,
    { data: swapData, loading: swapLoading, error: swapError },
  ] = useMutation(SWAP_MUTATION)

  const handleOpenCurrencyModal = (currencyType) => {
    setCurrencyTypeToSelect(currencyType)
    setIsCurrencyModalOpen(true)
  }

  const handleCloseCurrencyModal = () => {
    setIsCurrencyModalOpen(false)
  }

  const handleCurrencySelect = (currencyData) => {
    if (currencyTypeToSelect === "from") {
      setFromCurrency(currencyData)

      if (currencyData.currency.isoCode === toCurrency?.currency.isoCode) {
        const newToCurrency = currenciesData.allCurrencies.find(
          (currency) =>
            currency.currency.isoCode !== currencyData.currency.isoCode
        )
        setToCurrency(newToCurrency)
      }
    } else if (currencyTypeToSelect === "to") {
      setToCurrency(currencyData)

      if (currencyData.currency.isoCode === fromCurrency?.currency.isoCode) {
        const newFromCurrency = currenciesData.allCurrencies.find(
          (currency) =>
            currency.currency.isoCode !== currencyData.currency.isoCode
        )
        setFromCurrency(newFromCurrency)
      }
    }
    setIsCurrencyModalOpen(false)
  }

  const handleAmountChange = (e) => {
    const value = e.target.value

    if (isValidPositiveNumber(value)) {
      setAmount(normalizeDecimalSeparator(value))
    }
  }

  useEffect(() => {
    if (fromCurrency && toCurrency && amount) {
      convertCurrency({
        variables: {
          walletId,
          currency: fromCurrency.currency.isoCode,
          targetCurrency: toCurrency.currency.isoCode,
        },
      })
    } else {
      setConvertedAmount("")
    }
  }, [fromCurrency, toCurrency, amount, convertCurrency, walletId])

  useEffect(() => {
    if (conversionData && conversionData.convertSingleCurrency) {
      const data = conversionData.convertSingleCurrency
      const rate = parseFloat(data.exchangeRate)
      const finalConvertedAmount =
        amount && amount !== ""
          ? (parseFloat(amount) * rate * 0.99).toFixed(6)
          : ""
      setExchangeRate(rate.toFixed(6))
      setConvertedAmount(finalConvertedAmount)
    }
  }, [conversionData, amount])

  const getAvailableBalance = () => {
    if (fromCurrency && currenciesData && currenciesData.allCurrencies) {
      const currencyData = currenciesData.allCurrencies.find(
        (currency) =>
          currency.currency.isoCode === fromCurrency.currency.isoCode
      )
      return currencyData ? parseFloat(currencyData.balance) : 0
    }
    return 0
  }

  const handleMaxButtonClick = () => {
    const availableBalance = getAvailableBalance()
    setAmount(availableBalance.toString())
  }

  const handleSwapClick = () => {
    swapMutation({
      variables: {
        walletId,
        fromCurrency: fromCurrency.currency.isoCode,
        toCurrency: toCurrency.currency.isoCode,
        amount: parseFloat(amount),
      },
    })
      .then((response) => {
        const data = response.data.swap
        if (data.ok) {
          setSwapQuoteId(data.swapQuote.id)
          const convertedAmount = parseFloat(data.swapQuote.toAmount)
          const exchangeRate = parseFloat(data.swapQuote.exchangeRate)
          const commissionAmount =
            convertedAmount * parseFloat(data.swapQuote.fee)
          const finalConvertedAmount = convertedAmount - commissionAmount
          setCommission(commissionAmount.toFixed(6))
          setConvertedAmount(finalConvertedAmount.toFixed(6))
          setExchangeRate(exchangeRate.toFixed(6))

          setIsSwapModalOpen(true)
          setSwapTimer(5)
          const timerId = setInterval(() => {
            setSwapTimer((prev) => {
              if (prev > 1) {
                return prev - 1
              } else {
                clearInterval(timerId)
                return 0
              }
            })
          }, 1000)
          setSwapTimerId(timerId)
        } else {
          setErrorMessage(data.message)
        }
      })
      .catch((error) => {
        setErrorMessage(error.message)
      })
  }

  const handleConfirmSwap = () => {
    if (swapTimer > 0) {
      swapMutation({
        variables: {
          walletId,
          fromCurrency: fromCurrency.currency.isoCode,
          toCurrency: toCurrency.currency.isoCode,
          amount: parseFloat(amount),
          swapQuoteId: swapQuoteId,
        },
      })
        .then((response) => {
          const data = response.data.swap
          if (data.ok) {
            toast.success("Swap completed successfully")
            setIsSwapModalOpen(false)
            clearInterval(swapTimerId)
            navigate("/wallet")
          } else {
            setErrorMessage(data.message)
          }
        })
        .catch((error) => {
          setErrorMessage(error.message)
        })
    } else {
      setErrorMessage("Quote expired, please refresh")
    }
  }

  const handleRefreshQuote = () => {
    swapMutation({
      variables: {
        walletId,
        fromCurrency: fromCurrency.currency.isoCode,
        toCurrency: toCurrency.currency.isoCode,
        amount: parseFloat(amount),
      },
    })
      .then((response) => {
        const data = response.data.swap
        if (data.ok) {
          setSwapQuoteId(data.swapQuote.id)
          const convertedAmount = parseFloat(data.swapQuote.toAmount)
          const exchangeRate = parseFloat(data.swapQuote.exchangeRate)
          const commissionAmount =
            convertedAmount * parseFloat(data.swapQuote.fee)
          const finalConvertedAmount = convertedAmount - commissionAmount
          setCommission(commissionAmount.toFixed(6))
          setConvertedAmount(finalConvertedAmount.toFixed(6))
          setExchangeRate(exchangeRate.toFixed(6))

          setSwapTimer(5)
          clearInterval(swapTimerId)
          const timerId = setInterval(() => {
            setSwapTimer((prev) => {
              if (prev > 1) {
                return prev - 1
              } else {
                clearInterval(timerId)
                return 0
              }
            })
          }, 1000)
          setSwapTimerId(timerId)
          setErrorMessage("")
        } else {
          setErrorMessage(data.message)
        }
      })
      .catch((error) => {
        setErrorMessage(error.message)
      })
  }

  useEffect(() => {
    if (!isSwapModalOpen && swapTimerId) {
      clearInterval(swapTimerId)
      setSwapTimerId(null)
    }
  }, [isSwapModalOpen, swapTimerId])

  const cryptoCurrencies =
    currenciesData?.allCurrencies.filter(
      (currency) =>
        currency.currency.currencyType === "CRYPTO" &&
        currency.currency.isoCode !== fromCurrency?.currency.isoCode
    ) || []
  const fiatCurrencies =
    currenciesData?.allCurrencies.filter(
      (currency) =>
        currency.currency.currencyType === "FIAT" &&
        currency.currency.isoCode !== fromCurrency?.currency.isoCode
    ) || []

  const cryptoTabContent = (
    <div>
      {cryptoCurrencies.map((currencyData) => (
        <div className={styles.modalLabelButton}>
          <LabelButton
            key={currencyData.currency.id}
            onClick={() => handleCurrencySelect(currencyData)}
            icon={currencyData.currency.logoUrl}
          >
            {currencyData.currency.name}
          </LabelButton>
        </div>
      ))}
    </div>
  )

  const fiatTabContent = (
    <div>
      {fiatCurrencies.map((currencyData) => (
        <div className={styles.modalLabelButton}>
          <LabelButton
            key={currencyData.currency.id}
            onClick={() => handleCurrencySelect(currencyData)}
            icon={currencyData.currency.logoUrl}
          >
            {currencyData.currency.name}
          </LabelButton>
        </div>
      ))}
    </div>
  )

  const tabs = [
    { key: "crypto", label: "Crypto", content: cryptoTabContent },
    { key: "fiat", label: "Fiat", content: fiatTabContent },
  ]

  return (
    <div className={styles.container}>
      <p className={styles.pageName}>Swap</p>
      <div className={styles.fromContainer}>
        <div>
          {currenciesLoading ? (
            <Skeleton
              height={50}
              width={150}
              baseColor="#070707"
              highlightColor="#1e1e1e"
            />
          ) : fromCurrency ? (
            <Currency
              currency={fromCurrency.currency}
              variant="simple"
              onClick={() => handleOpenCurrencyModal("from")}
            />
          ) : (
            <Button onClick={() => handleOpenCurrencyModal("from")}>
              Select Currency
            </Button>
          )}
        </div>
        <Input
          type="text"
          placeholder="0"
          value={amount}
          onChange={handleAmountChange}
          secondaryLabel={
            currenciesLoading ? (
              <Skeleton
                width={100}
                baseColor="#070707"
                highlightColor="#1e1e1e"
              />
            ) : (
              `Available: ${getAvailableBalance()} ${
                fromCurrency?.currency?.isoCode || ""
              }`
            )
          }
          rightButtonLabel="MAX"
          onRightButtonClick={handleMaxButtonClick}
        />
      </div>
      <div className={styles.toContainer}>
        <div>
          {currenciesLoading ? (
            <Skeleton
              height={50}
              width={150}
              baseColor="#070707"
              highlightColor="#1e1e1e"
            />
          ) : toCurrency ? (
            <Currency
              currency={toCurrency.currency}
              variant="simple"
              onClick={() => handleOpenCurrencyModal("to")}
            />
          ) : (
            <Button onClick={() => handleOpenCurrencyModal("to")}>
              Select Currency
            </Button>
          )}
        </div>
        <p className={styles.convertedAmount}>
          {conversionLoading ? (
            <Skeleton
              width={100}
              baseColor="#070707"
              highlightColor="#1e1e1e"
            />
          ) : convertedAmount ? (
            `${convertedAmount} ${toCurrency?.currency?.isoCode}`
          ) : (
            ""
          )}
        </p>
      </div>
      {exchangeRate ? (
        <p className={styles.exchangeRate}>
          {`1 ${fromCurrency?.currency?.isoCode} ≈ ${exchangeRate} ${toCurrency?.currency?.isoCode}`}
        </p>
      ) : conversionLoading ? (
        <Skeleton width="100%" baseColor="#070707" highlightColor="#1e1e1e" />
      ) : null}
      <div className={styles.swapBtn}>
        <Button
          onClick={handleSwapClick}
          disabled={
            !fromCurrency ||
            !toCurrency ||
            !amount ||
            swapLoading ||
            parseFloat(amount) > getAvailableBalance()
          }
        >
          Swap
        </Button>
      </div>

      {isCurrencyModalOpen && (
        <Modal title="Select Currency" onClose={handleCloseCurrencyModal}>
          <TabMenu
            selectedTab={selectedTab}
            onTabChange={setSelectedTab}
            tabs={tabs}
          />
        </Modal>
      )}

      {isSwapModalOpen && (
        <Modal title="Confirm Swap" onClose={() => setIsSwapModalOpen(false)}>
          <p>
            Swap {amount} {fromCurrency.currency.isoCode} for {convertedAmount}{" "}
            {toCurrency.currency.isoCode}
          </p>
          <p>
            Commission: {commission} {toCurrency.currency.isoCode}
          </p>
          <p>Price is frozen for {swapTimer} seconds</p>
          {swapTimer > 0 ? (
            <Button onClick={handleConfirmSwap} disabled={swapLoading}>
              Confirm Swap
            </Button>
          ) : (
            <Button onClick={handleRefreshQuote} disabled={swapLoading}>
              Refresh Price
            </Button>
          )}
          {errorMessage && <p>{errorMessage}</p>}
        </Modal>
      )}
      <ToastContainer />
    </div>
  )
}

export default SwapPage
