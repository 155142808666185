import { gql } from "@apollo/client"

export const ALL_STATIC_INVOICES = gql`
  query AllStaticInvoices($walletId: UUID!) {
    allStaticInvoices(walletId: $walletId) {
      id
      name
      status
      network {
        id
        fullName
        name
        symbol
        logoUrl
        isActive
      }
      address {
        subAddress
      }
      createdAt
    }
  }
`
